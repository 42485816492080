const puzzle_inventory = [
	{
		imgName: "shalom_hamsa.jpg",
		thumbName: "shalom_hamsa_thumb.jpg",
		name: "Shalom Hamsa",
		catagory: "hidden_gems",
		height: 15.5,
		width: 12.75
	},
	{
		imgName: "santa_claus_preparing_for_his_annual_visit.jpg",
		thumbName: "santa_claus_preparing_for_his_annual_visit_thumb.jpg",
		name: "Santa Claus Preparing for His Annual Visit",
		catagory: "hidden_gems",
		height: 12.75,
		width: 13.0
	},
	{
		imgName: "holiday_crackers.jpg",
		thumbName: "holiday_crackers_thumb.jpg",
		name: "Holiday Crackers",
		catagory: "hidden_gems",
		height: 14.75,
		width: 20.75
	},
	{
		imgName: "wimbledon_2024.jpg",
		thumbName: "wimbledon_2024.jpg",
		name: "Wimbledon 2024",
		catagory: "hidden_gems",
		height: 11.75,
		width: 17.75
	},
]

export default puzzle_inventory