const puzzle_inventory = [
	{
		imgName: "woodpecker_owl_and_thrush.jpg",
		thumbName: "woodpecker_owl_and_thrush_thumb.jpg",
		name: "Woodpecker, Owl and Thrush",
		catagory: "birds",
		height: 17.75,
		width: 12.75
	},
	{
		imgName: "oiseaux_varieties_of_birds.jpg",
		thumbName: "oiseaux_varieties_of_birds_thumb.jpg",
		name: "Oiseaux: Varieties of Birds",
		catagory: "birds",
		height: 16.0,
		width: 12.75
	},
	{
		imgName: "large_blue-jay-image-1100-watermark.jpg",
		thumbName: "blue_jay_thumb.jpg",
		name: "Blue Jay",
		catagory: "birds",
		height: 13.25,
		width: 15.5
	},
	{
		imgName: "christmas_tree.jpg",
		thumbName: "christmas_tree_thumb.jpg",
		name: "Christmas Tree",
		catagory: "birds",
		height: 15.75,
		width: 20.75
	},
	{
		imgName: "a_good_tree_can_lodge_ten_thousand_birds.jpg",
		thumbName: "a_good_tree_can_lodge_ten_thousand_birds_thumb.jpg",
		name: "A Good Tree Can Lodge Ten Thousand Birds",
		catagory: "birds",
		height: 15.75,
		width: 13.75
	},
	{
		imgName: "swans.jpg",
		thumbName: "swans_thumb.jpg",
		name: "Swans",
		catagory: "birds",
		height: 7.5,
		width: 14.75
	},
	{
		imgName: "large_kolibris-image-1100-watermark.jpeg",
		thumbName: "kolibris_thumb.jpg",
		name: "Kolibris",
		catagory: "birds",
		height: 12.75,
		width: 16.25
	},
	{
		imgName: "a_present_for_santa.jpg",
		thumbName: "a_present_for_santa_thumb.jpg",
		name: "A Present for Santa",
		catagory: "birds",
		height: 8.75,
		width: 12.75
	},
	{
		imgName: "owl_and_cherry_branch.jpg",
		thumbName: "owl_and_cherry_branch_thumb.jpg",
		name: "Owl and Cherry Branch",
		catagory: "birds",
		height: 10.75,
		width: 10.75
	},
	{
		imgName: "a_perch_of_birds.jpg",
		thumbName: "a_perch_of_birds_thumb.jpg",
		name: "A Perch of Birds",
		catagory: "birds",
		height: 6.0,
		width: 23.75
	},
	{
		imgName: "the_twelve_days_of_christmas.jpg",
		thumbName: "the_twelve_days_of_christmas_thumb.jpg",
		name: "The Twelve Days of Christmas",
		catagory: "birds",
		height: 14.75,
		width: 14.75
	},
	{
		imgName: "a_company_of_macaws.jpg",
		thumbName: "a_company_of_macaws_thumb.jpg",
		name: "A Company of Macaws",
		catagory: "birds",
		height: 15.75,
		width: 10.75
	},
	{
		imgName: "birds.jpg",
		thumbName: "birds_thumb.jpg",
		name: "Birds",
		catagory: "birds",
		height: 14.75,
		width: 14.75
	},
	{
		imgName: "jennys_garden.jpg",
		thumbName: "jennys_garden_thumb.jpg",
		name: "Jenny's Garden",
		catagory: "birds",
		height: 13.75,
		width: 14.75
	},
	{
		imgName: "tahitian_landscape.jpg",
		thumbName: "tahitian_landscape_thumb.jpg",
		name: "Tahitian Landscape",
		catagory: "birds",
		height: 10.75,
		width: 21.75
	},
	{
		imgName: "the_chinese_drawing_room_at_temple_newsam_house.jpg",
		thumbName: "the_chinese_drawing_room_at_temple_newsam_house_thumb.jpg",
		name: "The Chinese Drawing Room at Temple Newsam House",
		catagory: "birds",
		height: 15.25,
		width: 11.75
	},
	{
		imgName: "the_good_life.jpg",
		thumbName: "the_good_life_thumb.jpg",
		name: "The Good Life",
		catagory: "birds",
		height: 16.5,
		width: 13.75
	},
	{
		imgName: "cranes_cycads_and_wisteria.jpg",
		thumbName: "cranes_cycads_and_wisteria_thumb.jpg",
		name: "Cranes, Cycads and Wisteria",
		catagory: "birds",
		height: 11.75,
		width: 17.25
	},
	{
		imgName: "osprey.jpg",
		thumbName: "osprey_thumb.jpg",
		name: "Osprey",
		catagory: "birds",
		height: 19.25,
		width: 15.25
	},
	{
		imgName: "great_horned_owl_round.jpg",
		thumbName: "great_horned_owl_round_thumb.jpg",
		name: "Great Horned Owl Round",
		catagory: "birds",
		height: 7.0,
		width: 9.0
	},
	{
		imgName: "great_horned_owl.jpg",
		thumbName: "great_horned_owl_thumb.jpg",
		name: "Great Horned Owl",
		catagory: "birds",
		height: 15.5,
		width: 19.5
	},
	{
		imgName: "liberty_eagle.jpg",
		thumbName: "liberty_eagle_thumb.jpg",
		name: "Liberty Eagle",
		catagory: "birds",
		height: 7.75,
		width: 23.0
	},
	{
		imgName: "red_and_yellow_macaw.jpg",
		thumbName: "red_and_yellow_macaw_thumb.jpg",
		name: "Red and Yellow Macaw",
		catagory: "birds",
		height: 22.0,
		width: 12.0
	},
	{
		imgName: "captiva_and_sanibel_islands.jpg",
		thumbName: "captiva_and_sanibel_islands_thumb.jpg",
		name: "Captiva and Sanibel Islands",
		catagory: "birds",
		height: 15.75,
		width: 21.25
	},
	{
		imgName: "birds_of_the_tropics.jpg",
		thumbName: "birds_of_the_tropics_thumb.jpg",
		name: "Birds of the Tropics",
		catagory: "birds",
		height: 15.75,
		width: 21.0
	},
	{
		imgName: "owl_eyes.jpg",
		thumbName: "owl_eyes_thumb.jpg",
		name: "Owl Eyes",
		catagory: "birds",
		height: 8.75,
		width: 23.75
	},
	{
		imgName: "phoenix.jpg",
		thumbName: "phoenix_thumb.jpg",
		name: "Phoenix",
		catagory: "birds",
		height: 17.75,
		width: 11.75
	},
	{
		imgName: "poultry_of_the_world.jpg",
		thumbName: "poultry_of_the_world_thumb.jpg",
		name: "Poultry of the World",
		catagory: "birds",
		height: 18.75,
		width: 15.5
	},
	{
		imgName: "birds_and_camellia_bushes_in_flower.jpg",
		thumbName: "birds_and_camellia_bushes_in_flower_thumb.jpg",
		name: "Birds and Camellia Bushes in Flower",
		catagory: "birds",
		height: 11.75,
		width: 18.5
	},
	{
		imgName: "peacock.jpg",
		thumbName: "peacock_thumb.jpg",
		name: "Peacock",
		catagory: "birds",
		height: 19.5,
		width: 13.5
	},
	{
		imgName: "american_avocet.jpg",
		thumbName: "american_avocet_thumb.jpg",
		name: "American Avocet",
		catagory: "birds",
		height: 11.75,
		width: 18.0
	},
	{
		imgName: "toucan.jpg",
		thumbName: "toucan_thumb.jpg",
		name: "Toucan",
		catagory: "birds",
		height: 20.0,
		width: 12.5
	},
	{
		imgName: "rooster.jpg",
		thumbName: "rooster_thumb.jpg",
		name: "Rooster",
		catagory: "birds",
		height: 20.0,
		width: 14.5
	},
	{
		imgName: "macaw.jpg",
		thumbName: "macaw_thumb.jpg",
		name: "Macaw",
		catagory: "birds",
		height: 19.5,
		width: 13.0
	},
	{
		imgName: "carolina_parakeet.jpg",
		thumbName: "carolina_parakeet_thumb.jpg",
		name: "Carolina Parakeet",
		catagory: "birds",
		height: 20.0,
		width: 15.0
	},
	{
		imgName: "connecticut_warbler.jpg",
		thumbName: "connecticut_warbler_thumb.jpg",
		name: "Connecticut Warbler",
		catagory: "birds",
		height: 20.0,
		width: 14.0
	},
	{
		imgName: "hawk.jpg",
		thumbName: "hawk_thumb.jpg",
		name: "Hawk",
		catagory: "birds",
		height: 10.0,
		width: 23.0
	},
	{
		imgName: "libre_esthetique.jpg",
		thumbName: "libre_esthetique_thumb.jpg",
		name: "Libre Esthetique",
		catagory: "birds",
		height: 17.75,
		width: 12.75
	},
	{
		imgName: "large_jenny-wren-image-watermark.jpeg",
		thumbName: "wedding_of_jenny_wren_kids_thumb.jpg",
		name: "Wedding of Jenny Wren Kids",
		catagory: "birds",
		height: 11.75,
		width: 9.0
	},
]

export default puzzle_inventory