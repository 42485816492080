const puzzle_inventory = [
	{
		imgName: "large_Maharana-Sarup-Singh-watermark-1210.jpeg",
		thumbName: "maharana_sarup_singh_inspects_a_prize_stallion_thumb.jpg",
		name: "Maharana Sarup Singh Inspects a Prize Stallion",
		catagory: "asian_artists",
		height: 12.75,
		width: 16.75
	},
	{
		imgName: "fine_wind_clear_morning.jpg",
		thumbName: "fine_wind_clear_morning_thumb.jpg",
		name: "Fine Wind, Clear Morning",
		catagory: "asian_artists",
		height: 8.5,
		width: 12.75
	},
	{
		imgName: "the_sea_at_satta_suruga_province.jpg",
		thumbName: "the_sea_at_satta_suruga_province_thumb.jpg",
		name: "The Sea at Satta, Suruga Province",
		catagory: "asian_artists",
		height: 17.5,
		width: 11.75
	},
	{
		imgName: "lakshmi.jpg",
		thumbName: "lakshmi_thumb.jpg",
		name: "Lakshmi",
		catagory: "asian_artists",
		height: 17.25,
		width: 11.75
	},
	{
		imgName: "naruto_whirlpool_awa_province.jpg",
		thumbName: "naruto_whirlpool_awa_province_thumb.jpg",
		name: "Naruto Whirlpool, Awa Province",
		catagory: "asian_artists",
		height: 17.75,
		width: 11.75
	},
	{
		imgName: "moon_over_a_waterfall.jpg",
		thumbName: "moon_over_a_waterfall_thumb.jpg",
		name: "Moon Over a Waterfall",
		catagory: "asian_artists",
		height: 19.75,
		width: 8.5
	},
	{
		imgName: "the_foxfires_in_nijushiko.jpg",
		thumbName: "the_foxfires_in_nijushiko_thumb.jpg",
		name: "The Foxfires in Nijushiko",
		catagory: "asian_artists",
		height: 18.5,
		width: 12.75
	},
	{
		imgName: "the_great_wave_off_kanagawa.jpg",
		thumbName: "the_great_wave_off_kanagawa_thumb.jpg",
		name: "The Great Wave off Kanagawa",
		catagory: "asian_artists",
		height: 12.75,
		width: 17.25
	},
	{
		imgName: "fudo_falls_oji.jpg",
		thumbName: "fudo_falls_oji_thumb.jpg",
		name: "Fudo Falls, Oji",
		catagory: "asian_artists",
		height: 19.25,
		width: 12.75
	},
	{
		imgName: "shamsa_sunburst.jpg",
		thumbName: "shamsa_sunburst_thumb.jpg",
		name: "Shamsa Sunburst",
		catagory: "asian_artists",
		height: 18.5,
		width: 12.75
	},
	{
		imgName: "armor_hanging_pine.jpg",
		thumbName: "armor_hanging_pine_thumb.jpg",
		name: "Armor Hanging Pine",
		catagory: "asian_artists",
		height: 19.25,
		width: 12.75
	},
	{
		imgName: "cherry_blossom_viewing_at_asukayama.jpg",
		thumbName: "cherry_blossom_viewing_at_asukayama_thumb.jpg",
		name: "Cherry Blossom Viewing at Asukayama",
		catagory: "asian_artists",
		height: 10.75,
		width: 22.0
	},
	{
		imgName: "falcon_and_the_cherry_tree.jpg",
		thumbName: "falcon_and_the_cherry_tree_thumb.jpg",
		name: "Falcon and the Cherry Tree",
		catagory: "asian_artists",
		height: 16.5,
		width: 7.0
	},
]

export default puzzle_inventory