const puzzle_inventory = [
	{
		imgName: "large_Wild-in-Spirit-image-1200-watermark.jpeg",
		thumbName: "wild_in_spirit_thumb.jpg",
		name: "Wild in Spirit",
		catagory: "animals",
		height: 17.0,
		width: 12.75
	},
	{
		imgName: "the_peaceful_kingdom.jpg",
		thumbName: "the_peaceful_kingdom_thumb.jpg",
		name: "The Peaceful Kingdom",
		catagory: "animals",
		height: 14.5,
		width: 14.75
	},
	{
		imgName: "vaqueros_andrés.jpg",
		thumbName: "vaqueros_andrés_thumb.jpg",
		name: "Vaqueros Andrés",
		catagory: "animals",
		height: 14.75,
		width: 14.75
	},
	{
		imgName: "canoe_of_fate.jpg",
		thumbName: "canoe_of_fate_thumb.jpg",
		name: "Canoe of Fate",
		catagory: "animals",
		height: 12.75,
		width: 17.5
	},
	{
		imgName: "underwater_world_ii.jpg",
		thumbName: "underwater_world_ii_thumb.jpg",
		name: "Underwater World II",
		catagory: "animals",
		height: 12.75,
		width: 18.75
	},
	{
		imgName: "black_bear.jpg",
		thumbName: "black_bear_thumb.jpg",
		name: "Black Bear",
		catagory: "animals",
		height: 15.75,
		width: 20.0
	},
	{
		imgName: "large_Basque-Coast-watermark-1260.jpeg",
		thumbName: "the_basque_coast_thumb.jpg",
		name: "The Basque Coast",
		catagory: "animals",
		height: 17.5,
		width: 13.75
	},
	{
		imgName: "foraging_at_first_light.jpg",
		thumbName: "foraging_at_first_light_thumb.jpg",
		name: "Foraging at First Light",
		catagory: "animals",
		height: 7.0,
		width: 7.0
	},
	{
		imgName: "hello_dolly.jpg",
		thumbName: "hello_dolly_thumb.jpg",
		name: "Hello Dolly",
		catagory: "animals",
		height: 21.75,
		width: 10.5
	},
	{
		imgName: "mabula_elephant.jpg",
		thumbName: "mabula_elephant_thumb.jpg",
		name: "Mabula Elephant",
		catagory: "animals",
		height: 15.5,
		width: 21.25
	},
	{
		imgName: "large_Woodland-Wheel-image-1100-watermark.jpeg",
		thumbName: "woodland_wheel_thumb.jpg",
		name: "Woodland Wheel",
		catagory: "animals",
		height: 16.5,
		width: 13.75
	},
	{
		imgName: "colorful_fish_of_the_east_indies.jpg",
		thumbName: "colorful_fish_of_the_east_indies_thumb.jpg",
		name: "Colorful Fish of the East Indies",
		catagory: "animals",
		height: 14.5,
		width: 16.75
	},
	{
		imgName: "elephant_pool.jpg",
		thumbName: "elephant_pool_thumb.jpg",
		name: "Elephant Pool",
		catagory: "animals",
		height: 17.5,
		width: 12.75
	},
	{
		imgName: "lovers.jpg",
		thumbName: "lovers_thumb.jpg",
		name: "Lovers",
		catagory: "animals",
		height: 17.25,
		width: 12.75
	},
	{
		imgName: "machu_picchu.jpg",
		thumbName: "machu_picchu_thumb.jpg",
		name: "Machu Picchu",
		catagory: "animals",
		height: 20.5,
		width: 14.75
	},
	{
		imgName: "holi_raja.jpg",
		thumbName: "holi_raja_thumb.jpg",
		name: "Holi Raja",
		catagory: "animals",
		height: 19.0,
		width: 14.75
	},
	{
		imgName: "troublesome_moose.jpg",
		thumbName: "troublesome_moose_thumb.jpg",
		name: "Troublesome Moose",
		catagory: "animals",
		height: 12.75,
		width: 17.0
	},
	{
		imgName: "woodland_scene.jpg",
		thumbName: "woodland_scene_thumb.jpg",
		name: "Woodland Scene",
		catagory: "animals",
		height: 12.75,
		width: 17.75
	},
	{
		imgName: "don_quixote_and_sancho_panza_at_a_crossroads.jpg",
		thumbName: "don_quixote_and_sancho_panza_at_a_crossroads_thumb.jpg",
		name: "Don Quixote and Sancho Panza at a Crossroads",
		catagory: "animals",
		height: 8.75,
		width: 13.75
	},
	{
		imgName: "let_it_flow.jpg",
		thumbName: "let_it_flow_thumb.jpg",
		name: "Let it Flow",
		catagory: "animals",
		height: 19.75,
		width: 14.75
	},
	{
		imgName: "its_raining_cats_and_dogs.jpg",
		thumbName: "its_raining_cats_and_dogs_thumb.jpg",
		name: "It's Raining Cats and Dogs",
		catagory: "animals",
		height: 17.75,
		width: 11.75
	},
	{
		imgName: "large_Ethiopian-Fauna-image-1100-watermark.jpeg",
		thumbName: "ethiopian_fauna_thumb.jpg",
		name: "Ethiopian Fauna",
		catagory: "animals",
		height: 13.75,
		width: 16.5
	},
	{
		imgName: "i_and_the_village.jpg",
		thumbName: "i_and_the_village_thumb.jpg",
		name: "I and the Village",
		catagory: "animals",
		height: 16.25,
		width: 12.75
	},
	{
		imgName: "chasing_butterflies_66_mya.jpg",
		thumbName: "chasing_butterflies_66_mya_thumb.jpg",
		name: "Chasing Butterflies 66 Mya",
		catagory: "animals",
		height: 18.75,
		width: 15.75
	},
	{
		imgName: "the_louisiana_tree_of_life.jpg",
		thumbName: "the_louisiana_tree_of_life_thumb.jpg",
		name: "The Louisiana Tree of Life",
		catagory: "animals",
		height: 10.25,
		width: 23.75
	},
	{
		imgName: "across_the_sun_and_moon.jpg",
		thumbName: "across_the_sun_and_moon_thumb.jpg",
		name: "Across the Sun and Moon",
		catagory: "animals",
		height: 12.75,
		width: 16.75
	},
	{
		imgName: "orca.jpg",
		thumbName: "orca_thumb.jpg",
		name: "Orca",
		catagory: "animals",
		height: 10.5,
		width: 22.75
	},
	{
		imgName: "sea_turtle.jpg",
		thumbName: "sea_turtle_thumb.jpg",
		name: "Sea Turtle",
		catagory: "animals",
		height: 18.0,
		width: 13.5
	},
	{
		imgName: "sea_turtle_round.jpg",
		thumbName: "sea_turtle_round_thumb.jpg",
		name: "Sea Turtle round",
		catagory: "animals",
		height: 8.0,
		width: 6.25
	},
	{
		imgName: "octopus.jpg",
		thumbName: "octopus_thumb.jpg",
		name: "Octopus",
		catagory: "animals",
		height: 16.25,
		width: 20.75
	},
	{
		imgName: "a_plethora_of_fish.jpg",
		thumbName: "a_plethora_of_fish_thumb.jpg",
		name: "A Plethora of Fish",
		catagory: "animals",
		height: 11.75,
		width: 17.75
	},
	{
		imgName: "elephants.jpg",
		thumbName: "elephants_thumb.jpg",
		name: "Elephants",
		catagory: "animals",
		height: 12.75,
		width: 9.0
	},
	{
		imgName: "ocean_life.jpg",
		thumbName: "ocean_life_thumb.jpg",
		name: "Ocean Life",
		catagory: "animals",
		height: 15.0,
		width: 21.75
	},
	{
		imgName: "cutout_of_animals.jpg",
		thumbName: "cutout_of_animals_thumb.jpg",
		name: "Cutout of Animals",
		catagory: "animals",
		height: 13.75,
		width: 17.5
	},
	{
		imgName: "monkey_in_persimmon_tree.jpg",
		thumbName: "monkey_in_persimmon_tree_thumb.jpg",
		name: "Monkey in Persimmon Tree",
		catagory: "animals",
		height: 11.25,
		width: 9.75
	},
	{
		imgName: "best_in_show.jpg",
		thumbName: "best_in_show_thumb.jpg",
		name: "Best in Show",
		catagory: "animals",
		height: 13.75,
		width: 17.25
	},
	{
		imgName: "the_heart_of_a_bear.jpg",
		thumbName: "the_heart_of_a_bear_thumb.jpg",
		name: "The Heart of a Bear",
		catagory: "animals",
		height: 17.0,
		width: 12.75
	},
	{
		imgName: "yanapuma.jpg",
		thumbName: "yanapuma_thumb.jpg",
		name: "Yanapuma",
		catagory: "animals",
		height: 12.75,
		width: 17.0
	},
	{
		imgName: "rome_italy.jpg",
		thumbName: "rome_italy_thumb.jpg",
		name: "Rome, Italy",
		catagory: "animals",
		height: 16.75,
		width: 12.75
	},
	{
		imgName: "flight_of_the_grizzly.jpg",
		thumbName: "flight_of_the_grizzly_thumb.jpg",
		name: "Flight of the Grizzly",
		catagory: "animals",
		height: 17.25,
		width: 13.75
	},
	{
		imgName: "after_dark_in_the_park.jpg",
		thumbName: "after_dark_in_the_park_thumb.jpg",
		name: "After Dark in the Park",
		catagory: "animals",
		height: 12.75,
		width: 9.25
	},
	{
		imgName: "relaxed_in_spite_of_it.jpg",
		thumbName: "relaxed_in_spite_of_it_thumb.jpg",
		name: "Relaxed in Spite of It",
		catagory: "animals",
		height: 11.75,
		width: 21.25
	},
	{
		imgName: "australia.jpg",
		thumbName: "australia_thumb.jpg",
		name: "Australia",
		catagory: "animals",
		height: 17.75,
		width: 12.75
	},
	{
		imgName: "forever_buffs.jpg",
		thumbName: "forever_buffs_thumb.jpg",
		name: "Forever Buffs",
		catagory: "animals",
		height: 12.75,
		width: 16.25
	},
	{
		imgName: "the_best_sea_view.jpg",
		thumbName: "the_best_sea_view_thumb.jpg",
		name: "The Best Sea View",
		catagory: "animals",
		height: 13.75,
		width: 15.75
	},
	{
		imgName: "a_resounding_success.jpg",
		thumbName: "a_resounding_success_thumb.jpg",
		name: "A Resounding Success",
		catagory: "animals",
		height: 16.5,
		width: 13.75
	},
	{
		imgName: "the_horse_fair.jpg",
		thumbName: "the_horse_fair_thumb.jpg",
		name: "The Horse Fair",
		catagory: "animals",
		height: 9.75,
		width: 20.75
	},
	{
		imgName: "lion_stroll.jpg",
		thumbName: "lion_stroll_thumb.jpg",
		name: "Lion Stroll",
		catagory: "animals",
		height: 11.75,
		width: 17.5
	},
	{
		imgName: "baby_steps.jpg",
		thumbName: "baby_steps_thumb.jpg",
		name: "Baby Steps",
		catagory: "animals",
		height: 9.75,
		width: 9.75
	},
	{
		imgName: "rainbow_trout.jpg",
		thumbName: "rainbow_trout_thumb.jpg",
		name: "Rainbow Trout",
		catagory: "animals",
		height: 9.5,
		width: 20.25
	},
	{
		imgName: "cat_from_the_wrong_side_of_the_tracks.jpg",
		thumbName: "cat_from_the_wrong_side_of_the_tracks_thumb.jpg",
		name: "Cat from the Wrong Side of the Tracks",
		catagory: "animals",
		height: 20.25,
		width: 10.75
	},
	{
		imgName: "joseph_katz_and_his_coat_of_many_colors.jpg",
		thumbName: "joseph_katz_and_his_coat_of_many_colors_thumb.jpg",
		name: "Joseph Katz and His Coat of Many Colors",
		catagory: "animals",
		height: 18.75,
		width: 9.25
	},
	{
		imgName: "the_painting_of_life.jpg",
		thumbName: "the_painting_of_life_thumb.jpg",
		name: "The Painting of Life",
		catagory: "animals",
		height: 10.5,
		width: 23.75
	},
	{
		imgName: "buffalo_vision.jpg",
		thumbName: "buffalo_vision_thumb.jpg",
		name: "Buffalo Vision",
		catagory: "animals",
		height: 17.0,
		width: 12.75
	},
	{
		imgName: "buffalo_strength.jpg",
		thumbName: "buffalo_strength_thumb.jpg",
		name: "Buffalo Strength",
		catagory: "animals",
		height: 14.5,
		width: 14.75
	},
	{
		imgName: "crab.jpg",
		thumbName: "crab_thumb.jpg",
		name: "Crab",
		catagory: "animals",
		height: 13.0,
		width: 19.0
	},
	{
		imgName: "bison.jpg",
		thumbName: "bison_thumb.jpg",
		name: "Bison",
		catagory: "animals",
		height: 14.25,
		width: 20.25
	},
	{
		imgName: "archbishop_katz.jpg",
		thumbName: "archbishop_katz_thumb.jpg",
		name: "Archbishop Katz",
		catagory: "animals",
		height: 18.5,
		width: 12.75
	},
	{
		imgName: "green_cat_with_lights.jpg",
		thumbName: "green_cat_with_lights_thumb.jpg",
		name: "Green Cat with Lights",
		catagory: "animals",
		height: 18.75,
		width: 12.75
	},
	{
		imgName: "trentino_polar_bear.jpg",
		thumbName: "trentino_polar_bear_thumb.jpg",
		name: "Trentino Polar Bear",
		catagory: "animals",
		height: 12.25,
		width: 8.5
	},
	{
		imgName: "buffalo_skittles.jpg",
		thumbName: "buffalo_skittles_thumb.jpg",
		name: "Buffalo Skittles",
		catagory: "animals",
		height: 5.75,
		width: 23.75
	},
	{
		imgName: "on_the_ngare_ndare_river.jpg",
		thumbName: "on_the_ngare_ndare_river_thumb.jpg",
		name: "On the Ngare Ndare River",
		catagory: "animals",
		height: 12.75,
		width: 17.75
	},
	{
		imgName: "coral_reef.jpg",
		thumbName: "coral_reef_thumb.jpg",
		name: "Coral Reef",
		catagory: "animals",
		height: 23.75,
		width: 7.75
	},
	{
		imgName: "varieties_of_molluscs.jpg",
		thumbName: "varieties_of_molluscs_thumb.jpg",
		name: "Varieties of Molluscs",
		catagory: "animals",
		height: 17.5,
		width: 13.75
	},
	{
		imgName: "cat_detective_in_the_wrong_part_of_town.jpg",
		thumbName: "cat_detective_in_the_wrong_part_of_town_thumb.jpg",
		name: "Cat Detective in the Wrong Part of Town",
		catagory: "animals",
		height: 17.25,
		width: 12.75
	},
	{
		imgName: "barrys_band.jpg",
		thumbName: "barrys_band_thumb.jpg",
		name: "Barry's Band",
		catagory: "animals",
		height: 12.75,
		width: 8.5
	},
	{
		imgName: "electric_fox.jpg",
		thumbName: "electric_fox_thumb.jpg",
		name: "Electric Fox",
		catagory: "animals",
		height: 17.5,
		width: 12.75
	},
	{
		imgName: "histoire_naturelle.jpg",
		thumbName: "histoire_naturelle_thumb.jpg",
		name: "Histoire Naturelle",
		catagory: "animals",
		height: 18.25,
		width: 12.75
	},
	{
		imgName: "barry_the_bear.jpg",
		thumbName: "barry_the_bear_thumb.jpg",
		name: "Barry the Bear",
		catagory: "animals",
		height: 11.0,
		width: 8.75
	},
	{
		imgName: "wolf.jpg",
		thumbName: "wolf_thumb.jpg",
		name: "Wolf",
		catagory: "animals",
		height: 15.5,
		width: 19.5
	},
	{
		imgName: "two_koi.jpg",
		thumbName: "two_koi_thumb.jpg",
		name: "Two Koi",
		catagory: "animals",
		height: 17.75,
		width: 13.75
	},
	{
		imgName: "chinook_salmon.jpg",
		thumbName: "chinook_salmon_thumb.jpg",
		name: "Chinook Salmon",
		catagory: "animals",
		height: 9.5,
		width: 22.0
	},
	{
		imgName: "wolf_song.jpg",
		thumbName: "wolf_song_thumb.jpg",
		name: "Wolf Song",
		catagory: "animals",
		height: 17.75,
		width: 13.75
	},
	{
		imgName: "ladies_home_journal_large_piece.jpg",
		thumbName: "ladies_home_journal_large_piece_thumb.jpg",
		name: "Ladies Home Journal Large Piece",
		catagory: "animals",
		height: 19.0,
		width: 12.75
	},
	{
		imgName: "ladies_home_journal_all-story_number.jpg",
		thumbName: "ladies_home_journal_all-story_number_thumb.jpg",
		name: "Ladies Home Journal All-Story Number",
		catagory: "animals",
		height: 19.0,
		width: 12.75
	},
	{
		imgName: "forest_lakes.jpg",
		thumbName: "forest_lakes_thumb.jpg",
		name: "Forest Lakes",
		catagory: "animals",
		height: 12.75,
		width: 16.5
	},
	{
		imgName: "grizzly_bear.jpg",
		thumbName: "grizzly_bear_thumb.jpg",
		name: "Grizzly Bear",
		catagory: "animals",
		height: 13.5,
		width: 20.0
	},
	{
		imgName: "chameleon.jpg",
		thumbName: "chameleon_thumb.jpg",
		name: "Chameleon",
		catagory: "animals",
		height: 17.25,
		width: 12.75
	},
	{
		imgName: "merlin.jpg",
		thumbName: "merlin_thumb.jpg",
		name: "Merlin",
		catagory: "animals",
		height: 8.75,
		width: 11.25
	},
	{
		imgName: "cactus.jpg",
		thumbName: "cactus_thumb.jpg",
		name: "Cactus",
		catagory: "animals",
		height: 20.0,
		width: 12.0
	},
	{
		imgName: "the_five_senses.jpg",
		thumbName: "the_five_senses_thumb.jpg",
		name: "The Five Senses",
		catagory: "animals",
		height: 12.75,
		width: 17.5
	},
	{
		imgName: "frog_concert.jpg",
		thumbName: "frog_concert_thumb.jpg",
		name: "Frog Concert",
		catagory: "animals",
		height: 15.5,
		width: 12.75
	},
	{
		imgName: "tree_of_life.jpg",
		thumbName: "tree_of_life_thumb.jpg",
		name: "Tree of Life",
		catagory: "animals",
		height: 16.25,
		width: 18.0
	},
	{
		imgName: "buffalo.jpg",
		thumbName: "buffalo_thumb.jpg",
		name: "Buffalo",
		catagory: "animals",
		height: 9.5,
		width: 9.5
	},
	{
		imgName: "dolphin.jpg",
		thumbName: "dolphin_thumb.jpg",
		name: "Dolphin",
		catagory: "animals",
		height: 12.0,
		width: 20.0
	},
	{
		imgName: "frog.jpg",
		thumbName: "frog_thumb.jpg",
		name: "Frog",
		catagory: "animals",
		height: 16.0,
		width: 13.0
	},
	{
		imgName: "river_dance.jpg",
		thumbName: "river_dance_thumb.jpg",
		name: "River Dance",
		catagory: "animals",
		height: 16.25,
		width: 12.75
	},
	{
		imgName: "seahorse.jpg",
		thumbName: "seahorse_thumb.jpg",
		name: "Seahorse",
		catagory: "animals",
		height: 18.0,
		width: 13.5
	},
	{
		imgName: "sugar_magnolia.jpg",
		thumbName: "sugar_magnolia_thumb.jpg",
		name: "Sugar Magnolia",
		catagory: "animals",
		height: 21.0,
		width: 16.75
	},
]

export default puzzle_inventory