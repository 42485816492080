
import { Component } from "vue-property-decorator";
import { Vue, Watch, Prop } from "vue-property-decorator";
import * as mut from "@/store/modules/MutationNames";
import * as act from "@/store/modules/ActionNames";
import MatImage from "@/components/mat/MatImage.vue"
import CustomSelect from  "@/components/controls/CustomSelect.vue"
import CloseButton from "@/components/buttons/CloseButton.vue"
import { MatLevel } from "@/store/GbsStore"

@Component({
    components: {
        MatImage,
        CustomSelect,
        CloseButton
    }
})
export default class MatSelector extends Vue {


    //Button
    isInitialPuzzleSetup = this.$store.getters.isInitialPuzzleSetup
    doneButtonTitle!:string;
    
    //width
    search = '';
    sizeOptions = new Array<any>();
    defaultSizeKey = 10;
    selectedPuzzle = this.$store.getters.selectedPuzzle
    showMatWidthInfo = false
    topMatWidth = this.$store.getters.topMatWidth;

    //color
    allMats = this.$store.getters.matInventory
    matWidth = this.$store.getters.matWidth;
    topMatColor = this.$store.getters.topMatColor
    filteredMats = this.allMats

    @Prop()
    matLevel


    getSizeOptions() {
        let options = new Array<any>();
        // Determine what the largest mat size is based on puzzle size and 
        // size of mat paper: 19.5 x 25.5
        let puzzle = this.selectedPuzzle
        //Key is the actual size, value is the text representation of key as displayed in drop down box
        let largest = {value: "", key: 0}
        let widthMax;
        let lengthMax;
        if (puzzle.width > puzzle.height) {
            //This is a landscape format
            widthMax = (25.5 - puzzle.width) / 2;
            lengthMax = (19.5 - puzzle.height) / 2;
        }
        else {
            widthMax = (19.5 - puzzle.width) / 2;
            lengthMax = (25.5 - puzzle.height) / 2;
        }
        console.log("widthMax: " + widthMax)
        console.log("lengthMax: " + lengthMax)
        widthMax < lengthMax ? largest.key = widthMax : largest.key = lengthMax;

        //Round down the size to nearest 1/2"
        let increment = .5;
        var decimal = largest.key - Math.floor(largest.key);
        if (decimal > .5) {
            //Round down to .5
            largest.key  = Math.floor(largest.key) + .5;
        } else {
            //Round down to integer
            largest.key  = Math.floor(largest.key);
        }
        largest.value = largest.key + '"'
        options.push(largest);
        

        let smallestIncrement = 1;
        let nextSizeIncrement = largest.key - increment;
        while (nextSizeIncrement >= smallestIncrement) {
            //console.log("matSizeOptions.push: " + nextSizeIncrement + " / " + nextSizeIncrement + '"')
            options.push({name: nextSizeIncrement + '"', size: nextSizeIncrement});
            nextSizeIncrement = nextSizeIncrement - increment;
        }
        return options
    }

  created() {
      //this.$forceUpdate()
      console.log("creating MatSelector")
      let options = this.getSizeOptions()
      this.filteredMats = this.allMats
      console.log("got filteredMats")
      options.forEach(opt => {
          //console.log("size option: ", opt)
          this.sizeOptions.push({key: opt.size, value:opt.name })
      });
      if (this.$store.getters.isInitialPuzzleSetup) {
          this.doneButtonTitle = "Next ...";
      }
      else {
          this.doneButtonTitle = "Done"
      }
      console.log(" MatSelector created")
  }

    //color
  async handleMatColorButtonClick(name: string) {
      //console.log("Selected Mat: " + name)
      if(this.matLevel == MatLevel.TOP) {
          console.log("Selected top mat: " + name)
          await this.$store.dispatch(act.SET_TOP_MAT_COLOR, name)
      }
      if(this.matLevel == MatLevel.BOTTOM) {
          console.log("Selected bottom mat: " + name)
          await this.$store.dispatch(act.SET_BOTTOM_MAT_COLOR, name)
      }
      this.$forceUpdate();
  }
  

    //Irrelavant
    closeWindow() {
      console.log("emitting closeMatSelector")
      this.$emit("closeMatSelector")
  }

  handleShowMatWidthInfo() {
      require(`../../views/info/images/one_layer_mat_width_info.png`)
      this.showMatWidthInfo = true;
      console.log("framebuidler showMatWidthInfo")
    }

    @Watch('$store.getters.topMatWidth')
    onMatWidthChange(value: number, oldValue: number) {
        console.log("onMatWidthChange: ", value)
        this.topMatWidth = value;
        this.$forceUpdate()
    }

    @Watch('$store.getters.topMatColor')
    onMatColorChange(value: string, oldValue: string) {
        //console.log("heard change topMatColor")
        this.topMatColor = value;
        this.$forceUpdate();
    }


    closeMatSelector() {
        let eventName = "closeMatColorSelector"
        console.log("closeMatSelector emiting " + eventName)
        this.$emit(eventName)
    }

    get matColor() {
        return this.$store.getters.topMatColor
    }  


}



