const puzzle_inventory = [
	{
		imgName: "the_thames_and_the_city.jpg",
		thumbName: "the_thames_and_the_city_thumb.jpg",
		name: "The Thames and the City",
		catagory: "classical_artists",
		height: 13.75,
		width: 20.0
	},
	{
		imgName: "the_dream_garden.jpg",
		thumbName: "the_dream_garden_thumb.jpg",
		name: "The Dream Garden",
		catagory: "classical_artists",
		height: 8.5,
		width: 28.0
	},
	{
		imgName: "interior_of_st._peters_rome.jpg",
		thumbName: "interior_of_st._peters_rome_thumb.jpg",
		name: "Interior of St. Peter's, Rome",
		catagory: "classical_artists",
		height: 13.75,
		width: 17.5
	},
	{
		imgName: "girl_with_a_pearl_earring_round.jpg",
		thumbName: "girl_with_a_pearl_earring_round_thumb.jpg",
		name: "Girl with a Pearl Earring round",
		catagory: "classical_artists",
		height: 7.0,
		width: 7.0
	},
	{
		imgName: "piazza_san_marco.jpg",
		thumbName: "piazza_san_marco_thumb.jpg",
		name: "Piazza San Marco",
		catagory: "classical_artists",
		height: 13.75,
		width: 18.0
	},
	{
		imgName: "primavera_xl.jpg",
		thumbName: "primavera_xl_thumb.jpg",
		name: "Primavera XL",
		catagory: "classical_artists",
		height: 13.75,
		width: 22.0
	},
	{
		imgName: "primavera_large.jpg",
		thumbName: "primavera_large_thumb.jpg",
		name: "Primavera Large",
		catagory: "classical_artists",
		height: 11.75,
		width: 18.75
	},
	{
		imgName: "the_concert.jpg",
		thumbName: "the_concert_thumb.jpg",
		name: "The Concert",
		catagory: "classical_artists",
		height: 15.5,
		width: 13.75
	},
	{
		imgName: "storm_on_the_sea_of_galilee.jpg",
		thumbName: "storm_on_the_sea_of_galilee_thumb.jpg",
		name: "Storm on the Sea of Galilee",
		catagory: "classical_artists",
		height: 17.25,
		width: 13.75
	},
	{
		imgName: "allegory_of_painting.jpg",
		thumbName: "allegory_of_painting_thumb.jpg",
		name: "Allegory of Painting",
		catagory: "classical_artists",
		height: 15.5,
		width: 12.75
	},
	{
		imgName: "birth_of_venus.jpg",
		thumbName: "birth_of_venus_thumb.jpg",
		name: "Birth of Venus",
		catagory: "classical_artists",
		height: 11.75,
		width: 18.5
	},
	{
		imgName: "carpet_merchant_large.jpg",
		thumbName: "carpet_merchant_large_thumb.jpg",
		name: "Carpet Merchant Large",
		catagory: "classical_artists",
		height: 16.25,
		width: 12.75
	},
	{
		imgName: "carpet_merchant_xl.jpg",
		thumbName: "carpet_merchant_xl_thumb.jpg",
		name: "Carpet Merchant XL",
		catagory: "classical_artists",
		height: 20.75,
		width: 16.0
	},
	{
		imgName: "adoration_of_the_magi.jpg",
		thumbName: "adoration_of_the_magi_thumb.jpg",
		name: "Adoration of the Magi",
		catagory: "classical_artists",
		height: 15.5,
		width: 15.5
	},
	{
		imgName: "journey_of_the_magi.jpg",
		thumbName: "journey_of_the_magi_thumb.jpg",
		name: "Journey of the Magi",
		catagory: "classical_artists",
		height: 12.75,
		width: 16.75
	},
]

export default puzzle_inventory