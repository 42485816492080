const puzzle_inventory = [
	{
		imgName: "shakyamuni_buddha.jpg",
		thumbName: "shakyamuni_buddha_thumb.jpg",
		name: "Shakyamuni Buddha",
		catagory: "modern_artists",
		height: 17.0,
		width: 12.75
	},
	{
		imgName: "train_smoke.jpg",
		thumbName: "train_smoke_thumb.jpg",
		name: "Train Smoke",
		catagory: "modern_artists",
		height: 12.75,
		width: 16.75
	},
	{
		imgName: "sailboat_at_le_petit-gennevilliers.jpg",
		thumbName: "sailboat_at_le_petit-gennevilliers_thumb.jpg",
		name: "Sailboat at Le Petit-Gennevilliers",
		catagory: "modern_artists",
		height: 9.5,
		width: 12.75
	},
	{
		imgName: "strawberry_thief.jpg",
		thumbName: "strawberry_thief_thumb.jpg",
		name: "Strawberry Thief",
		catagory: "modern_artists",
		height: 7.0,
		width: 7.0
	},
	{
		imgName: "large_Coastal-Landscape-watermark-1150.jpeg",
		thumbName: "coastal_landscape_with_blooming_lilac_bush_thumb.jpg",
		name: "Coastal Landscape with Blooming Lilac Bush",
		catagory: "modern_artists",
		height: 12.75,
		width: 16.0
	},
	{
		imgName: "menton_seen_from_cap_martin.jpg",
		thumbName: "menton_seen_from_cap_martin_thumb.jpg",
		name: "Menton Seen from Cap Martin",
		catagory: "modern_artists",
		height: 13.75,
		width: 16.5
	},
	{
		imgName: "poplars_in_the_sun.jpg",
		thumbName: "poplars_in_the_sun_thumb.jpg",
		name: "Poplars in the Sun",
		catagory: "modern_artists",
		height: 16.25,
		width: 12.75
	},
	{
		imgName: "the_blue_pergola.jpg",
		thumbName: "the_blue_pergola_thumb.jpg",
		name: "The Blue Pergola",
		catagory: "modern_artists",
		height: 12.75,
		width: 16.75
	},
	{
		imgName: "houses_at_auvers.jpg",
		thumbName: "houses_at_auvers_thumb.jpg",
		name: "Houses at Auvers",
		catagory: "modern_artists",
		height: 15.875,
		width: 12.75
	},
	{
		imgName: "port_of_la_rochelle.jpg",
		thumbName: "port_of_la_rochelle_thumb.jpg",
		name: "Port of La Rochelle",
		catagory: "modern_artists",
		height: 13.75,
		width: 17.25
	},
	{
		imgName: "garden_at_st._adresse.jpg",
		thumbName: "garden_at_st._adresse_thumb.jpg",
		name: "Garden at St. Adresse",
		catagory: "modern_artists",
		height: 12.75,
		width: 17.0
	},
	{
		imgName: "blessing_of_the_tuna_fleet_at_groix.jpg",
		thumbName: "blessing_of_the_tuna_fleet_at_groix_thumb.jpg",
		name: "Blessing of the Tuna Fleet at Groix",
		catagory: "modern_artists",
		height: 13.75,
		width: 17.25
	},
	{
		imgName: "the_port_of_saint-tropez.jpg",
		thumbName: "the_port_of_saint-tropez_thumb.jpg",
		name: "The Port of Saint-Tropez",
		catagory: "modern_artists",
		height: 13.75,
		width: 17.25
	},
	{
		imgName: "la_classe_de_danse.jpg",
		thumbName: "la_classe_de_danse_thumb.jpg",
		name: "La Classe de Danse",
		catagory: "modern_artists",
		height: 13.5,
		width: 10.75
	},
	{
		imgName: "the_herb_market_verona.jpg",
		thumbName: "the_herb_market_verona_thumb.jpg",
		name: "The Herb Market, Verona",
		catagory: "modern_artists",
		height: 12.75,
		width: 16.75
	},
	{
		imgName: "cliffs_by_the_sea_at_trouville.jpg",
		thumbName: "cliffs_by_the_sea_at_trouville_thumb.jpg",
		name: "Cliffs by the Sea at Trouville",
		catagory: "modern_artists",
		height: 13.75,
		width: 17.0
	},
	{
		imgName: "a_sunday_on_la_grand_jatte_large_piece.jpg",
		thumbName: "a_sunday_on_la_grand_jatte_large_piece_thumb.jpg",
		name: "A Sunday on La Grand Jatte Large Piece",
		catagory: "modern_artists",
		height: 12.75,
		width: 19.0
	},
	{
		imgName: "the_kiss_round.jpg",
		thumbName: "the_kiss_round_thumb.jpg",
		name: "The Kiss round",
		catagory: "modern_artists",
		height: 7.0,
		width: 7.0
	},
	{
		imgName: "san_giorgio_maggiore_at_dusk.jpg",
		thumbName: "san_giorgio_maggiore_at_dusk_thumb.jpg",
		name: "San Giorgio Maggiore at Dusk",
		catagory: "modern_artists",
		height: 12.75,
		width: 18.0
	},
	{
		imgName: "luncheon_of_the_boating_party_large_piece.jpg",
		thumbName: "luncheon_of_the_boating_party_large_piece_thumb.jpg",
		name: "Luncheon of the Boating Party Large Piece",
		catagory: "modern_artists",
		height: 12.75,
		width: 16.5
	},
	{
		imgName: "starry_night_large_piece.jpg",
		thumbName: "starry_night_large_piece_thumb.jpg",
		name: "Starry Night Large Piece",
		catagory: "modern_artists",
		height: 13.75,
		width: 17.0
	},
	{
		imgName: "a_sunday_on_la_grande_jatte.jpg",
		thumbName: "a_sunday_on_la_grande_jatte_thumb.jpg",
		name: "A Sunday on La Grande Jatte",
		catagory: "modern_artists",
		height: 12.75,
		width: 19.25
	},
	{
		imgName: "country_house_by_the_attersee.jpg",
		thumbName: "country_house_by_the_attersee_thumb.jpg",
		name: "Country House by the Attersee",
		catagory: "modern_artists",
		height: 14.75,
		width: 14.0
	},
	{
		imgName: "wheat_field_with_cypresses.jpg",
		thumbName: "wheat_field_with_cypresses_thumb.jpg",
		name: "Wheat Field with Cypresses",
		catagory: "modern_artists",
		height: 12.75,
		width: 17.0
	},
	{
		imgName: "green_dancer.jpg",
		thumbName: "green_dancer_thumb.jpg",
		name: "Green Dancer",
		catagory: "modern_artists",
		height: 19.25,
		width: 10.75
	},
	{
		imgName: "the_kiss.jpg",
		thumbName: "the_kiss_thumb.jpg",
		name: "The Kiss",
		catagory: "modern_artists",
		height: 14.75,
		width: 14.75
	},
	{
		imgName: "two_young_girls_at_the_piano.jpg",
		thumbName: "two_young_girls_at_the_piano_thumb.jpg",
		name: "Two Young Girls at the Piano",
		catagory: "modern_artists",
		height: 16.6000003814697,
		width: 12.75
	},
	{
		imgName: "le_moulin_de_la_galette.jpg",
		thumbName: "le_moulin_de_la_galette_thumb.jpg",
		name: "Le Moulin de la Galette",
		catagory: "modern_artists",
		height: 15.75,
		width: 21.75
	},
	{
		imgName: "edge_of_the_forest.jpg",
		thumbName: "edge_of_the_forest_thumb.jpg",
		name: "Edge of the Forest",
		catagory: "modern_artists",
		height: 12.75,
		width: 17.5
	},
	{
		imgName: "view_of_krumau.jpg",
		thumbName: "view_of_krumau_thumb.jpg",
		name: "View of Krumau",
		catagory: "modern_artists",
		height: 12.75,
		width: 16.0
	},
	{
		imgName: "street_in_tahiti_chemin_a_papeete_large.jpg",
		thumbName: "street_in_tahiti_chemin_a_papeete_large_thumb.jpg",
		name: "Street in Tahiti (Chemin a Papeete) Large",
		catagory: "modern_artists",
		height: 17.0,
		width: 12.75
	},
	{
		imgName: "night_cafe.jpg",
		thumbName: "night_cafe_thumb.jpg",
		name: "Night Cafe",
		catagory: "modern_artists",
		height: 12.75,
		width: 16.25
	},
	{
		imgName: "impression_sunrise.jpg",
		thumbName: "impression_sunrise_thumb.jpg",
		name: "Impression, Sunrise",
		catagory: "modern_artists",
		height: 13.75,
		width: 17.0
	},
	{
		imgName: "almond_blossom.jpg",
		thumbName: "almond_blossom_thumb.jpg",
		name: "Almond Blossom",
		catagory: "modern_artists",
		height: 12.75,
		width: 16.25
	},
	{
		imgName: "large_van-gogh-terrace-website-watermark.jpeg",
		thumbName: "cafe_terrace_on_the_place_du_forum_large_thumb.jpg",
		name: "Cafe Terrace on the Place du Forum Large",
		catagory: "modern_artists",
		height: 16.25,
		width: 12.75
	},
	{
		imgName: "small_van-gogh-terrace-website-watermark.jpeg",
		thumbName: "cafe_terrace_on_the_place_du_forum_small_thumb.jpg",
		name: "Cafe Terrace on the Place du Forum Small",
		catagory: "modern_artists",
		height: 12.0,
		width: 9.25
	},
	{
		imgName: "church_in_cassone.jpg",
		thumbName: "church_in_cassone_thumb.jpg",
		name: "Church in Cassone",
		catagory: "modern_artists",
		height: 14.75,
		width: 14.75
	},
	{
		imgName: "girl_with_a_watering_can.jpg",
		thumbName: "girl_with_a_watering_can_thumb.jpg",
		name: "Girl with a Watering Can",
		catagory: "modern_artists",
		height: 17.0,
		width: 12.75
	},
	{
		imgName: "luncheon_of_the_boating_party.jpg",
		thumbName: "luncheon_of_the_boating_party_thumb.jpg",
		name: "Luncheon of the Boating Party",
		catagory: "modern_artists",
		height: 12.75,
		width: 16.5
	},
	{
		imgName: "mahone_bay.jpg",
		thumbName: "mahone_bay_thumb.jpg",
		name: "Mahone Bay",
		catagory: "modern_artists",
		height: 13.75,
		width: 16.75
	},
	{
		imgName: "on_the_terrace.jpg",
		thumbName: "on_the_terrace_thumb.jpg",
		name: "On The Terrace",
		catagory: "modern_artists",
		height: 11.75,
		width: 9.25
	},
	{
		imgName: "Peschi in Fiore.jpeg",
		thumbName: "peschi_in_fiore_thumb.jpg",
		name: "Peschi in Fiore",
		catagory: "modern_artists",
		height: 12.75,
		width: 15.75
	},
	{
		imgName: "large_pines---grand-canyon-image-750-watermark.jpeg",
		thumbName: "pines_-_grand_canyon_thumb.jpg",
		name: "Pines - Grand Canyon",
		catagory: "modern_artists",
		height: 10.25,
		width: 10.5
	},
	{
		imgName: "large_monet-red-boats.jpeg",
		thumbName: "RedBoats_thumb.jpeg",
		name: "Red Boats",
		catagory: "modern_artists",
		height: 12.75,
		width: 17.75
	},
	{
		imgName: "red_room.jpg",
		thumbName: "red_room_thumb.jpg",
		name: "Red Room",
		catagory: "modern_artists",
		height: 12.75,
		width: 16.0
	},
	{
		imgName: "red-headed_girl_with_parasol.jpg",
		thumbName: "red-headed_girl_with_parasol_thumb.jpg",
		name: "Red-Headed Girl with Parasol",
		catagory: "modern_artists",
		height: 16.25,
		width: 12.75
	},
	{
		imgName: "road_with_blossoming_trees.jpg",
		thumbName: "road_with_blossoming_trees_thumb.jpg",
		name: "Road with Blossoming Trees",
		catagory: "modern_artists",
		height: 12.75,
		width: 17.0
	},
	{
		imgName: "sails_and_pine_trees.jpg",
		thumbName: "sails_and_pine_trees_thumb.jpg",
		name: "Sails and Pine Trees",
		catagory: "modern_artists",
		height: 12.75,
		width: 9.25
	},
	{
		imgName: "starry_night.jpg",
		thumbName: "starry_night_thumb.jpg",
		name: "Starry Night",
		catagory: "modern_artists",
		height: 13.75,
		width: 17.0
	},
	{
		imgName: "starry_night_over_the_rhone.jpg",
		thumbName: "starry_night_over_the_rhone_thumb.jpg",
		name: "Starry Night Over the Rhone",
		catagory: "modern_artists",
		height: 12.75,
		width: 16.25
	},
]

export default puzzle_inventory