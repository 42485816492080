const puzzle_inventory = [
	{
		imgName: "large_Langlois-Bridge-Watermark-850.jepg",
		thumbName: "the_langlois_bridge_at_arles_with_women_washing_thumb.jpg",
		name: "The Langlois Bridge at Arles with Women Washing",
		catagory: "spring",
		height: 10.5,
		width: 11.75
	},
	{
		imgName: "birds_nest.jpg",
		thumbName: "birds_nest_thumb.jpg",
		name: "Birds Nest",
		catagory: "spring",
		height: 7.0,
		width: 7.0
	},
	{
		imgName: "patchwork_-_birds.jpg",
		thumbName: "patchwork_-_birds_thumb.jpg",
		name: "Patchwork - Birds",
		catagory: "spring",
		height: 12.75,
		width: 19.0
	},
	{
		imgName: "hens_and_peacock.jpg",
		thumbName: "hens_and_peacock_thumb.jpg",
		name: "Hens and Peacock",
		catagory: "spring",
		height: 12.75,
		width: 17.25
	},
	{
		imgName: "landscape_the_parc_monceau.jpg",
		thumbName: "landscape_the_parc_monceau_thumb.jpg",
		name: "Landscape: The Parc Monceau",
		catagory: "spring",
		height: 12.75,
		width: 17.5
	},
	{
		imgName: "a_plethora_of_flowers.jpg",
		thumbName: "a_plethora_of_flowers_thumb.jpg",
		name: "A Plethora of Flowers",
		catagory: "spring",
		height: 11.75,
		width: 17.75
	},
	{
		imgName: "la_terrasse.jpg",
		thumbName: "la_terrasse_thumb.jpg",
		name: "La Terrasse",
		catagory: "spring",
		height: 12.0,
		width: 9.75
	},
	{
		imgName: "tulips_-_the_blue_jug.jpg",
		thumbName: "tulips_-_the_blue_jug_thumb.jpg",
		name: "Tulips - The Blue Jug",
		catagory: "spring",
		height: 11.75,
		width: 14.25
	},
	{
		imgName: "peacock_and_pattern.jpg",
		thumbName: "peacock_and_pattern_thumb.jpg",
		name: "Peacock and Pattern",
		catagory: "spring",
		height: 14.75,
		width: 14.75
	},
	{
		imgName: "emerald_isle_peacock.jpg",
		thumbName: "emerald_isle_peacock_thumb.jpg",
		name: "Emerald Isle Peacock",
		catagory: "spring",
		height: 11.75,
		width: 17.75
	},
	{
		imgName: "german_butterflies.jpg",
		thumbName: "german_butterflies_thumb.jpg",
		name: "German Butterflies",
		catagory: "spring",
		height: 8.0,
		width: 12.75
	},
	{
		imgName: "beetles.jpg",
		thumbName: "beetles_thumb.jpg",
		name: "Beetles",
		catagory: "spring",
		height: 12.75,
		width: 8.0
	},
	{
		imgName: "giant_moonflower.jpg",
		thumbName: "giant_moonflower_thumb.jpg",
		name: "Giant Moonflower",
		catagory: "spring",
		height: 7.0,
		width: 7.0
	},
	{
		imgName: "when_the_fairies_came.jpg",
		thumbName: "when_the_fairies_came_thumb.jpg",
		name: "When the Fairies Came",
		catagory: "spring",
		height: 12.25,
		width: 9.75
	},
	{
		imgName: "pear_tree.jpg",
		thumbName: "pear_tree_thumb.jpg",
		name: "Pear Tree",
		catagory: "spring",
		height: 14.75,
		width: 14.75
	},
	{
		imgName: "every_girl_should_have_a_unicorn.jpg",
		thumbName: "every_girl_should_have_a_unicorn_thumb.jpg",
		name: "Every Girl Should Have a Unicorn",
		catagory: "spring",
		height: 17.5,
		width: 13.75
	},
	{
		imgName: "the_menagerie.jpg",
		thumbName: "the_menagerie_thumb.jpg",
		name: "The Menagerie",
		catagory: "spring",
		height: 19.0,
		width: 15.75
	},
	{
		imgName: "flatirons_xl.jpg",
		thumbName: "flatirons_xl_thumb.jpg",
		name: "Flatirons XL",
		catagory: "spring",
		height: 15.75,
		width: 23.75
	},
	{
		imgName: "the_woods_walk_crested_butte_colorado.jpg",
		thumbName: "the_woods_walk_crested_butte_colorado_thumb.jpg",
		name: "The Woods Walk, Crested Butte, Colorado",
		catagory: "spring",
		height: 18.25,
		width: 11.75
	},
	{
		imgName: "mountainous_landscape_behind_saint-remy.jpg",
		thumbName: "mountainous_landscape_behind_saint-remy_thumb.jpg",
		name: "Mountainous Landscape Behind Saint-Remy",
		catagory: "spring",
		height: 12.75,
		width: 16.5
	},
	{
		imgName: "apple_tree.jpg",
		thumbName: "apple_tree_thumb.jpg",
		name: "Apple Tree",
		catagory: "spring",
		height: 14.75,
		width: 14.75
	},
	{
		imgName: "burpees_seed_packet.jpg",
		thumbName: "burpees_seed_packet_thumb.jpg",
		name: "Burpee's Seed Packet",
		catagory: "spring",
		height: 12.75,
		width: 8.0
	},
	{
		imgName: "flutter_by.jpg",
		thumbName: "flutter_by_thumb.jpg",
		name: "Flutter By",
		catagory: "spring",
		height: 11.25,
		width: 16.0
	},
	{
		imgName: "artists_garden_at_giverny.jpg",
		thumbName: "artists_garden_at_giverny_thumb.jpg",
		name: "Artist's Garden at Giverny",
		catagory: "spring",
		height: 12.75,
		width: 18.5
	},
	{
		imgName: "garden_path_with_chickens.jpg",
		thumbName: "garden_path_with_chickens_thumb.jpg",
		name: "Garden Path with Chickens",
		catagory: "spring",
		height: 15.0,
		width: 14.75
	},
	{
		imgName: "spring_garden.jpg",
		thumbName: "spring_garden_thumb.jpg",
		name: "Spring Garden",
		catagory: "spring",
		height: 13.75,
		width: 13.75
	},
	{
		imgName: "flowering_garden.jpg",
		thumbName: "flowering_garden_thumb.jpg",
		name: "Flowering Garden",
		catagory: "spring",
		height: 14.75,
		width: 11.75
	},
	{
		imgName: "irises.jpg",
		thumbName: "irises_thumb.jpg",
		name: "Irises",
		catagory: "spring",
		height: 13.75,
		width: 17.75
	},
	{
		imgName: "mayflower_premium_bulbs_round.jpg",
		thumbName: "mayflower_premium_bulbs_round_thumb.jpg",
		name: "Mayflower Premium Bulbs round",
		catagory: "spring",
		height: 7.0,
		width: 7.0
	},
	{
		imgName: "farm_garden_with_sunflowers.jpg",
		thumbName: "farm_garden_with_sunflowers_thumb.jpg",
		name: "Farm Garden with Sunflowers",
		catagory: "spring",
		height: 14.75,
		width: 14.75
	},
	{
		imgName: "poppies_near_vetheuil.jpg",
		thumbName: "poppies_near_vetheuil_thumb.jpg",
		name: "Poppies Near Vetheuil",
		catagory: "spring",
		height: 12.75,
		width: 16.0
	},
	{
		imgName: "camille_monet_in_the_garden.jpg",
		thumbName: "camille_monet_in_the_garden_thumb.jpg",
		name: "Camille Monet in the Garden",
		catagory: "spring",
		height: 12.75,
		width: 9.25
	},
	{
		imgName: "flatirons.jpg",
		thumbName: "flatirons_thumb.jpg",
		name: "Flatirons",
		catagory: "spring",
		height: 11.75,
		width: 17.75
	},
	{
		imgName: "in_the_garden.jpg",
		thumbName: "in_the_garden_thumb.jpg",
		name: "In the Garden",
		catagory: "spring",
		height: 12.75,
		width: 12.75
	},
	{
		imgName: "near_kennebunkport.jpg",
		thumbName: "near_kennebunkport_thumb.jpg",
		name: "Near Kennebunkport",
		catagory: "spring",
		height: 11.75,
		width: 17.75
	},
	{
		imgName: "the_artists_garden_at_vetheuil.jpg",
		thumbName: "the_artists_garden_at_vetheuil_thumb.jpg",
		name: "The Artist's Garden at Vetheuil",
		catagory: "spring",
		height: 16.25,
		width: 12.75
	},
	{
		imgName: "blossoming_chestnut_branches.jpg",
		thumbName: "blossoming_chestnut_branches_thumb.jpg",
		name: "Blossoming Chestnut Branches",
		catagory: "spring",
		height: 12.75,
		width: 16.25
	},
	{
		imgName: "best_easter_wishes.jpg",
		thumbName: "best_easter_wishes_thumb.jpg",
		name: "Best Easter Wishes",
		catagory: "spring",
		height: 13.75,
		width: 8.75
	},
	{
		imgName: "easter_chariot.jpg",
		thumbName: "easter_chariot_thumb.jpg",
		name: "Easter Chariot",
		catagory: "spring",
		height: 8.0,
		width: 12.75
	},
	{
		imgName: "easter_collage.jpg",
		thumbName: "easter_collage_thumb.jpg",
		name: "Easter Collage",
		catagory: "spring",
		height: 18.5,
		width: 12.75
	},
	{
		imgName: "easter_egg_cottage.jpg",
		thumbName: "easter_egg_cottage_thumb.jpg",
		name: "Easter Egg Cottage",
		catagory: "spring",
		height: 14.75,
		width: 9.5
	},
	{
		imgName: "easter_egg_party.jpg",
		thumbName: "easter_egg_party_thumb.jpg",
		name: "Easter Egg Party",
		catagory: "spring",
		height: 8.0,
		width: 12.75
	},
]

export default puzzle_inventory