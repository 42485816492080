
import { Vue, Watch, Component } from "vue-property-decorator";
import * as mut from "@/store/modules/MutationNames";
import * as act from "@/store/modules/ActionNames";
import FrameImage from "@/components/frame/FrameImage.vue"
import Image from "@/models/image"
import CustomSelect from  "@/components/controls/CustomSelect.vue"

@Component({
    components: {
        FrameImage,
        CustomSelect
    }
})
export default class FrameStainSelector extends Vue {

    //Wood
    selectedWood = this.$store.getters.selectedWood;
    selectedStain = this.$store.getters.selectedStain;

    //Stain
    oakStains = this.$store.getters.oakStainInventory
    mapleStains = this.$store.getters.mapleStainInventory
    images = new Array<typeof Image>();
    
    //Button
    isFrameConfigured = this.$store.getters.isFrameConfigured;
    isInitialPuzzleSetup = this.$store.getters.isInitialPuzzleSetup
    doneButtonTitle = "Done";

    created() {
        this.selectedWood = this.selectedWood.charAt(0).toUpperCase() + this.selectedWood.slice(1);
    }

    handleStainButtonClick(stain: Image) {
        ////console.log("Selected stain: " , stain)
        this.isFrameConfigured = true;
        this.$store.dispatch(act.SET_SELECTED_STAIN, stain)
        this.$store.commit(mut.DISABLE_NEXT_BUTTON, false)
        this.$forceUpdate();
    }

    @Watch('$store.getters.isFrameConfigured')
    onFrameConfigChange(value: boolean, oldValue: boolean) {
        ////console.log("wood watcher got: " + value)
        this.isFrameConfigured = value;
    }

    @Watch('$store.getters.selectedStain')
    onStainChange(value: string, oldValue: string) {
        ////console.log("wood watcher got: " + value)
        this.selectedStain = value;
    }

    closeWindow() {
        //TODO: this state management should be in the store, but since the window only closes
        // when the done button is clicked we need to keep the build step increment here.
        ////console.log("closeFrameStainSelector buildStep is: " + this.$store.getters.buildStep)
        if(this.$store.getters.buildStep == this.$store.getters.buildSteps.SELECT_STAIN_COLOR ) {
            ////console.log("closeFrameStainSelector SET_BUILD_STEP set to BUILD_IS_COMPLETE")
            this.$store.commit(mut.SET_BUILD_STEP, this.$store.getters.buildSteps.BUILD_IS_COMPLETE)
        }
        //If the build step is not SELECT_STAIN_COLOR frame builder needs to close this window.
        //console.log("emitting closeFrameStainSelector")
        this.$emit("closeFrameStainSelector")
    }
}
