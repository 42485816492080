
import { Component, Watch } from "vue-property-decorator";
import { Vue } from "vue-property-decorator";

@Component({
    components: {
    }
})
export default class Demo extends Vue {

  
}
