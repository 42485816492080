const puzzle_inventory = [
	{
		imgName: "the_love_boat.jpg",
		thumbName: "the_love_boat_thumb.jpg",
		name: "The Love Boat",
		catagory: "holidays",
		height: 8.0,
		width: 12.75
	},
	{
		imgName: "gifts_for_good_children.jpg",
		thumbName: "gifts_for_good_children_thumb.jpg",
		name: "Gifts for Good Children",
		catagory: "holidays",
		height: 19.5,
		width: 13.25
	},
	{
		imgName: "working_late.jpg",
		thumbName: "working_late_thumb.jpg",
		name: "Working Late",
		catagory: "holidays",
		height: 8.25,
		width: 12.75
	},
	{
		imgName: "santas_workshop.jpg",
		thumbName: "santas_workshop_thumb.jpg",
		name: "Santa's Workshop",
		catagory: "holidays",
		height: 14.5,
		width: 10.75
	},
	{
		imgName: "rooftop_reindeer.jpg",
		thumbName: "rooftop_reindeer_thumb.jpg",
		name: "Rooftop Reindeer",
		catagory: "holidays",
		height: 12.75,
		width: 8.0
	},
	{
		imgName: "christmas_shoppers_madison_square.jpg",
		thumbName: "christmas_shoppers_madison_square_thumb.jpg",
		name: "Christmas Shoppers, Madison Square",
		catagory: "holidays",
		height: 11.75,
		width: 21.5
	},
	{
		imgName: "jolly_old_st._nick.jpg",
		thumbName: "jolly_old_st._nick_thumb.jpg",
		name: "Jolly Old St. Nick",
		catagory: "holidays",
		height: 18.5,
		width: 9.75
	},
	{
		imgName: "joyful_yellow_santa.jpg",
		thumbName: "joyful_yellow_santa_thumb.jpg",
		name: "Joyful Yellow Santa",
		catagory: "holidays",
		height: 21.25,
		width: 8.25
	},
	{
		imgName: "morses_santa_claus.jpg",
		thumbName: "morses_santa_claus_thumb.jpg",
		name: "Morse's Santa Claus",
		catagory: "holidays",
		height: 21.0,
		width: 9.75
	},
	{
		imgName: "santa_claus_train.jpg",
		thumbName: "santa_claus_train_thumb.jpg",
		name: "Santa Claus Train",
		catagory: "holidays",
		height: 13.75,
		width: 8.75
	},
	{
		imgName: "rockwell_santa.jpg",
		thumbName: "rockwell_santa_thumb.jpg",
		name: "Rockwell Santa",
		catagory: "holidays",
		height: 15.0,
		width: 12.75
	},
	{
		imgName: "chimney_santa.jpg",
		thumbName: "chimney_santa_thumb.jpg",
		name: "Chimney Santa",
		catagory: "holidays",
		height: 11.75,
		width: 9.5
	},
	{
		imgName: "cupid_garden_party.jpg",
		thumbName: "cupid_garden_party_thumb.jpg",
		name: "Cupid Garden Party",
		catagory: "holidays",
		height: 8.5,
		width: 13.5
	},
	{
		imgName: "love_letters.jpg",
		thumbName: "love_letters_thumb.jpg",
		name: "Love Letters",
		catagory: "holidays",
		height: 12.75,
		width: 8.5
	},
	{
		imgName: "loving_thoughts.jpg",
		thumbName: "loving_thoughts_thumb.jpg",
		name: "Loving Thoughts",
		catagory: "holidays",
		height: 11.0,
		width: 10.0
	},
	{
		imgName: "to_my_valentine.jpg",
		thumbName: "to_my_valentine_thumb.jpg",
		name: "To My Valentine",
		catagory: "holidays",
		height: 9.0,
		width: 14.25
	},
	{
		imgName: "valentine_ribbons.jpg",
		thumbName: "valentine_ribbons_thumb.jpg",
		name: "Valentine Ribbons",
		catagory: "holidays",
		height: 8.25,
		width: 12.75
	},
	{
		imgName: "valentine_with_cherubs.jpg",
		thumbName: "valentine_with_cherubs_thumb.jpg",
		name: "Valentine with Cherubs",
		catagory: "holidays",
		height: 12.75,
		width: 8.0
	},
	{
		imgName: "easter_egg_chicks.jpg",
		thumbName: "easter_egg_chicks_thumb.jpg",
		name: "Easter Egg Chicks",
		catagory: "holidays",
		height: 12.75,
		width: 8.0
	},
	{
		imgName: "easter_egg_rabbit.jpg",
		thumbName: "easter_egg_rabbit_thumb.jpg",
		name: "Easter Egg Rabbit",
		catagory: "holidays",
		height: 12.75,
		width: 8.0
	},
	{
		imgName: "hoppy_easter.jpg",
		thumbName: "hoppy_easter_thumb.jpg",
		name: "Hoppy Easter",
		catagory: "holidays",
		height: 8.25,
		width: 13.0
	},
	{
		imgName: "jelly_bean_bandits.jpg",
		thumbName: "jelly_bean_bandits_thumb.jpg",
		name: "Jelly Bean Bandits",
		catagory: "holidays",
		height: 8.0,
		width: 12.75
	},
	{
		imgName: "shamrock_of_ireland.jpg",
		thumbName: "shamrock_of_ireland_thumb.jpg",
		name: "Shamrock of Ireland",
		catagory: "holidays",
		height: 8.5,
		width: 13.25
	},
	{
		imgName: "the_witching_hour.jpg",
		thumbName: "the_witching_hour_thumb.jpg",
		name: "The Witching Hour",
		catagory: "holidays",
		height: 12.75,
		width: 8.0
	},
]

export default puzzle_inventory