
import { Component } from "vue-property-decorator";
import { Vue } from "vue-property-decorator";


@Component({
    components: {
    }
})
export default class Home extends Vue {
  created() {
      //console.log("Home page has been created.")
  }


  onPuzzleClick() {
      //this.$router.push( "/demo");
  }
}
