
import { Component } from "vue-property-decorator";
import { Vue } from "vue-property-decorator";

@Component({
    components: {
    }
})
export default class Samples extends Vue {
    sampleCarousel = this.$store.getters.samplesInventory
    columns = [
        {
            field: "itemAttribute",
            label: "",
            width: 250
        },
        {
            field: "selectedValue",
            label: "",
            width: 300
        }
    ]
    

  created() {
      this.sampleCarousel.forEach(sample => {
          require(`../../assets/images/samples/${sample.imgName}`)
      });     
      
  }


}
