
import { Component, Prop, Watch } from "vue-property-decorator";
import { Vue } from "vue-property-decorator";
import * as mut from '@/store/modules/MutationNames';
import * as act from '@/store/modules/ActionNames';
import DoubleMatPuzzleFrame from  "@/components/puzzles/DoubleMatPuzzleFrame.vue"
import PuzzleSelector from  "@/components/puzzles/PuzzleSelector.vue"
import PuzzleSearchTextBox from "@/components/puzzles/PuzzleSearchTextBox.vue"
import PuzzleLineItem from  "@/components/tabs/frameBuilder/PuzzleLineItem.vue"
import StainLineItem from  "@/components/tabs/frameBuilder/StainLineItem.vue"
import MatColorSelector from  "@/components/mat/MatColorSelector.vue"
import MatWidthSelector from "@/components/mat/MatWidthSelector.vue"
import FrameWoodSelector from  "@/components/frame/FrameWoodSelector.vue"
import FrameStainSelector from  "@/components/frame/FrameStainSelector.vue"
import CustomSelect from  "@/components/controls/CustomSelect.vue"
import MatTypeLineItem from  "@/components/tabs/frameBuilder/MatTypeLineItem.vue"
import Image from '@/models/image';
import MatImage from "@/components/mat/MatImage.vue"
import AddToCartButton from "@/components/buttons/AddToCartButton.vue"
import NextButton from "@/components/buttons/NextButton.vue"
import BuildFrameButton from "@/components/buttons/BuildFrameButton.vue"
import FullScreenPreview from  "@/components/frame/FullScreenPreview.vue"
import PencilButton from "@/components/buttons/PencilButton.vue"
import InfoButton from "@/components/buttons/InfoButton.vue"
import CancelButton from "@/components/buttons/CancelButton.vue"
import TopMatColorLineItem from "@/components/tabs/frameBuilder/TopMatColorLineItem.vue"
import BottomMatColorLineItem from "@/components/tabs/frameBuilder/BottomMatColorLineItem.vue"
import BottomMatRevealSelector from "@/components/mat/BottomMatRevealSelector.vue"
import {DEFAULT_TOP_MAT_WIDTH, MatLevel, MatTypes} from "@/store/GbsStore"

function sleep(seconds) {
   let milliseconds = seconds * 1000
   const promise = new Promise(resolve => {
       setTimeout(() => {
         resolve('done sleeping');
       }, milliseconds);
     });
   return promise
}

@Component({
    components: {
        DoubleMatPuzzleFrame,
        PuzzleSearchTextBox,
        PuzzleSelector,
        CustomSelect,
        MatImage,
        MatColorSelector,
        MatWidthSelector,
        FrameWoodSelector,
        FrameStainSelector,
        AddToCartButton,
        NextButton,
        BuildFrameButton,
        FullScreenPreview,
        PencilButton,
        InfoButton,
        MatTypeLineItem,
        PuzzleLineItem,
        CancelButton,
        TopMatColorLineItem,
        BottomMatColorLineItem,
        BottomMatRevealSelector,
        StainLineItem
    }
})
export default class FrameBuilder extends Vue {
    showUpdateTooltip = false;
    selectedPuzzle!: Image
    puzzleOptions =  new Array<any>()
    showFramePicker = false;
    //isMatConfigured = this.$store.getters.isMatConfigured;
    isFrameConfigured = this.$store.getters.isFrameConfigured;
    matInventory = this.$store.getters.matInventory
    topMatColor = this.$store.getters.topMatColor
    selectedMat = this.$store.getters.selectedMat;
    matImagePath!: string;
    isInitialPuzzleSetup = this.$store.getters.isInitialPuzzleSetup;
    selectedStain = this.$store.getters.selectedStain;
    orderItemRows = this.$store.getters.orderItemRows;
    
    //stainImage = this.$store.getters.selectedStainImageName
    matTypes = this.$store.getters.matTypes;
    topMatTitle = "";
    matCompleteButtonTitle = "Done";
    isFullScreen = false;
    showWidthWarning = false;
    isWidthWarned = false;
    showFrameTooltip = false;
    widthWarningText = "";
    showMatWidthInfo = false;


    //BuildStep 
    buildStep = this.$store.getters.buildStep
    buildSteps = this.$store.getters.buildSteps
    matLevel = MatLevel
   
    orderItemColumns = [
    {
            field: "itemAttribute",
            label: "Puzzle:",
            width: 150
        },
      ]

    sectionTitle = "";
    lineItemsTitle = "Your Puzzle Frame";
    selectTopMatColorTitle = ""
    selectPuzzleTitle = "Select the puzzle you want to frame:"

    created() {
        this.sectionTitle = this.selectPuzzleTitle;
        ////console.log("creating FrameBuilder at step: " + this.getReadableBuildStep(this.$store.getters.buildStep))
        // Warn mobile devices 
        let details = navigator.userAgent;
        let regexp = /android|iphone|kindle/i;
        let isMobileDevice = regexp.test(details);
        if (isMobileDevice) {
            this.widthWarningText = 'This web site has not yet been optimized for small screens.'
            this.showWidthWarning = true;
        } 
        //or Watch for small browser windows
        else {
          this.widthWarningText = 'This web site has not yet been optimized for small screens. \n Increase your browser window size for an optimal visual experience'
          window.addEventListener("resize", this.resizeHandler);
          this.evaluateWindowWidth(window.innerWidth)
        }

        //console.error("WARNING: FrameBuilder is adding test item to the cart")
        //this.$store.dispatch(act.ADD_TEST_ORDER_TO_CART)
        ////console.log("createing frame builder with stain image: " + this.stainImage)
        require(`../../frame/images/default_stain.png`)

        if (this.selectedMat) {
            this.matImagePath = "img/" + this.selectedMat.imgName
        }
        for (let i = 0; i < this.puzzleInventory.length; i++) {
            let option = {key: i, 
                          value: this.puzzleInventory[i].name}
            this.puzzleOptions.push(option)
        }
        
        if (this.$store.getters.selectedPuzzle) {
            this.selectedPuzzle = this.$store.getters.selectedPuzzle
            this.setPuzzleParameters(this.$store.getters.selectedPuzzle);
            ////console.log("created with selected puzzle: ", this.selectedPuzzle)
        }
        
        /*TODO: this is based on the old Framebuilder, this should be refactored
        if (this.$store.getters.selectedPuzzle && this.$store.getters.isInitialPuzzleSetup && !this.$store.getters.selectedMat.imgName) {
            this.showMatPicker = true;
        }
        else if (this.$store.getters.isInitialPuzzleSetup && this.$store.getters.selectedMat.imgName && !this.$store.getters.isFrameConfigured) {
            this.showFramePicker = true;
        }*/
        
    }

    handleNextClick() {
        if (this.$store.getters.buildStep == this.$store.getters.selectionWindow ||
            this.$store.getters.buildStep == this.$store.getters.buildSteps.SELECT_MAT_TYPE) {
            this.$store.dispatch(act.INCREMENT_BUILD_STEP);
        }
        else{
            this.$store.commit(mut.SET_SHOW_NEXT_BUTTON, false);
            this.$store.commit(mut.SET_SELECTION_WINDOW, this.$store.getters.selectionWindows.NONE);
        }
    }

    handleEditBottomMatColorClick() {
        //console.log("handleEditBottomMatColorClick heard the click")
        this.$store.commit(mut.SET_SELECTION_WINDOW, this.$store.getters.selectionWindows.SELECT_BOTTOM_MAT_COLOR);
        this.$store.commit(mut.SET_SHOW_NEXT_BUTTON, true);
    }

    handleEditStainClick() {
        this.$store.commit(mut.SET_SELECTION_WINDOW, this.$store.getters.selectionWindows.SELECT_STAIN_COLOR);
        this.$store.commit(mut.SET_SHOW_NEXT_BUTTON, true);
    }
    
    async resizeHandler(e) { 
      this.evaluateWindowWidth(e.target.innerWidth)
    }

    async evaluateWindowWidth(width: number) {
      if (width < 900) {
        this.showWidthWarning = true;
        await sleep(10)
        this.showWidthWarning = false;
      }
      if (width > 900 ) {
         this.showWidthWarning = false;
      }
    }

    //TODO remove
    setPuzzleParameters(p: Image) {
        ////console.log("setPuzzleParameters about to call CALCULATE_MAT_SIZES")
        //this.$store.dispatch(act.CALCULATE_MAT_SIZES)
        //this.setFrameSize();
        this.$forceUpdate()
    }


    handleEditTopMatColorClick() {
        this.$store.commit(mut.SET_SELECTION_WINDOW, this.$store.getters.selectionWindows.SELECT_TOP_MAT_COLOR);
        this.$store.commit(mut.SET_SHOW_NEXT_BUTTON, true);
    }

    get puzzleInventory() {
        return this.$store.getters.puzzleInventory
    }

    get frameColor() {
        return this.$store.getters.frameColor
    }  
    

    buildNewFrame() {
        ////console.log("build new frame was clicked")
        this.$store.dispatch(act.START_NEW_PUZZLE)
        this.$forceUpdate()
    }

    async handleAddToCart() {
        this.$store.dispatch(act.ADD_ORDER_TO_CART)
        window.scrollTo({ top: 0, behavior: 'smooth' });
      
        //await this.$store.commit(mut.SET_BUILD_STEP, this.$store.getters.buildSteps.ADD_ORDER_TO_CART)
        this.$store.commit(mut.RESET_PUZZLE);
    }

    async resetPuzzle() {
      this.$store.dispatch(act.START_NEW_PUZZLE)
      this.$forceUpdate()
    }


    openPuzzleSelector() {
        ////console.log("openPuzzlePopup setting showSelectPuzzle = true;")
        this.$store.commit(mut.SET_SELECTION_WINDOW, this.$store.getters.selectionWindows.SELECT_PUZZLE);
        this.$store.commit(mut.SET_SHOW_NEXT_BUTTON, true);
        this.showFramePicker = false;
        this.$forceUpdate()
    }

    openPuzzleInfo() {
      alert("There is no puzzle info page.")
    }

    closePuzzlePopup() {
        if (this.$store.getters.buildStep == this.$store.getters.buildSteps.SELECT_PUZZLE) {
          //this.incrementBuildStep(1)
        }
        
        this.$forceUpdate()
    }

    closeFrameSelector() {
        this.showFramePicker = false;
        this.isFrameConfigured = true;
        this.$store.commit(mut.SET_IS_FRAME_CONFIGURED, true)
        this.$store.commit(mut.SET_IS_INITIAL_PUZZLE_SETUP, false)
        this.$forceUpdate()
    }

    //TODO remove
    selectPuzzle() {
        ////console.log("select puzzle click")
    }

    changeFrameStyle() {
        ////console.log("select frame color click")
        //console.log("starting changeFrameStyle() ")
        this.showFramePicker = true;
        //this.showMatPicker = false;
    }

    changeMatStyle() {
        //console.log("starting changeMatStyle() ")
        //this.showMatPicker = true;
        this.showFramePicker = false;
        //this.$store.commit(mut.SET_IS_MAT_CONFIGURED, false)
    }

    handlePuzzleChange(event) {
        let puzzleOpt = this.puzzleOptions.find(p => p.key == event)
        ////console.log("new puzzle change to: ", puzzleOpt.value)
        //TODO: Does this work with dispatch?
        this.$store.dispatch(mut.SET_SELECTED_PUZZLE, puzzleOpt.value)
    }

    async toggleTip() {
        //console.log("show update tool tip for 5 seconds")
        this.showUpdateTooltip = true;
        await sleep(3)
        this.showUpdateTooltip = false;
    }

    async toggleFrameTip() {
        ////console.log("show update tool tip for 5 seconds")
        this.showFrameTooltip = true;
        await sleep(10)
        this.showFrameTooltip = false;
    }

    openFullScreen() {
        //console.log("open full screen")
        this.isFullScreen = true;
    }

    closeFullScreen() {
        //console.log("close full screen")
        this.isFullScreen = false;
    }

    getReadableBuildStep(stepNumber: number) {
        let step = ""
        switch(stepNumber) {
            case 0:
                step = "SELECT_PUZZLE";
                break;
            case 1:
                step = "SELECT_MAT_TYPE";
                break;
            case 2:
                step = "SELECT_TOP_MAT_COLOR";
                break;
            case 3:
                step = "SELECT_TOP_MAT_WIDTH";
                break;
            case 4:
                step = "SELECT_BOTTOM_MAT_COLOR";
                break;
            case 5:
                step = "SELECT_BOTTOM_MAT_REVEAL";
                break;
            case 6:
                step = "SELECT_WOOD_TYPE";
                break;
            case 7:
                step = "SELECT_STAIN_COLOR";
                break;
            case 8:
                step = "BUILD_IS_COMPLETE";
                break;
            case 9:
                step = "IS_ADDED_TO_CART";
                break;
        }
        return step;
    }

    @Watch('$store.getters.buildStep')
    onBuildStepChange(value: number) {
        //console.log("build step is now: " + this.getReadableBuildStep(value))
        //console.log("build step was   : " + this.getReadableBuildStep(oldval))
        //console.log("build step is now: " + this.getReadableBuildStep(value))
        //After mat width is selected scroll to bottom of screnn
    }

    @Watch('$store.getters.selectionWindow')
    onWindowChange(value: number) {
        switch(value) {
            case this.$store.getters.selectionWindows.NONE:
                this.sectionTitle = "Your Puzzle Frame:";
                break;
            case this.$store.getters.selectionWindows.SELECT_PUZZLE:
                this.sectionTitle = "Select the puzzle you want to frame:";
                break;
            case this.$store.getters.selectionWindows.SELECT_TOP_MAT_COLOR:
                if (this.$store.getters.matType == this.$store.getters.matTypes.SINGLE) {
                    this.sectionTitle = "Select the mat color:";
                }
                else {
                    this.sectionTitle = "Select the primary mat color:";
                }
                break;
            case this.$store.getters.selectionWindows.SELECT_BOTTOM_MAT_COLOR:
                this.sectionTitle = "Select the accent mat color:";
                break;
            case this.$store.getters.selectionWindows.SELECT_STAIN_COLOR:
                const wood = this.$store.getters.selectedWood
                this.sectionTitle = "Select the " + wood + " stain color:";
                break;
                
        }
    }
    
    @Watch('$store.getters.selectedPuzzle')
    onPuzzleChange(value: Image, oldValue: Image) {
        this.selectedPuzzle = value;
        this.setPuzzleParameters(value);
    }

    @Watch('$store.getters.isInitialPuzzleSetup')
    onInitialSetupToggle(value: boolean, oldValue: boolean) {
        ////console.log("isInitialPuzzleSetup was: " + oldValue + ". Is now: " + value)
        this.isInitialPuzzleSetup = value;
        //For some reason oldValue is always coming into here set to true, so measure how big the car is instead 
        //if (oldValue == true && value == false) {
            ////console.log("cart length: " + this.$store.getters.cart.length)
        if (this.$store.getters.cart.length == 0) {
            ////console.log("show update toggleTip")
            this.toggleTip()
        }
    }

    /*@Watch('$store.getters.isMatConfigured')
    onIsMateConfigToggle(value: boolean, oldValue: boolean) {
        this.isMatConfigured = value;
        if (this.isMatConfigured) {
            this.matCompleteButtonTitle = "Done"
        }
        else {
            this.matCompleteButtonTitle = "Next..."
        }
    }*/

    @Watch('$store.getters.selectedMat')
    onMatChange(value: Image, oldValue: Image) {
        ////console.log("onMatChange: ", value)
        this.selectedMat = value;
        this.matImagePath = "img/" + value.imgName
        /*After first puzzle selection selectedMat will change with empty value
        if (value.imgName) {
            this.showMatPicker = false;
        }*/
        
        this.$forceUpdate()
    }

    @Watch('$store.getters.topMatColor')
    onMatColorChange(value: Image, oldValue: Image) {
        this.topMatColor = value;
        this.$forceUpdate()
    }

    @Watch('$store.getters.matType')
    onMatTypeChange(value: number) {
        if (this.$store.getters.buildStep == this.$store.getters.buildSteps.SELECT_MAT_TYPE) {
          //this is how we detect mat type selection via radiobutton
          //this.incrementBuildStep(1)
        }
        this.$forceUpdate()
    }


    @Watch('$store.getters.isFrameConfigured')
    onIsFrameConfigToggle(value: boolean, oldValue: boolean) {
        ////console.log("Frame builder heard isFrameConfigured: ", value)
        this.isFrameConfigured = value;
    }

    @Watch('$store.getters.selectedStain')
    onStainChange(value: string, oldValue: boolean) {
        //console.log("Frame builder heard selected stain: ", value)
        if (!this.selectedStain) {
          this.toggleFrameTip()
        }
        this.selectedStain = value;
         this.$forceUpdate()
    }

    /*@Watch('$store.getters.selectedStainImageName')
    onStainImageChange(value: string, oldValue: boolean) {
        ////console.log("Frame builder heard selected stain image: ", value)
        this.stainImage = value;
    }*/
    

    @Watch('$store.getters.orderItemRows')
    onRowChange(value: Array<any>, oldValue: Array<any>) {
        this.orderItemRows = value;
        this.$forceUpdate()
    }


    @Watch('$store.getters.topMatWidth')
    async ontopMatWidthChange(value: number) {
        //Wait for the frame to resize then scroll down to make sure the bottom of frame is exposed
        await sleep(.5)
        window.scrollTo(0, document.body.scrollHeight);
    }

    
    
}
