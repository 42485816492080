
import { Component } from "vue-property-decorator";
import { Vue, Watch } from "vue-property-decorator";
import * as mut from "@/store/modules/MutationNames";
import PuzzleImageThumb from "@/components/puzzles/PuzzleImageThumb.vue"
import Image from "@/models/image"
import 'tachyons'

//Implemented with https://codepen.io/tomascherry/pen/GrgbzQ
// https://levelup.gitconnected.com/display-images-like-google-and-flickr-with-vue-js-e4cb8159ecfa
@Component({
    components: {
        PuzzleImageThumb
    }
})
export default class PuzzleSelector extends Vue {
    filteredPuzzles!: Array<Image>;
    images = new Array<typeof Image>();
    allPuzzles = this.$store.getters.puzzleInventory
    isInitialPuzzleSetup = this.$store.getters.isInitialPuzzleSetup
    search = '';
    pixelRatio = this.$store.getters.pixelRatio / 2
    isChosen = false;

  created() {
    //console.log("Select Puzzle has been created ")
    this.filteredPuzzles = this.allPuzzles
  }

  handleCancelClick() {
      //console.log("Cancel Puzzle popup has been clicked.")
      this.closeWindow();
  }

  async handleButtonClick(s) {
      
      const currentName = this.$store.getters.selectedPuzzle.name
      if (currentName === s) {
        return;
      }
      this.$store.commit(mut.SET_IS_PUZZLE_LOADED, false)
      this.$store.commit(mut.SET_SELECTED_PUZZLE, s)
      this.$store.commit(mut.DISABLE_NEXT_BUTTON, false)
       //Make sure the next button is exposed
      window.scrollTo(0, document.body.scrollHeight);
      this.$forceUpdate();
  }

  closeWindow() {
      this.$emit("closePuzzleSelector")
  }

  

  @Watch('$store.getters.puzzleSearchText')
   onSearchChange(value: string, oldValue: string) {
        this.filterPuzzles(value);
    }

  filterPuzzles(value: string) {
        let puzzles = new Array<Image>();
        this.allPuzzles.forEach(puzzle => {
            let lcPuzzle = puzzle.name.toLowerCase();
            let lcValue = value.toLowerCase();
            if (lcPuzzle.includes(lcValue)) {
                puzzles.push(puzzle)
            }
        });
        this.filteredPuzzles = puzzles;
        this.$forceUpdate();
  }

}


