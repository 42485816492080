const puzzle_inventory = [
	{
		imgName: "palisade_peach_basket.jpg",
		thumbName: "palisade_peach_basket_thumb.jpg",
		name: "Palisade Peach Basket",
		catagory: "botanicals",
		height: 14.0,
		width: 11.5
	},
	{
		imgName: "poppy.jpg",
		thumbName: "poppy_thumb.jpg",
		name: "Poppy",
		catagory: "botanicals",
		height: 10.25,
		width: 10.75
	},
	{
		imgName: "social_graces.jpg",
		thumbName: "social_graces_thumb.jpg",
		name: "Social Graces",
		catagory: "botanicals",
		height: 17.5,
		width: 13.75
	},
	{
		imgName: "sunflower.jpg",
		thumbName: "sunflower_thumb.jpg",
		name: "Sunflower",
		catagory: "botanicals",
		height: 11.75,
		width: 12.25
	},
	{
		imgName: "chrysanthemums.jpg",
		thumbName: "chrysanthemums_thumb.jpg",
		name: "Chrysanthemums",
		catagory: "botanicals",
		height: 13.75,
		width: 16.5
	},
	{
		imgName: "the_pink_tablecloth.jpg",
		thumbName: "the_pink_tablecloth_thumb.jpg",
		name: "The Pink Tablecloth",
		catagory: "botanicals",
		height: 9.0,
		width: 12.75
	},
	{
		imgName: "vines_amongst_the_rockies.jpg",
		thumbName: "vines_amongst_the_rockies_thumb.jpg",
		name: "Vines Amongst the Rockies",
		catagory: "botanicals",
		height: 10.75,
		width: 21.75
	},
	{
		imgName: "abundance.jpg",
		thumbName: "abundance_thumb.jpg",
		name: "Abundance",
		catagory: "botanicals",
		height: 13.75,
		width: 17.75
	},
	{
		imgName: "vegetables_and_plants.jpg",
		thumbName: "vegetables_and_plants_thumb.jpg",
		name: "Vegetables and Plants",
		catagory: "botanicals",
		height: 17.25,
		width: 12.75
	},
	{
		imgName: "channeling_jerry.jpg",
		thumbName: "channeling_jerry_thumb.jpg",
		name: "Channeling Jerry",
		catagory: "botanicals",
		height: 17.25,
		width: 12.75
	},
	{
		imgName: "more_blooms_in_a_basket.jpg",
		thumbName: "more_blooms_in_a_basket_thumb.jpg",
		name: "More Blooms in a Basket",
		catagory: "botanicals",
		height: 12.75,
		width: 17.5
	},
	{
		imgName: "mels_belles.jpg",
		thumbName: "mels_belles_thumb.jpg",
		name: "Mel's Belles",
		catagory: "botanicals",
		height: 13.75,
		width: 17.25
	},
	{
		imgName: "water_lilies_1916.jpg",
		thumbName: "water_lilies_1916_thumb.jpg",
		name: "Water Lilies, 1916",
		catagory: "botanicals",
		height: 14.75,
		width: 14.75
	},
	{
		imgName: "colorado_columbine.jpg",
		thumbName: "colorado_columbine_thumb.jpg",
		name: "Colorado Columbine",
		catagory: "botanicals",
		height: 7.0,
		width: 7.0
	},
	{
		imgName: "dahlias.jpg",
		thumbName: "dahlias_thumb.jpg",
		name: "Dahlias",
		catagory: "botanicals",
		height: 7.0,
		width: 7.0
	},
	{
		imgName: "heirloom_berry_assortment.jpg",
		thumbName: "heirloom_berry_assortment_thumb.jpg",
		name: "Heirloom Berry Assortment",
		catagory: "botanicals",
		height: 8.0,
		width: 13.25
	},
	{
		imgName: "large_garden-harvest-image-1200-watermark.jpg",
		thumbName: "garden_harvest_thumb.jpg",
		name: "Garden Harvest",
		catagory: "botanicals",
		height: 19.75,
		width: 7.75
	},
	{
		imgName: "orchids_in_east_window.jpg",
		thumbName: "orchids_in_east_window_thumb.jpg",
		name: "Orchids in East Window",
		catagory: "botanicals",
		height: 17.0,
		width: 13.75
	},
	{
		imgName: "harpers_bazar.jpg",
		thumbName: "harpers_bazar_thumb.jpg",
		name: "Harper's Bazar",
		catagory: "botanicals",
		height: 17.0,
		width: 11.75
	},
	{
		imgName: "van_gogh_flower_trio.jpg",
		thumbName: "van_gogh_flower_trio_thumb.jpg",
		name: "Van Gogh Flower Trio",
		catagory: "botanicals",
		height: 11.25,
		width: 27.0
	},
	{
		imgName: "nantucket_basket.jpg",
		thumbName: "nantucket_basket_thumb.jpg",
		name: "Nantucket Basket",
		catagory: "botanicals",
		height: 13.5,
		width: 15.5
	},
	{
		imgName: "blossoms_on_the_tama_river_embankment.jpg",
		thumbName: "blossoms_on_the_tama_river_embankment_thumb.jpg",
		name: "Blossoms on the Tama River Embankment",
		catagory: "botanicals",
		height: 19.5,
		width: 12.75
	},
	{
		imgName: "water_lilies_schille.jpg",
		thumbName: "water_lilies_schille_thumb.jpg",
		name: "Water Lilies, Schille",
		catagory: "botanicals",
		height: 13.75,
		width: 16.25
	},
	{
		imgName: "lilies.jpg",
		thumbName: "lilies_thumb.jpg",
		name: "Lilies",
		catagory: "botanicals",
		height: 19.75,
		width: 11.75
	},
	{
		imgName: "carnation_lily_lily_rose.jpg",
		thumbName: "carnation_lily_lily_rose_thumb.jpg",
		name: "Carnation, Lily, Lily, Rose",
		catagory: "botanicals",
		height: 15.75,
		width: 13.75
	},
	{
		imgName: "the_room_of_flowers.jpg",
		thumbName: "the_room_of_flowers_thumb.jpg",
		name: "The Room of Flowers",
		catagory: "botanicals",
		height: 14.75,
		width: 14.75
	},
	{
		imgName: "pinecone_and_pomegranate_wreath.jpg",
		thumbName: "pinecone_and_pomegranate_wreath_thumb.jpg",
		name: "Pinecone and Pomegranate Wreath",
		catagory: "botanicals",
		height: 7.0,
		width: 7.0
	},
]

export default puzzle_inventory