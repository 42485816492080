const puzzle_inventory = [
	{
		imgName: "castle_neuschwanstein.jpg",
		thumbName: "castle_neuschwanstein_thumb.jpg",
		name: "Castle Neuschwanstein",
		catagory: "stolen_art",
		height: 18.0,
		width: 14.75
	},
]

export default puzzle_inventory