
import { Component } from "vue-property-decorator";
import { Prop } from "vue-property-decorator";
import { Vue } from "vue-property-decorator";

interface DropdownOption {
    key: number;
    value: string;
}

@Component({
})
export default class CustomSelect extends Vue {
    isDirty = false;
    origKey!:number;
    optKey!:number;
    optionTitle = "Select..."
    topMatWidth = this.$store.getters.topMatWidth

    @Prop()
    placeholder!: string;

    @Prop()
    optionKey!: number;

    @Prop()
    propName!: string;

    @Prop()
    options!: Array<DropdownOption>;

    @Prop()
    disable!: boolean;

    created() {
        if (this.topMatWidth) {
            this.origKey = -1
        }
        this.optKey = this.optionKey
        
    }

    handleSelectionChange(e) {
        //Trigger value change event
        let eventName = this.propName + "SelectionChange"
        //console.log("event : " + e)
        this.$emit(eventName, e)
    }

}
