const puzzle_inventory = [
	{
		imgName: "map_of_london_large_piece.jpg",
		thumbName: "map_of_london_large_piece_thumb.jpg",
		name: "Map of London Large Piece",
		catagory: "large_piece_puzzles",
		height: 12.75,
		width: 18.0
	},
	{
		imgName: "primavera_large_piece.jpg",
		thumbName: "primavera_large_piece_thumb.jpg",
		name: "Primavera Large Piece",
		catagory: "large_piece_puzzles",
		height: 11.75,
		width: 18.75
	},
	{
		imgName: "the_great_wave_large_piece.jpg",
		thumbName: "the_great_wave_large_piece_thumb.jpg",
		name: "The Great Wave Large Piece",
		catagory: "large_piece_puzzles",
		height: 12.75,
		width: 17.25
	},
]

export default puzzle_inventory