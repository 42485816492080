import {default as winter_puzzles} from "@/store/inventory/winterPuzzles" 
import {default as spring_puzzles} from "@/store/inventory/springPuzzles"
import {default as summer_puzzles} from "@/store/inventory/summerPuzzles"
import {default as fall_puzzles} from "@/store/inventory/fallPuzzles"
import {default as americana_puzzles} from "@/store/inventory/americanaPuzzles" 
import {default as animals_puzzles} from "@/store/inventory/animalsPuzzles"
import {default as birds_puzzles} from "@/store/inventory/birdsPuzzles"
import {default as botanicals_puzzles} from "@/store/inventory/botanicalsPuzzles"
import {default as maps_puzzles} from "@/store/inventory/mapsPuzzles"
import {default as travel_posters_puzzles} from "@/store/inventory/travel_postersPuzzles"
import {default as vintage_prints_puzzles} from "@/store/inventory/vintage_printsPuzzles"
import {default as art_nouveau_puzzles} from "@/store/inventory/art_nouveauPuzzles"
import {default as classical_artists_puzzles} from "@/store/inventory/classical_artistsPuzzles"
import {default as modern_artists_puzzles} from "@/store/inventory/modern_artistsPuzzles"
//Those puzzle imports that are commented out had empy category arrays as inventory
//import {default as contemporary_artists_puzzles} from "@/store/inventory/contemporary_artistsPuzzles"
import {default as asian_artists_puzzles} from "@/store/inventory/asian_artistsPuzzles"
import {default as stolen_art_puzzles} from "@/store/inventory/stolen_artPuzzles"
//import {default as best_sellers_puzzles} from "@/store/inventory/best_sellersPuzzles"
import {default as hidden_gems_puzzles} from "@/store/inventory/hidden_gemsPuzzles"
import {default as holidays_puzzles} from "@/store/inventory/holidaysPuzzles"
//import {default as valentine_s_day_puzzles} from "@/store/inventory/valentine_s_dayPuzzles"
import {default as little_liberty_puzzles} from "@/store/inventory/little_libertyPuzzles"
import {default as large_piece_puzzles_puzzles} from "@/store/inventory/large_piece_puzzlesPuzzles"

import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators';
import axios from 'axios';
import * as actions from './modules/ActionNames';
import * as mutations from './modules/MutationNames';
import Image from '@/models/image';
import FrameSample from '@/models/frameSample';
import * as _ from 'lodash';

export interface CartItem {
    key: number
    puzzle: string;
    topMatColor: string;
    topMatWidth: string;
    wood: string;
    stain: string;
    price: number;
}

export const DEFAULT_TOP_MAT_WIDTH = 1

export enum BuildSteps {
  SELECT_PUZZLE = 0,
  SELECT_MAT_TYPE = 1,
  SELECT_TOP_MAT_COLOR = 2,
  SELECT_TOP_MAT_WIDTH = 3,
  SELECT_BOTTOM_MAT_COLOR = 4,
  SELECT_BOTTOM_MAT_REVEAL = 5,
  SELECT_WOOD_TYPE = 6,
  SELECT_STAIN_COLOR = 7,
  BUILD_IS_COMPLETE = 8,
  IS_ADDED_TO_CART = 9
}

// This enum is used to flag which selection window is being displayed
// and the values should equal it's corresponding build step.
export enum SelectionWindows {
    NONE = -1,
    SELECT_PUZZLE = 0,
    SELECT_TOP_MAT_COLOR = 2,
    SELECT_BOTTOM_MAT_COLOR = 4,
    SELECT_STAIN_COLOR = 7
}

export enum MatTypes {
  UNSELECTED = 0,
  SINGLE = 1,
  DOUBLE = 2
}

export enum MatLevel {
  TOP = 0,
  BOTTOM = 1
}

export function getInchesAsString(inches: number) {
    let decimal = inches.toString().split(".")[1];
    if (!decimal) {
        return inches.toString() + '"'
    }
    let fraction = "";
    let measurement = ""
    switch (decimal) {
        case "125":
            fraction = '1/8"';
            break;
        case "25":
            fraction = '1/4"';
            break;
        case "3725":
            fraction = '3/8"';
            break;
        case "5":
            fraction = '1/2"';
            break;
        case "625":
            fraction = '5/8"';
            break;
        case "75":
            fraction = '3/4"';
            break;
        case "875":
            fraction = '7/8"';
            break;
        default:
            console.error('could not parse ' + decimal + " to a fraction.")
            fraction = 'unknown fraction"';
    }
    if (inches > .9) {
        measurement = inches.toString().split(".")[0] + " & ";
    }
    measurement = measurement + fraction;
    return measurement;
}


const GOLDEN_RATIO = 1.618
const AWS_API = "https://pxe0iolw9h.execute-api.us-east-2.amazonaws.com/default/gbs-flask-app"
export const DEFAULT_TOP_MAT = "DefaultBottom.png";
//const DEFAULT_TOP_MAT = "AlaskaBlue.png";
export const DEFAULT_BOTTOM_MAT = "AlaskaBlue.png";

const DEFAULT_PUZZLE =new Image({
  imgName: "flatirons-bw.jpeg",
  name: "Flatirons BW",
  catagory: "aaa_website",
  height: 11.75,
  width: 17
});

@Module({ name: 'gbsStore' })
export default class GbsStore extends VuexModule {

    //Pages
    //Sample Builds
    private _samplesInventory = [
        new FrameSample({
            imgName: "flatirons_frame.jpeg",
            name: "Flatirons",
            topMatColor: "Pale Yellow",
            wood: "Oak",
            stain: "Provincial"
        }),
     new FrameSample({
        imgName: "crested_butte_frame.jpeg",
        name: "Crested Butte",
        topMatColor: "Pale Yellow",
        wood: "Pine",
        stain: "Provincial"
    }),
    new FrameSample({
        imgName: "terrace_frame.png",
        name: "On The Terrace",
        topMatColor: "Pale Yellow",
        wood: "Oak",
        stain: "Carbon-Black"
    }),
    new FrameSample({
        imgName: "van_gogh_frame.png",
        name: "Vase with Oleanders and Books",
        topMatColor: "Dawn Pink",
        wood: "Pine",
        stain: "Pecan Poly-Shade"
    })
    ]
    get samplesInventory() {
        return this._samplesInventory;
    }

    //Builder 
    
    private _pixelRatio = 20;
    private _isInitialPuzzleSetup = true;
    private  _frameColor = "rgb(141, 115, 44)";
    private _buildStep = 0;
    private _buildSteps = BuildSteps
    private _selectionWindows = SelectionWindows;
    private _selectionWindow = SelectionWindows.SELECT_PUZZLE;
    private _showNextButton = true;
    private _disableNextButton = true;

    get buildSteps(): any {
      return this._buildSteps;
    }

    get buildStep(): number {
      return this._buildStep;
    }

    get showNextButton() {
        return this._showNextButton;
    }

    get disableNextButton() {
        return this._disableNextButton;
    }

    get selectionWindows() {
        return this._selectionWindows;
    }

    get selectionWindow() {
        return this._selectionWindow;
    }

    get pixelRatio(): number {
        return this._pixelRatio;
    }
    get frameColor(): string {
        return this._frameColor;
    }


    //TODO: remove this
    get isInitialPuzzleSetup() {
        ////console.log("get isInitialPuzzleSetup returning: " + this._isInitialPuzzleSetup)
        return this._isInitialPuzzleSetup
    }

    @Action
    async [actions.GET_INVENTORY]() {
        this.context.dispatch(actions.GENERATE_MAT_INVENTORY);
        this.context.dispatch(actions.GENERATE_FRAME_INVENTORY);
        this.context.dispatch(actions.GENERATE_PUZZLE_INVENTORY);
    }

    @Action
    async [actions.CONFIGURE_PUZZLE_BUILDER](puzzleName: string) {
        this.context.commit(mutations.SET_SELECTED_PUZZLE, puzzleName);
        //this.context.commit(actions.CALCULATE_MAT_SIZES);
    }

    
    @Action
    [actions.INCREMENT_BUILD_STEP]() {

        this.context.commit(mutations.INCREMENT_BUILD_STEP)
        this.context.commit(mutations.SET_SHOW_NEXT_BUTTON, true);
        this.context.commit(mutations.DISABLE_NEXT_BUTTON, true);
        switch(this._buildStep) {
            case  this._buildSteps.SELECT_PUZZLE:
                this.context.commit(mutations.SET_SELECTION_WINDOW, this._buildSteps.SELECT_PUZZLE);
                break;
            case this._buildSteps.SELECT_MAT_TYPE:
                //We want the next button displayed with no selection window
                this.context.commit(mutations.SET_SELECTION_WINDOW, this._selectionWindows.NONE);
                break;
            case this._buildSteps.SELECT_TOP_MAT_COLOR:
                this.context.commit(mutations.SET_SELECTION_WINDOW, this._buildSteps.SELECT_TOP_MAT_COLOR);
                break;
            case this._buildSteps.SELECT_BOTTOM_MAT_COLOR:
                this.context.commit(mutations.SET_SELECTION_WINDOW, this._buildSteps.SELECT_BOTTOM_MAT_COLOR);
                break;
            case this._buildSteps.SELECT_STAIN_COLOR:
                this.context.commit(mutations.SET_SELECTION_WINDOW, this._buildSteps.SELECT_STAIN_COLOR);
                break;
            default:
                this.context.commit(mutations.SET_SELECTION_WINDOW, this._selectionWindows.NONE);
                this.context.commit(mutations.SET_SHOW_NEXT_BUTTON, false);
        }
    }

    //TODO: remove this
    @Mutation
    [mutations.SET_IS_INITIAL_PUZZLE_SETUP](b: boolean) {
       ////console.log("SET_IS_INITIAL_PUZZLE_SETUP receive: " + b)
        this._isInitialPuzzleSetup = b;
    }

    @Mutation
    [mutations.SET_SELECTION_WINDOW](n: number) {
        this._selectionWindow = n;
    }
    

    @Mutation
    [mutations.INCREMENT_BUILD_STEP]() {
        this._buildStep = this._buildStep + 1;
    }

    @Mutation
    [mutations.SET_SHOW_NEXT_BUTTON](b: boolean) {
        this._showNextButton = b;
    }

    @Mutation
    [mutations.DISABLE_NEXT_BUTTON](b: boolean) {
        this._disableNextButton = b;
    }

    

    @Mutation
    [mutations.SET_BUILD_STEP](step: number) {
       ////console.log("SET_IS_INITIAL_PUZZLE_SETUP receive: " + b)
        let stepName = "unknown"
        switch(step) {
            case 0:
                stepName = "SELECT_PUZZLE";
                break;
            case 1:
                stepName = "SELECT_MAT_TYPE";
                break;
            case 2:
                stepName = "SELECT_TOP_MAT_COLOR";
                break;
            case 3:
                stepName = "SELECT_TOP_MAT_WIDTH";
                break;
            case 4:
                stepName = "SELECT_BOTTOM_MAT_COLOR";
                break;
            case 5:
                stepName = "SELECT_BOTTOM_MAT_REVEAL";
                break;
            case 6:
                stepName = "SELECT_WOOD_TYPE";
                break;
            case 7:
                stepName = "SELECT_STAIN_COLOR";
                break;
            case 8:
                stepName = "BUILD_IS_COMPLETE";
                break;
            case 9:
                stepName = "IS_ADDED_TO_CART";
                break;

        }
        //console.log("Build step is now " + stepName)
        this._buildStep = step;
    }

    

    //This has to be declaired early
    private _orderItemRows = new Array<any>();

    //===== Puzzle Store
    private _puzzleInventory = [];
    private _puzzleSearchText = "";

    private _puzzleCategories = [ winter_puzzles,
       spring_puzzles,
         summer_puzzles,
         fall_puzzles,
         americana_puzzles,
         animals_puzzles,
         birds_puzzles,
         botanicals_puzzles,
         maps_puzzles,
         travel_posters_puzzles,
         vintage_prints_puzzles,
         art_nouveau_puzzles,
         classical_artists_puzzles,
         modern_artists_puzzles,
         asian_artists_puzzles,
         stolen_art_puzzles,
         hidden_gems_puzzles,
         holidays_puzzles,
         little_liberty_puzzles,
         large_piece_puzzles_puzzles
       ]

    private _isPuzzleLoaded = false;
   
    private _selectedPuzzle = DEFAULT_PUZZLE;

    get isPuzzleLoaded() {
        return this._isPuzzleLoaded;
    }

    get puzzleSearchText() {
        return this._puzzleSearchText;
    }

    get puzzleInventory() {
        return this._puzzleInventory;
    }

    get selectedPuzzle() {
        return this._selectedPuzzle;
    }

    get puzzleHeightPixels() {
        return this._selectedPuzzle.height * this._pixelRatio
    }

    get puzzleWidthPixels() {
        return this._selectedPuzzle.width * this._pixelRatio
    }

    @Action
    async [actions.START_NEW_PUZZLE]() {
        this.context.commit(mutations.RESET_PUZZLE);
        this.context.commit(mutations.SET_IS_PUZZLE_LOADED, false)
        this.context.commit(mutations.SET_BUILD_STEP, this.context.getters.buildSteps.SELECT_PUZZLE)
        this.context.commit(mutations.SET_SELECTION_WINDOW, this.context.getters.selectionWindows.SELECT_PUZZLE);
        this.context.commit(mutations.SET_SHOW_NEXT_BUTTON, true);
    }
    
    @Action
    async [actions.GENERATE_PUZZLE_INVENTORY]() {
        let inventory = []
        this._puzzleCategories.forEach(cat => {
                cat.forEach(o => {
                    let p = new Image(o)
                    inventory.push(p)
            })  
        })
        this.context.commit(mutations.SET_PUZZLE_INVENTORY, inventory)
    }

    @Mutation
    [mutations.SET_PUZZLE_SEARCH_TEXT](s: string) {
        this._puzzleSearchText = s;
    }

    @Mutation
    [mutations.SET_SELECTED_PUZZLE](name: string) {
        let puzzle = this._puzzleInventory.find(p => p.name == name)
        this._selectedPuzzle = puzzle;
    }

    @Mutation
    [mutations.SET_IS_PUZZLE_LOADED](b: boolean) {
        this._isPuzzleLoaded = b;
    }

    @Mutation
    [mutations.SET_PUZZLE_INVENTORY](inventory: any) {
        this._puzzleInventory = inventory;

    }



    //===== Frame Store
    private _selectedWood = "";
    private  _frameBoardWidth = 1.5;
    private _isFrameConfigured = false;
    //TODO: Is this necessary?
    private _woodInventory = [
        "Pine",
        "Oak",
        "Maple",
        "Poplar"
    ] 

    private _woodOptions = [ 
        {key: "oak", value: "Oak"},
        //{key: "maple", value: "Maple"}
    ];

    get woodOptions() {
        return this._woodOptions;
    }

    get frameHeightPixels() {
        //return (this._selectedPuzzle.height + 2 * this._topMatWidth) * this.pixelRatio + (this.frameBoardWidth * this._pixelRatio) + 'px'
        ////console.log("get frameHeightPixels topMatPixels             : " + this.topMatPixels)
        ////console.log("get frameHeightPixels bottomMatPixels          : " + this.bottomMatPixels)
        ////console.log("get frameHeightPixels _selectedPuzzle.height px: " + this.puzzleHeightPixels)
        //const height = ( this.topMatPixels + this.bottomMatPixels) * 2 + this.puzzleHeightPixels
        ////console.log("get frameHeightPixels total                 : " + height)
        return ( this.topMatPixels + this.bottomMatPixels) * 2 + this.puzzleHeightPixels + 'px'
    }

    get frameWidthPixels() {
       //return (this._selectedPuzzle.width + 2 * this.topMatWidth) * this.pixelRatio + (this.frameBoardWidth * this._pixelRatio) + 'px'
       return ( this.topMatPixels + this.bottomMatPixels) * 2 + this.puzzleWidthPixels + 'px'
    }
        
    get frameBoarderStyle() {
        return (this.frameBoardWidth * this._pixelRatio) + 'px solid ' + this._frameColor;
    }

    get isFrameConfigured() {
        return this._isFrameConfigured
    }

    get frameBoardWidth(): number {
        return this._frameBoardWidth;
    }

    get woodInventory() {
        return this._woodInventory;
    }

    get selectedWood() {
        return this._selectedWood;
    }

    @Action
    async [actions.GENERATE_FRAME_INVENTORY]() {
        
        let oakInv = new Array<Image>();
        let pineInv = new Array<Image>();
        let poplarInv = new Array<Image>();
        let mapleInv = new Array<Image>();
        this._stainInventory.forEach(stain => {
            let file = "_" + stain + ".jpeg";
            let name = stain.replace(/"-"/g, " ")
            oakInv.push(new Image({imgName: "Oak" + file, name: name}));
            pineInv.push(new Image({imgName: "Pine" + file, name: name}));
            poplarInv.push(new Image({imgName: "Poplar" + file, name: name}));
            //TODO: Resolve issue with GET Maple_Fruitwood.jpeg not being found 
            if (!stain.includes("Fruitwood") ) {
                mapleInv.push(new Image({imgName: "Maple" + file, name: name}));
            }

        });
        this.context.commit(mutations.SET_OAK_STAINS,oakInv);
        //this.context.commit(mutations.SET_PINE_STAINS,pineInv);
        //this.context.commit(mutations.SET_POPLAR_STAINS,poplarInv);
        this.context.commit(mutations.SET_MAPLE_STAINS,mapleInv);
    
    }

    @Action
    async [actions.SET_SELECTED_WOOD](name: string) {
        this.context.commit(mutations.SET_SELECTED_WOOD, name)
        if (this._buildStep == this._buildSteps.SELECT_WOOD_TYPE) {
            this.context.dispatch(actions.INCREMENT_BUILD_STEP)
        }
        else {
            this.context.commit(mutations.SET_SELECTION_WINDOW, this._buildSteps.SELECT_STAIN_COLOR);
            this.context.commit(mutations.SET_BUILD_STEP, this._buildSteps.SELECT_STAIN_COLOR)
            this.context.commit(mutations.SET_SHOW_NEXT_BUTTON, true);
        }
        let itemId;
        switch(this._matType) {
            case MatTypes.SINGLE:
                itemId = 4;
                break;
            case MatTypes.DOUBLE:
                itemId = 6;
        }
        let item = {'id': itemId,
                    "itemAttribute": "Wood:",
                    'selectedValue': name
                    };
        this.context.commit(mutations.SET_ORDER_ITEM, item)
        this.context.dispatch(actions.SET_PRICE)
        
    }

    @Mutation
    [mutations.SET_SELECTED_WOOD](name: string) {
        this._selectedWood = name;
        switch(name) {
            case "oak":
                this._displayedStains = this._oakStainInventory;
            case "maple":
                this._displayedStains = this._mapleStainInventory
            default:
                this._displayedStains = undefined;


        }
        //Since we have to select a new stain regardless, set build step to select stain
        this._selectedStain = "";
    }

    @Mutation
    [mutations.SET_IS_FRAME_CONFIGURED](b: boolean) {
        this._isFrameConfigured = b;
    }

    @Mutation
    [mutations.SET_FRAME_COLOR](color: string) {
        this._frameColor = color;
    }

    @Mutation
    [mutations.SET_FRAME_BOARD_WIDTH](width: number) {
        this._frameBoardWidth = width;
    }

    //TODO: what does this do?
    @Mutation
    [mutations.SET_FRAME_INVENTORY](name: string) {
        
    }

    //===== Stain Store
    private _stainInventory= [//"Cherry",
                            //"American-Walnut",
                            "Carbon-Black",
                            //"Cedar",
                            //"Crimson",
                            //"Dark-Mahogany",
                            //"Dark-Walnut",
                            //"Fruitwood",
                            //"Espresso"
                        ]

    private _pineStainInventory!: Array<Image>;
    private _oakStainInventory!: Array<Image>;
    private _mapleStainInventory!: Array<Image>;
    private _poplarStainInventory!: Array<Image>;
    //private _selectedStainImageName = "default_stain.png";
    private _selectedStainImageName = "Oak_Carbon-Black.jpeg";
    
    private _selectedStain = "Carbon-Black";
    private _displayedStains = this._oakStainInventory;

    get selectedStainImageName() {
        ////console.log("selectedStainImageName getter returning: " + this._selectedStain)
        return this._selectedStainImageName
    }

    get selectedStain() {
        ////console.log("selectedStain getter returning: " + this._selectedStain)
        return this._selectedStain
    }
    
    get displayedStains() {
        ////console.log("displayedStains getter returning: " + this._displayedStains)
        return this._displayedStains;
    }
    
    get oakStainInventory() {
        return this._oakStainInventory;
    }

    get pineStainInventory() {
        return this._pineStainInventory;
    }

    get mapleStainInventory() {
        return this._mapleStainInventory;
    }

    get poplarStainInventory() {
        return this._poplarStainInventory;
    }

    @Action
    async [actions.SET_SELECTED_STAIN](stain: Image) {
        //console.log("actions.SET_SELECTED_STAIN:", stain)
        this.context.commit(mutations.SET_SELECTED_STAIN, stain.name)
        this.context.commit(mutations.SET_SELECTED_STAIN_IMAGE_NAME, stain.imgName)
        //Default id is for MatTypes.SINGLE
        let itemId;
        switch(this._matType) {
            case MatTypes.SINGLE:
                itemId = 5;
                break;
            case MatTypes.DOUBLE:
                itemId = 7;
        }
        let item = {'id': itemId,
        "itemAttribute": "Stain:",
        'selectedValue': stain.name
        };
        this.context.commit(mutations.SET_ORDER_ITEM, item)
        this.context.dispatch(actions.SET_PRICE)
    }


    //TODO:
    //export const SET_MAPLE_STAINS = "mutSetMapleStains"
    //export const SET_POPLAR_STAINS = "mutSetPoplarStains"

    @Mutation
    [mutations.SET_OAK_STAINS](inv: Array<Image>) {
        ////console.log("SET_OAK_STAINS: ", inv)
        this._oakStainInventory = inv;
    }

    @Mutation
    [mutations.SET_PINE_STAINS](inv: Array<Image>) {
        ////console.log("SET_OAK_STAINS: ", inv)
        this._pineStainInventory = inv;
    }

    @Mutation
    [mutations.SET_MAPLE_STAINS](inv: Array<Image>) {
        ////console.log("SET_OAK_STAINS: ", inv)
        this._mapleStainInventory = inv;
    }

    @Mutation
    [mutations.SET_SELECTED_STAIN](name: string) {
        ////console.log("SET_SELECTED_STAIN: ", name)
        this._selectedStain = name;
        //Don't increment build step becuase we are waiting for the done buttong to be clicked
    }

    
    @Mutation
    [mutations.SET_SELECTED_STAIN_IMAGE_NAME](name: string) {
        ////console.log("SET_SELECTED_STAIN_IMAGE_NAME: ", name)
        this._selectedStainImageName = name;
    }

    

    //===== Mat Store
    
    
    //require(`../mat/images/${this.DEFAULT_BOTTOM_MAT}`);
    private _selectedTopMat =  new Image({imgName: DEFAULT_TOP_MAT, name: ""});
    private _selectedBottomMat =  new Image({imgName: DEFAULT_BOTTOM_MAT, name: ""});
    private _topMatImagePath = "img/" + this._selectedTopMat.imgName
    private _bottomMatImagePath =  "img/" + this._selectedBottomMat.imgName
    //Keep _bottomMatReveal & _bottomMatPixels at a value so that when double mat is selected it shows up right away
    private _bottomMatReveal = .625  //Reveal in inches
    private _bottomMatPixels = this._bottomMatReveal * this._pixelRatio + 6;
    private _matInventory = new Array<Image>();
    private _matColors = [
        // "Alaska Blue",
        // "Anise",
        // "Azure",
        // "Buff",
        // "Charcoal Gray",
        // "Chestnut Brown",
        // "Cloud White",
        // "Cream",
        // "Dawn Pink",
        // "Eggshell",
        // "Felt Grey",
        // "Apple Green",
        // "Bordeaux",
        // "Champagne",
        // "Ivory",
        // "Ivy",
        // "Light Blue",
        // "Light Green",
        // "Lilac",
        // "Lily",
        // "Default Bottom"
        "Envy",
        "Deep Woods",
        "Coal Mine",
        "Frond",
        "Irish Green",
        "Lawn",
        "Sierra Olive",
        "Batter",
        "Blizzard",
        "CreamPuff",
        "Dove",
        "Egg White",
        "Half And Half",
        "Honey Wheat",
        "Off White",
        "Pure White",
    ];
    private _topMatColor = "";
    private _topMatWidth = DEFAULT_TOP_MAT_WIDTH;
    private _bottomMatColor = "";
    private _matBevelWidth = 0;
    private _isColorSelected = false;
    private _isWidthSelected = false;
    private _isMatConfigured = false;
    private _isMatWithCore = true;
    private _matTypes = MatTypes;
    private _matType = MatTypes.UNSELECTED;
    private _showBottomMatSelector = false;
    private _showBottomRevealToolTip = false;
    private _revealOptions = [
        //{value: "3/8\"", key: 0.3725},
        {value: "1/2\"", key: 0.624},
        //{value: "5/8\"", key: 0.625}
      ]

    get revealOptions() {
        return this._revealOptions;
    }


    get topMatPixels() {
        return this._topMatWidth * this._pixelRatio
    }

    get topMatImagePath() {
        return this._topMatImagePath
    }

    get bottomMatPixels() {
        return this._bottomMatPixels
    }

    get bottomMatImagePath() {
        return this._bottomMatImagePath
    }

    get bottomMatReveal() {
        return this._bottomMatReveal;
    }

    
    get matType() {
        return this._matType;
    }

    get matTypes() {
      return this._matTypes;
    }

    get isMatWithCore() {
        return this._isMatWithCore;
    }

    //TODO: is getter depricated?
    get selectedTopMat() {
        return this._selectedTopMat;
    }

    get matInventory() {
        return this._matInventory
    }
    
    get topMatColor(): string {
        return this._topMatColor;
    }

    get topMatWidth(): number {
        return this._topMatWidth;
    }

    get bottomMatColor(): string {
      return this._bottomMatColor;
  }

    get isMatConfigured() {
        ////console.log("isMatConfigured getter returning: " + this._isMatConfigured)
        return this._isMatConfigured;
    }

    get matBevelWidth(): number {
        return this._matBevelWidth;
    }

    get showBottomMatSelector() {
        return this._showBottomMatSelector;
    }

    get showBottomRevealToolTip() {
        return this._showBottomRevealToolTip;
    }

    @Action
    async [actions.GENERATE_MAT_INVENTORY](name: string) {
        this._matColors.forEach(mc => {

            let file = mc.replace(/\s/g, "") + ".png";
            //console.log("Adding mat: " + file)
            let image = new Image({imgName: file, name: mc});
            this._matInventory.push(image)
        });

    }

    @Action
    async [actions.SET_TOP_MAT_COLOR](color: string) {
        this.context.commit(mutations.SET_TOP_MAT_COLOR, color)
        let itemAttr;
        switch(this._matType) {
            case MatTypes.SINGLE:
                itemAttr = "Mat Color:";
                break;
            case MatTypes.DOUBLE:
                itemAttr = "Primary Mat Color:";
        }
        let item = {
            'id': 3,
            "itemAttribute": itemAttr,
            'selectedValue': color
        };
        this.context.commit(mutations.SET_ORDER_ITEM, item)
        
    }

    @Action
    async [actions.SET_TOP_MAT_WIDTH](width: number) {
        this.context.commit(mutations.SET_TOP_MAT_WIDTH, width)
        let itemAttr;
        switch(this._matType) {
            case MatTypes.SINGLE:
                itemAttr = "Mat Size:";
                break;
            case MatTypes.DOUBLE:
                itemAttr = "Primary Mat Size:";
        }
        let item = {
            'id': 2,
            "itemAttribute": itemAttr,
            'selectedValue': width + '"'
        };
        if (this._buildStep == BuildSteps.SELECT_TOP_MAT_WIDTH && this._matType == MatTypes.DOUBLE) {
            this.context.dispatch(actions.INCREMENT_BUILD_STEP);
        }
        else if (this._buildStep == BuildSteps.SELECT_TOP_MAT_WIDTH && this._matType == MatTypes.SINGLE) {
            this.context.commit(mutations.SET_BUILD_STEP, BuildSteps.SELECT_WOOD_TYPE)
        }
        this.context.commit(mutations.SET_ORDER_ITEM, item)
        this.context.dispatch(actions.SET_PRICE);
    }

    @Action
    async [actions.SET_BOTTOM_MAT_COLOR](color: string) {
        //console.log("actions.SET_BOTTOM_MAT_COLOR: " + color)
        this.context.commit(mutations.SET_BOTTOM_MAT_COLOR, color)
        let item = {
            'id': 4,
            "itemAttribute": "Accent Mat Color:",
            'selectedValue': color
        };
        this.context.commit(mutations.SET_ORDER_ITEM, item)
    }

    @Action
    async [actions.SET_BOTTOM_MAT_REVEAL](reveal: number) {
        //console.log("actions.SET_BOTTOM_MAT_REVEAL: " + reveal)
        this.context.commit(mutations.SET_BOTTOM_MAT_REVEAL, reveal)
        let item = {
            'id': 5,
            "itemAttribute": "Accent Mat Reveal:",
            'selectedValue': reveal + '"'
        };
        this.context.commit(mutations.SET_ORDER_ITEM, item)
        if (this._buildStep == BuildSteps.SELECT_BOTTOM_MAT_REVEAL) {
            this.context.dispatch(actions.INCREMENT_BUILD_STEP);
        }
    }

    @Mutation
    [mutations.SET_BOTTOM_MAT_PIXELS]() {
        let fudgeFactor= 6;
        if (!this._bottomMatReveal) {
            this._bottomMatPixels = fudgeFactor;
        }
        else {
            this._bottomMatPixels = this._bottomMatReveal * this._pixelRatio + fudgeFactor;
        }
    }
    
    @Mutation
    [mutations.SET_BOTTOM_MAT_REVEAL](reveal: number) {
        this._bottomMatReveal = reveal;
        this._showBottomRevealToolTip = false;
        
        //TODO: adding 6 pixels was just an estimation, verify this it's correct
        this._bottomMatPixels = this._bottomMatReveal * this._pixelRatio + 6 
    }

    @Mutation
    [mutations.SET_IS_MAT_WIDTH_SELECTED](b: boolean) {
        this._isWidthSelected = b;
    }

    //TODO: : This is being depricated?
    @Mutation
    [mutations.SET_MAT_INVENTORY](array: Array<Image>) {
        this._matInventory = array;
    }

    //TODO: This is being depricated?
    @Mutation
    [mutations.SET_IS_MAT_CONFIGURED](b: boolean) {
        this._isMatConfigured = b;
    }

    @Mutation
    [mutations.SET_TOP_MAT_COLOR](name: string) {
        let mat = this._matInventory.find(m => m.name == name)
        ////console.log("SET_SELECTED_MAT found: ", mat)
        this._selectedTopMat = mat;
        this._topMatColor = name;
        this._topMatImagePath = "img/" + this._selectedTopMat.imgName;
        this._isColorSelected = true;
    }

    @Mutation
    [mutations.SET_BOTTOM_MAT_COLOR](name: string) {
        ////console.log("SET_BOTTOM_MAT_COLOR looking for: ", name)
        let mat = this._matInventory.find(m => m.name == name)
        ////console.log("SET_BOTTOM_MAT_COLOR found: ", mat)
        
        this._bottomMatColor = name;
        this._bottomMatImagePath = "img/" + mat.imgName
        //This ensures bottom mat color selector closes when user changed Mat Type from single to double during or after wood selection
        this._showBottomMatSelector = false;
    }



    @Mutation
    [mutations.SET_TOP_MAT_WIDTH](width: number) {
        this._topMatWidth = width;
        this._isWidthSelected = true;
    }

    @Action
    [actions.SET_MAT_TYPE](type: number) {
        this.context.commit(mutations.SET_MAT_TYPE, type)
        this.context.commit(mutations.DISABLE_NEXT_BUTTON, false)
    }

    @Mutation
    [mutations.SET_MAT_TYPE](type: number) {
        this._matType = type;
        if (this._buildStep >= BuildSteps.SELECT_WOOD_TYPE && this._bottomMatColor == "" && this._matType == MatTypes.DOUBLE) {
            this._showBottomMatSelector = true;
            this._showBottomRevealToolTip = true;
        } 
    }
    
//===== Cart Store
    private _emailRequestType = ""
    private _price = 0
    private _cart = new Array<CartItem>()
    private _cartItemColumns = [
        {
            field: "itemAttribute",
            label: "",
            width: 150
        },
        {
            field: "selectedValue",
            label: "",
            width: 200
        }
    ]

    get orderItemColumns() {
        return this._cartItemColumns;
    }

    get orderItemRows() {
        return this._orderItemRows;
    }

    get cart() {
        return this._cart
    }

    get price() {
        return this._price
    }

    get emailRequestType() {
      return this._emailRequestType
    }

    @Action
    async [actions.SEND_EMAIL](payload: {requestType: string, emailAddress: string, subject: string, comments: string}) {
      ////console.log("starting action EMAIL_ORDER: ", payload)

      try {
        let items = []
        if (payload.requestType == 'purchase') {
          items = this._cart
          items.forEach(item => delete item.key)
          this.context.commit(mutations.EMPTY_CART)
        }
        
        let request =  {
                      "requestType": payload.requestType,
                      "items": items,
                      "from": payload.emailAddress,
                      "subject": payload.subject,
                      "comments": payload.comments
                    }
        
        //console.log("sendorder contents: ", JSON.stringify(request))
        await axios.post(AWS_API, request).catch(error => console.log("Disregard theses CORS & POST errors.  CORS has been enabled in the corresponding Amazon API gateway, and the email will be sent."));
        //await axios.post(`http://localhost:5000/sendorder`, request)
        
      }
      catch (e) {
        console.error("SEND_EMAIL Caught exception: ", e)
      }
    }

    @Action
    [actions.REMOVE_ORDER_FROM_CART](key: number) {
        let deleteIndex = -1;
        for(var i = 0; i < this._cart.length; i += 1) {
            if(this._cart[i].key == key) {
                deleteIndex =  i;
            }
        }
        if (deleteIndex >= 0) {
            this.context.commit(mutations.REMOVE_ORDER_FROM_CART, deleteIndex)
        }

    }

    @Action
    [actions.ADD_TEST_ORDER_TO_CART]() {
      //console.log("ADD_TEST_ORDER_TO_CART() ")
      try {
        let item = { key: Date.now(),
                    puzzle:"Test puzzle",
                    topMatColor: "tan", 
                    topMatWidth: 2,
                    wood: "pine",
                    stain: "walnut",
                    price: "1.99"}
        let item2 = { key: Date.now(),
                      puzzle:"Another puzzle",
                      topMatColor: "red", 
                      topMatWidth: 3,
                      wood: "oak",
                      stain: "espresso",
                      price: "1.99"}
        this.context.commit(mutations.PUSH_TO_CART, item); 
        this.context.commit(mutations.PUSH_TO_CART, item2);
      }
      catch(e) {
          //console.log("ADD_ORDER_TO_CART error: ", e)
      }
  }

    @Action
    [actions.ADD_ORDER_TO_CART]() {
        //console.log("ADD_ORDER_TO_CART() ")
        try {
            let item = { key: Date.now(),
                        puzzle: this.selectedPuzzle.name,
                        topMatColor: this.topMatColor, 
                        topMatWidth: this.topMatWidth,
                        bottomMatColor: this.bottomMatColor, 
                        bottomMatReveal: this.bottomMatReveal,
                        wood: this.selectedWood,
                        stain: this.selectedStain,
                        price: this.price
                      }
            if (this._matType == MatTypes.SINGLE) {
                //The Purchase and Checkout pages depends on this as being the Mat Type indicator
                item.bottomMatReveal = 0
            }
            this.context.commit(mutations.PUSH_TO_CART, item);
        }
        catch(e) {
            //console.log("ADD_ORDER_TO_CART error: ", e)
        }
    }

    @Action
    [actions.SET_PRICE]() {
        ////console.log("starting SET_PRICE for now")
        if (this._topMatWidth && this._selectedWood && this._selectedStain) {
            let itemId;
            switch(this._matType) {
                case MatTypes.SINGLE:
                    itemId = 6;
                    break;
                case MatTypes.DOUBLE:
                    itemId = 8;
            }
            let price = {'id': itemId,
                        "itemAttribute": "Price:",
                        'selectedValue': "$124.99"
            }
            this.context.commit(mutations.SET_ORDER_ITEM, price)
        }
        else {
            ////console.log("skip SET_PRICE for now")
        }
    }

    @Mutation
    [mutations.REMOVE_ORDER_FROM_CART](idx: number) {
        this._cart.splice(idx, 1)
    }

    @Mutation
    [mutations.RESET_PUZZLE]() {
        this._selectedPuzzle = DEFAULT_PUZZLE;
        this._selectedTopMat =  new Image({imgName: DEFAULT_TOP_MAT, name: ""});;
        this._selectedBottomMat =  new Image({imgName: DEFAULT_BOTTOM_MAT, name: ""});;
        this._topMatColor = "";
        this._topMatImagePath = "img/" + this._selectedTopMat.imgName
        this._bottomMatImagePath = "img/" + this._selectedBottomMat.imgName
        this._bottomMatReveal = .5
        this._topMatWidth = DEFAULT_TOP_MAT_WIDTH;
        this._bottomMatColor = "";
        this._matType = this._matTypes.UNSELECTED
        this._topMatWidth = 1;
        this._isColorSelected = false;
        this._isWidthSelected = false;
        this._selectedStain = "Carbon-Black";
        this._selectedWood = "Oak";
        this._orderItemRows = new Array<any>();
        this._isFrameConfigured = false;
        this._isMatConfigured = false;
        this._isInitialPuzzleSetup = true;
        //this._selectedStainImageName = "default_stain.png";
        this._selectedStainImageName = "Oak_Carbon-Black.jpeg";
        this._buildStep = 0;
    }
    

    @Mutation
    [mutations.PUSH_TO_CART](item: CartItem) {
        ////console.log("pushing to cart: ", item)
        this._cart.push(item)

        //Reset puzzle builder state
        this._selectedPuzzle = DEFAULT_PUZZLE;
        this._selectedTopMat =  new Image({imgName: DEFAULT_TOP_MAT, name: ""});;
        this._selectedBottomMat =  new Image({imgName: DEFAULT_BOTTOM_MAT, name: ""});;
        this._topMatColor = "";
        this._topMatWidth = 1;
        this._isColorSelected = false;
        this._isWidthSelected = false;
        this._bottomMatReveal = .5
        this._selectedStain = "Carbon-Black";
        this._selectedWood = "Oak";
        this._orderItemRows = new Array<any>();
        this._isFrameConfigured = false;
        this._isMatConfigured = false;
        this._isInitialPuzzleSetup = true;
        ////console.log("PUSH_TO_CART _isInitialPuzzleSetup: " + this._isInitialPuzzleSetup)
        this._selectedStainImageName = "default_stain.png";
        //Reset to select new puzze
        this._selectionWindow = this._selectionWindows.SELECT_PUZZLE
        this._buildStep = 0; 
        this._showNextButton = true;
        this._disableNextButton = true;
    }

    @Mutation
    [mutations.SET_ORDER_ITEM] (item: any)  {
        //console.log("calling SET_ORDER_ITEM: ", item)
        try {
            //Check to see if this is replacing pre-existing choice
            let idx = -1;
            for (let i = 0; i < this._orderItemRows.length; i++) {
                ////console.log("SET_ORDER_ITEM inspecting: ", this._orderItemRows[i])
                if (this._orderItemRows[i].id == item.id) {
                    //console.log("found id: ", this._orderItemRows[i].id + " @ index: " +  i)
                    idx = i;
                }
                
            }
            //If this is a replacement selection then replace
            if (idx > -1) {
                //Create a new Array so it shakes the dom tree
                let newItems = new Array<any>();
                for (let i2 = 0; i2 < this._orderItemRows.length; i2++) {
                    ////console.log("newItems inspecting index " + i2)
                    if (i2 == idx) {
                        ////console.log("newItems replacing index with  ", item)
                        newItems.push(item)
                    }
                    else {
                        ////console.log("newItems replicating index with  ", this._orderItemRows[i2])
                        newItems.push(this._orderItemRows[i2])
                    }
                }
                this._orderItemRows = _.cloneDeep(newItems); 
                ////console.log("_orderItemRows is now: ",  this._orderItemRows)
            }
            else {
                //console.log("pushing new item: ", item)
                this._orderItemRows.push(item)
            }
        }
        catch(e) {
            console.error("SET_ORDER_ITEM threw: ", e)
        }
    }

    @Mutation
    [mutations.EMPTY_CART] ()  {
      this._cart = new Array<CartItem>()
    }

    @Mutation
    [mutations.SET_EMAIL_REQUEST_TYPE] (type: string)  {
      this._emailRequestType = type
      ////console.log("_emailRequestType is now: " +  this._emailRequestType )
    }

    
}

