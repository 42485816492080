const puzzle_inventory = [
	{
		imgName: "the_tug_of_war.jpg",
		thumbName: "the_tug_of_war_thumb.jpg",
		name: "The Tug of War",
		catagory: "vintage_prints",
		height: 6.0,
		width: 23.75
	},
	{
		imgName: "vogue_1912.jpg",
		thumbName: "vogue_1912_thumb.jpg",
		name: "Vogue 1912",
		catagory: "vintage_prints",
		height: 16.25,
		width: 11.75
	},
	{
		imgName: "large_Useful-Gifts-not-Gimcracks-image-900-watermark.jpeg",
		thumbName: "useful_gifts_thumb.jpg",
		name: "Useful Gifts",
		catagory: "vintage_prints",
		height: 12.25,
		width: 9.75
	},
	{
		imgName: "toys_a-plenty.jpg",
		thumbName: "toys_a-plenty_thumb.jpg",
		name: "Toys A-Plenty",
		catagory: "vintage_prints",
		height: 14.0,
		width: 13.5
	},
	{
		imgName: "birra_italia_pilsen.jpg",
		thumbName: "birra_italia_pilsen_thumb.jpg",
		name: "Birra Italia Pilsen",
		catagory: "vintage_prints",
		height: 12.75,
		width: 9.5
	},
	{
		imgName: "the_story_of_king_schahriyar_and_his_brother_schahzenan.jpg",
		thumbName: "the_story_of_king_schahriyar_and_his_brother_schahzenan_thumb.jpg",
		name: "The Story of King Schahriyar and His Brother Schahzenan",
		catagory: "vintage_prints",
		height: 14.75,
		width: 14.75
	},
	{
		imgName: "the_story_of_scheherazade.jpg",
		thumbName: "the_story_of_scheherazade_thumb.jpg",
		name: "The Story of Scheherazade",
		catagory: "vintage_prints",
		height: 14.75,
		width: 14.75
	},
	{
		imgName: "the_overseers_tale.jpg",
		thumbName: "the_overseers_tale_thumb.jpg",
		name: "The Overseer's Tale",
		catagory: "vintage_prints",
		height: 14.75,
		width: 14.75
	},
	{
		imgName: "what_santa_claus_brings.jpg",
		thumbName: "what_santa_claus_brings_thumb.jpg",
		name: "What Santa Claus Brings",
		catagory: "vintage_prints",
		height: 15.0,
		width: 11.75
	},
	{
		imgName: "santas_toboggan_ride.jpg",
		thumbName: "santas_toboggan_ride_thumb.jpg",
		name: "Santa's Toboggan Ride",
		catagory: "vintage_prints",
		height: 12.75,
		width: 9.5
	},
	{
		imgName: "cholla_and_sahuaro.jpg",
		thumbName: "cholla_and_sahuaro_thumb.jpg",
		name: "Cholla and Sahuaro",
		catagory: "vintage_prints",
		height: 9.75,
		width: 9.75
	},
	{
		imgName: "victorian_christmas_tree.jpg",
		thumbName: "victorian_christmas_tree_thumb.jpg",
		name: "Victorian Christmas Tree",
		catagory: "vintage_prints",
		height: 21.25,
		width: 14.0
	},
	{
		imgName: "rise_and_shine.jpg",
		thumbName: "rise_and_shine_thumb.jpg",
		name: "Rise and Shine",
		catagory: "vintage_prints",
		height: 7.0,
		width: 7.0
	},
	{
		imgName: "fancy_cakes.jpg",
		thumbName: "fancy_cakes_thumb.jpg",
		name: "Fancy Cakes",
		catagory: "vintage_prints",
		height: 12.75,
		width: 15.75
	},
	{
		imgName: "terza_coppa_etna.jpg",
		thumbName: "terza_coppa_etna_thumb.jpg",
		name: "Terza Coppa Etna",
		catagory: "vintage_prints",
		height: 17.5,
		width: 11.75
	},
	{
		imgName: "sing_a_song_of_christmas.jpg",
		thumbName: "sing_a_song_of_christmas_thumb.jpg",
		name: "Sing a Song of Christmas",
		catagory: "vintage_prints",
		height: 11.75,
		width: 17.5
	},
	{
		imgName: "spaldings_baseball_guide.jpg",
		thumbName: "spaldings_baseball_guide_thumb.jpg",
		name: "Spalding's Baseball Guide",
		catagory: "vintage_prints",
		height: 17.75,
		width: 11.75
	},
	{
		imgName: "louisiana_heron.jpg",
		thumbName: "louisiana_heron_thumb.jpg",
		name: "Louisiana Heron",
		catagory: "vintage_prints",
		height: 7.0,
		width: 7.0
	},
	{
		imgName: "exposition_universelle_de_1889.jpg",
		thumbName: "exposition_universelle_de_1889_thumb.jpg",
		name: "Exposition Universelle de 1889",
		catagory: "vintage_prints",
		height: 16.0,
		width: 10.25
	},
	{
		imgName: "angkor_wat.jpg",
		thumbName: "angkor_wat_thumb.jpg",
		name: "Angkor Wat",
		catagory: "vintage_prints",
		height: 8.25,
		width: 23.75
	},
	{
		imgName: "cycles_gladiator_large.jpg",
		thumbName: "cycles_gladiator_large_thumb.jpg",
		name: "Cycles Gladiator Large",
		catagory: "vintage_prints",
		height: 12.75,
		width: 18.75
	},
	{
		imgName: "cycles_perfecta.jpg",
		thumbName: "cycles_perfecta_thumb.jpg",
		name: "Cycles Perfecta",
		catagory: "vintage_prints",
		height: 16.5,
		width: 12.75
	},
	{
		imgName: "vogue_christmas_gifts_number.jpg",
		thumbName: "vogue_christmas_gifts_number_thumb.jpg",
		name: "Vogue Christmas Gifts Number",
		catagory: "vintage_prints",
		height: 17.0,
		width: 12.75
	},
	{
		imgName: "christmas_special.jpg",
		thumbName: "christmas_special_thumb.jpg",
		name: "Christmas Special",
		catagory: "vintage_prints",
		height: 8.5,
		width: 12.75
	},
	{
		imgName: "joy_peace_health_and_plenty.jpg",
		thumbName: "joy_peace_health_and_plenty_thumb.jpg",
		name: "Joy, Peace, Health & Plenty",
		catagory: "vintage_prints",
		height: 11.25,
		width: 10.75
	},
	{
		imgName: "christmas_joyride.jpg",
		thumbName: "christmas_joyride_thumb.jpg",
		name: "Christmas Joyride",
		catagory: "vintage_prints",
		height: 8.0,
		width: 12.75
	},
	{
		imgName: "woolson_spice_christmas.jpg",
		thumbName: "woolson_spice_christmas_thumb.jpg",
		name: "Woolson Spice Christmas",
		catagory: "vintage_prints",
		height: 15.0,
		width: 11.75
	},
	{
		imgName: "saint_nicholas.jpg",
		thumbName: "saint_nicholas_thumb.jpg",
		name: "Saint Nicholas",
		catagory: "vintage_prints",
		height: 21.5,
		width: 8.0
	},
	{
		imgName: "santas_sleigh.jpg",
		thumbName: "santas_sleigh_thumb.jpg",
		name: "Santa's Sleigh",
		catagory: "vintage_prints",
		height: 8.0,
		width: 12.75
	},
	{
		imgName: "the_night_before_christmas.jpg",
		thumbName: "the_night_before_christmas_thumb.jpg",
		name: "The Night Before Christmas",
		catagory: "vintage_prints",
		height: 15.75,
		width: 12.75
	},
	{
		imgName: "christmas_wishes.jpg",
		thumbName: "christmas_wishes_thumb.jpg",
		name: "Christmas Wishes",
		catagory: "vintage_prints",
		height: 8.25,
		width: 13.25
	},
	{
		imgName: "kris_kringle_series.jpg",
		thumbName: "kris_kringle_series_thumb.jpg",
		name: "Kris Kringle Series",
		catagory: "vintage_prints",
		height: 12.75,
		width: 9.0
	},
	{
		imgName: "snowy_sleigh_ride.jpg",
		thumbName: "snowy_sleigh_ride_thumb.jpg",
		name: "Snowy Sleigh Ride",
		catagory: "vintage_prints",
		height: 11.75,
		width: 9.25
	},
	{
		imgName: "vintage_christmas_postcards.jpg",
		thumbName: "vintage_christmas_postcards_thumb.jpg",
		name: "Vintage Christmas Postcards",
		catagory: "vintage_prints",
		height: 14.25,
		width: 20.25
	},
	{
		imgName: "yuletide_memories.jpg",
		thumbName: "yuletide_memories_thumb.jpg",
		name: "Yuletide Memories",
		catagory: "vintage_prints",
		height: 14.75,
		width: 20.5
	},
	{
		imgName: "christmas_wreath.jpg",
		thumbName: "christmas_wreath_thumb.jpg",
		name: "Christmas Wreath",
		catagory: "vintage_prints",
		height: 10.75,
		width: 10.75
	},
	{
		imgName: "stockings_by_the_fire.jpg",
		thumbName: "stockings_by_the_fire_thumb.jpg",
		name: "Stockings by the Fire",
		catagory: "vintage_prints",
		height: 7.0,
		width: 7.0
	},
	{
		imgName: "merry_christmas_round.jpg",
		thumbName: "merry_christmas_round_thumb.jpg",
		name: "Merry Christmas round",
		catagory: "vintage_prints",
		height: 7.0,
		width: 7.0
	},
	{
		imgName: "santas_treats.jpg",
		thumbName: "santas_treats_thumb.jpg",
		name: "Santa's Treats",
		catagory: "vintage_prints",
		height: 7.0,
		width: 7.0
	},
	{
		imgName: "new_years_toboggan.jpg",
		thumbName: "new_years_toboggan_thumb.jpg",
		name: "New Year's Toboggan",
		catagory: "vintage_prints",
		height: 11.75,
		width: 15.25
	},
	{
		imgName: "large_christmas-greetings-watermark.jpeg",
		thumbName: "christmas-greetings-thumb.jpg",
		name: "Christmas Greetings",
		catagory: "vintage_prints",
		height: 8.25,
		width: 13.25
	},
	{
		imgName: "valentine_collection_special_edition.jpg",
		thumbName: "valentine_collection_special_edition_thumb.jpg",
		name: "Valentine Collection Special Edition",
		catagory: "vintage_prints",
		height: 14.0,
		width: 17.5
	},
	{
		imgName: "valentine_swans.jpg",
		thumbName: "valentine_swans_thumb.jpg",
		name: "Valentine Swans",
		catagory: "vintage_prints",
		height: 8.0,
		width: 13.0
	},
]

export default puzzle_inventory