
import { Component, Vue, Prop } from "vue-property-decorator";


@Component({
    
})
export default class BuildFrameButton extends Vue {

    @Prop()
    disabled!: boolean;

    @Prop()
    title!: string

    handleClick() {
        this.$emit('buildNewFrameClick')
    }

}
