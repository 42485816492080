
import { Component } from "vue-property-decorator";
import { Vue } from "vue-property-decorator";
import * as act from "@/store/modules/ActionNames";
import * as mut from '@/store/modules/MutationNames';
import CustomSelect from  "@/components/controls/CustomSelect.vue"
import InfoButton from "@/components/buttons/InfoButton.vue"
import InfoPopup from "@/components/views/info/InfoPopup.vue"

@Component({
    components: {
        CustomSelect,
        InfoButton,
        InfoPopup
    }
})
export default class BottomMatRevealSelector extends Vue {

    description = "'Reveal' is the amount of bottom mat that is exposed underneath the top mat."
    showInfo = false;
    placeholder = "Select reveal...";
    selectedKey = null;
    selectedPuzzle = this.$store.getters.selectedPuzzle;
    topMatWidth = this.$store.getters.topMatWidth;
    isInitialPuzzleSetup!: boolean;

    created() {
        this.isInitialPuzzleSetup = this.$store.getters.isInitialPuzzleSetup

        if(this.$store.getters.buildStep > this.$store.getters.buildSteps.SELECT_BOTTOM_MAT_REVEAL) {
            this.selectedKey = this.$store.getters.bottomMatReveal
        }
    }

    closeInfo() {
        this.showInfo = false;
    }

    handleSizeChange(e) {
        this.selectedKey = e;
        this.$store.dispatch(act.SET_BOTTOM_MAT_REVEAL, e)
        this.$forceUpdate();
    }

}
