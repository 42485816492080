
import { Component } from "vue-property-decorator";
import { Vue, Watch, Prop } from "vue-property-decorator";
import * as mut from "@/store/modules/MutationNames";
import * as act from "@/store/modules/ActionNames";
import MatImage from "@/components/mat/MatImage.vue"
import CustomSelect from  "@/components/controls/CustomSelect.vue"
import CloseButton from "@/components/buttons/CloseButton.vue"
import { MatLevel, MatTypes } from "@/store/GbsStore"
import Image from '@/models/image';

@Component({
    components: {
        MatImage,
        CustomSelect,
        CloseButton
    }
})
export default class MatSelector extends Vue {
    //Button
    isInitialPuzzleSetup = this.$store.getters.isInitialPuzzleSetup
    doneButtonTitle = "Next";

    
    showMatWidthInfo = false
    topMatWidth = this.$store.getters.topMatWidth;

    //color
    matWidth = this.$store.getters.matWidth;
    topMatColor = this.$store.getters.topMatColor
    filteredMats = new Array<Image>();

    @Prop()
    matLevel


    created() {
        this.filteredMats = new Array<Image>();
        this.$store.getters.matInventory.forEach(mat => {
            if (!mat.imgName.includes("Default")) {
                console.log("Pushing mat: ", mat)
                this.filteredMats.push(mat)
            }
        });
    }

    get isButtonEnabled() {
        let enable = false;
        if (this.$store.getters.topMatColor && this.matLevel == MatLevel.TOP) {
            enable = true;
        }
        if (this.$store.getters.bottomMatColor && this.matLevel == MatLevel.BOTTOM) {
            enable = true;
        }
        return enable;
    }

    //color
    async handleMatColorButtonClick(name: string) {
        //console.log("Selected Mat: " + name)
        if(this.matLevel == MatLevel.TOP) {
            //console.log("Selected top mat: " + name)
            await this.$store.dispatch(act.SET_TOP_MAT_COLOR, name)
        }
        if(this.matLevel == MatLevel.BOTTOM) {
            //console.log("Selected bottom mat: " + name)
            await this.$store.dispatch(act.SET_BOTTOM_MAT_COLOR, name)
        }
        this.$store.commit(mut.DISABLE_NEXT_BUTTON, false)
        this.$forceUpdate();
    }
  

    @Watch('$store.getters.topMatColor')
    onMatColorChange(value: string, oldValue: string) {
        //console.log("heard change topMatColor")
        this.topMatColor = value;
        this.$forceUpdate();
    }


    closeMatSelector() {
        let eventName = "closeMatColorSelector"
        //TODO: this state management should be in the store, but since the window only closes
        // when the done button is clicked we need to keep the build step increment here.
        if(this.$store.getters.buildStep == this.$store.getters.buildSteps.SELECT_TOP_MAT_COLOR ) {
            this.$store.commit(mut.SET_BUILD_STEP, this.$store.getters.buildSteps.SELECT_TOP_MAT_WIDTH)
        }
        else if(this.$store.getters.buildStep == this.$store.getters.buildSteps.SELECT_BOTTOM_MAT_COLOR)                                                                                                 
        {
            this.$store.commit(mut.SET_BUILD_STEP, this.$store.getters.buildSteps.SELECT_BOTTOM_MAT_REVEAL)
        }
        //Keep event so that builder can close window when opened out of sequence
        this.$emit(eventName)
    }

}



