<template>
  <div id="app">
    <nav-bar/>
    <SubNavBar/>
    <router-view></router-view>
  </div>
</template>

<script>
import * as act from '@/store/modules/ActionNames';
import * as mut from '@/store/modules/MutationNames';
import NavBar from "@/components/NavBar";
import SubNavBar from "@/components/SubNavBar";
import About from "@/components/tabs/About.vue"
import FrameBuilder from "@/components/tabs/frameBuilder/FrameBuilder.vue"
export default {
  name: 'app',
  components: {
        NavBar,
        SubNavBar,
        About,
        FrameBuilder

    },
    created() {
        this.$store.dispatch(act.GET_INVENTORY)
        this.$router.push(this.$router.currentRoute.path).catch(error => {
            //Ignore NavigationDuplicated error, which is a known issue with vue router
            if (error.name != "NavigationDuplicated") {
                throw error;
            }
        });;
    }
}
</script>


<style>
#app {
}
</style>