
import { Component } from "vue-property-decorator";
import { Vue, Prop, Watch} from "vue-property-decorator";
import InfoButton from "@/components/buttons/InfoButton.vue"
import PencilButton from "@/components/buttons/PencilButton.vue"

@Component({
    components: {
        InfoButton,
        PencilButton
    }
})
export default class BottomMatColorLineItem extends Vue {

  created() {
      
  } 



  infoClick() {
    this.$emit("bottomMatColorInfoClick")
  }

  
  editClick() {
      this.$emit("editBottomMatColorClick")
  }

  @Watch('this.$store.getters.bottomMatColor')
    onMatTypeChange(value: number) {
      this.$forceUpdate()
    }

  


}



