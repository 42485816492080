
import { Component } from "vue-property-decorator";
import { Vue, Prop} from "vue-property-decorator";
import InfoButton from "@/components/buttons/InfoButton.vue"
import PencilButton from "@/components/buttons/PencilButton.vue"

@Component({
    components: {
        InfoButton,
        PencilButton
    }
})
export default class LineItem extends Vue {
  
  infoClick() {
    this.$emit("puzzleInfoClick")
  }

  
  editClick() {
      console.log("emmitting puzzle click")
      this.$emit("editPuzzleClick")
  }

  


}



