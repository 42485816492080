
import { Component, Prop } from "vue-property-decorator";
import { Vue } from "vue-property-decorator";
import * as mut from '@/store/modules/MutationNames';
import CloseButton from "@/components/buttons/CloseButton.vue"


@Component({
    components: {
        CloseButton
    }
})
export default class InfoPopup extends Vue {
    styleWidth!: string;

    @Prop()
    infoTitle!: string;

    @Prop()
    description!: string;

    @Prop()
    propertyType!: string;

    @Prop()
    imageName!: string;

    @Prop()
    width!: string;

    created() {
        this.styleWidth = this.width + "px;"
        this.$forceUpdate()
    }

    get imagePath() {
        require('./images/' + this.imageName)
        let path = 'img/' + this.imageName
        return path
    }

    get style() {
        let heightPx =  '550px'
        let widthPx = '800px'
        //console.log("getting style for " + this.propertyType)
        if (this.propertyType.includes("MatType")) {
            widthPx = '1200px'
        }
        
        return "height: " + heightPx + "; width: " + widthPx;
    }
}
