
import { Component, Vue, Prop } from "vue-property-decorator";


@Component({
    
})
export default class SecruityButton extends Vue {

    @Prop()
    disabled!: boolean;

    @Prop()
    buttonText!: boolean;

    @Prop()
    buttonWidth!: string;

    handleClick() {
        this.$emit('securityClick')
    }

}
