
import { Component, Vue, Prop, Watch } from "vue-property-decorator";


@Component({
    
})
export default class SendEmailButton extends Vue {

    @Prop()
    disable: boolean;

    @Prop()
    buttonText!: boolean;

    @Prop()
    buttonId!: string;

    @Prop()
    buttonWidth!: string;


    handleClick() {
        this.$emit('sendClick')
    }



    @Watch('disable')
    onDisableChange(value: boolean, oldValue: boolean) {
        //console.log("send button watcher : " + value)
        //this.isFrameConfigured = value;
        
    }


}
