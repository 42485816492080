<template>
<div id="nav-bar-container">
    <button class="invisible-button" @click="onLogoClick">
    <img
        class="logo"
        src="../assets/images/GBSLogo.jpg"
        width="120px"
        height="200px"
    />
    <img
        class="logo"
        src="../assets/images/GBSLogoText-white.png"
        width="200px"
        height="50px"
    />
    </button>
    <div class="menu-bar">
        <!--b-tooltip      v-if="showDemoTip"
                        style="margin-top: 20px;"
                        :type="'is-warning'"
                        :label="'View Demo to see what it\'s all about!'"
                        :active="active"
                        position="is-left"
                        always
                        multilined
                        >
            </b-tooltip>
            <img v-if="showDemoTip"
                class="logo"
                src="../assets/images/finger-pointer.png"
                width="60px"
                height="100px"
          -->
        <button class="menu-button" @click="onDemoClick">
            <strong>View Demo</strong>
        </button>
        <button class="menu-button" @click="onSamplesClick">
            <strong>View Samples</strong>
        </button>
        <button class="menu-button" @click="onBuildClick">
            <strong>Build Your Puzzle Frame</strong>
        </button>
        <button class="menu-button" @click="onStainClick">
            <strong>Stained Glass Frames</strong>
        </button>
        <button class="menu-button" @click="onAboutClick">
            <strong>About Us</strong>
        </button>
        <button class="menu-button" @click="onContactClick">
            <strong>Contact Us</strong>
        </button>
        
    </div>
    </div>
</template>

<script >
import { Component, Vue, Watch } from "vue-property-decorator";
import * as mut from "@/store/modules/MutationNames"
//import { mutators } from "@/store/mutators";

@Component
export default class SubNavBar extends Vue {
    cartItemsLength = 0;
    showDemoTip = true;

    onBuildClick() {
        this.showDemoTip = false;
        this.$router.push( "/builder").catch(error => {
            if (error.name != "NavigationDuplicated") {
                throw error;
            }
        });
    }

    onStainClick() {
        this.showDemoTip = false;
        this.$router.push( "/stained-glass").catch(error => {
            if (error.name != "NavigationDuplicated") {
                throw error;
            }
        });
    }

    onSamplesClick() {
        this.showDemoTip = false;
        this.$router.push( "/samples").catch(error => {
            if (error.name != "NavigationDuplicated") {
                throw error;
            }
        });
    }

    onDemoClick() {
        this.showDemoTip = false;
        this.$router.push( "/demo").catch(error => {
            if (error.name != "NavigationDuplicated") {
                throw error;
            }
        });
    }

    onAboutClick() {
        this.showDemoTip = false;
        this.$router.push( "/about").catch(error => {
            if (error.name != "NavigationDuplicated") {
                throw error;
            }
        });
    }

    onLogoClick() {
        this.$router.push( "/").catch(error => {
            if (error.name != "NavigationDuplicated") {
                throw error;
            }
        });
    }

    onContactClick() {
        this.$store.commit(mut.SET_EMAIL_REQUEST_TYPE, "contact")
        this.$router.push( "/contact").catch(error => {
            if (error.name != "NavigationDuplicated") {
                throw error;
            }
        });
    }

}
</script>

<style scoped>

#nav-item {
    
}

#nav-button {
    
}

.logo {
    margin-top: 10px;
}

.navbar-item img {
    max-height: 2rem;
    /*background-image: url("../assets/images/woodchip1.png")*/
}
#nav {
    height: 60px;
    padding-left: 1rem;
    padding-right: 1rem;
    position: sticky;
    width: 100%;
    top: 0;
    background-image: url("../assets/images/ReadOak.png");
    opacity: 1.0;
}

.menu-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
    height: 70px;
}

#nav-bar-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


</style>
