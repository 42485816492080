const puzzle_inventory = [
	{
		imgName: "wisby_the_city_of_ruins_and_roses_sweden.jpg",
		thumbName: "wisby_the_city_of_ruins_and_roses_sweden_thumb.jpg",
		name: "Wisby the City of Ruins and Roses, Sweden",
		catagory: "travel_posters",
		height: 18.25,
		width: 11.75
	},
	{
		imgName: "valle_daosta.jpg",
		thumbName: "valle_daosta_thumb.jpg",
		name: "Valle D'Aosta",
		catagory: "travel_posters",
		height: 16.0,
		width: 12.75
	},
	{
		imgName: "lake_thun_sailing_school.jpg",
		thumbName: "lake_thun_sailing_school_thumb.jpg",
		name: "Lake Thun Sailing School",
		catagory: "travel_posters",
		height: 14.75,
		width: 9.75
	},
	{
		imgName: "matson_lines_hawaii.jpg",
		thumbName: "matson_lines_hawaii_thumb.jpg",
		name: "Matson Lines Hawaii",
		catagory: "travel_posters",
		height: 17.5,
		width: 13.75
	},
	{
		imgName: "north_cape_voyage.jpg",
		thumbName: "north_cape_voyage_thumb.jpg",
		name: "North Cape Voyage",
		catagory: "travel_posters",
		height: 16.5,
		width: 12.75
	},
	{
		imgName: "bryce_canyon_national_park.jpg",
		thumbName: "bryce_canyon_national_park_thumb.jpg",
		name: "Bryce Canyon National Park",
		catagory: "travel_posters",
		height: 17.25,
		width: 12.75
	},
	{
		imgName: "grand_canyon_santa_fe_railroad.jpg",
		thumbName: "grand_canyon_santa_fe_railroad_thumb.jpg",
		name: "Grand Canyon Santa Fe Railroad",
		catagory: "travel_posters",
		height: 17.5,
		width: 13.75
	},
	{
		imgName: "yosemite_united_airlines.jpg",
		thumbName: "yosemite_united_airlines_thumb.jpg",
		name: "Yosemite United Airlines",
		catagory: "travel_posters",
		height: 18.75,
		width: 12.75
	},
	{
		imgName: "new_england_united_airlines.jpg",
		thumbName: "new_england_united_airlines_thumb.jpg",
		name: "New England United Airlines",
		catagory: "travel_posters",
		height: 19.25,
		width: 12.75
	},
	{
		imgName: "colorado_united_airlines.jpg",
		thumbName: "colorado_united_airlines_thumb.jpg",
		name: "Colorado United Airlines",
		catagory: "travel_posters",
		height: 17.5,
		width: 13.75
	},
	{
		imgName: "pontresina_travel_poster.jpg",
		thumbName: "pontresina_travel_poster_thumb.jpg",
		name: "Pontresina Travel Poster",
		catagory: "travel_posters",
		height: 18.75,
		width: 11.75
	},
	{
		imgName: "españa.jpg",
		thumbName: "españa_thumb.jpg",
		name: "España",
		catagory: "travel_posters",
		height: 18.25,
		width: 11.25
	},
	{
		imgName: "denmark_land_of_colour.jpg",
		thumbName: "denmark_land_of_colour_thumb.jpg",
		name: "Denmark Land of Colour",
		catagory: "travel_posters",
		height: 18.75,
		width: 10.75
	},
	{
		imgName: "agra.jpg",
		thumbName: "agra_thumb.jpg",
		name: "Agra",
		catagory: "travel_posters",
		height: 20.0,
		width: 12.75
	},
	{
		imgName: "united_airlines_san_francisco.jpg",
		thumbName: "united_airlines_san_francisco_thumb.jpg",
		name: "United Airlines San Francisco",
		catagory: "travel_posters",
		height: 18.75,
		width: 11.75
	},
	{
		imgName: "st._andrews.jpg",
		thumbName: "st._andrews_thumb.jpg",
		name: "St. Andrews",
		catagory: "travel_posters",
		height: 8.5,
		width: 12.75
	},
	{
		imgName: "foire_de_paris_large_piece.jpg",
		thumbName: "foire_de_paris_large_piece_thumb.jpg",
		name: "Foire de Paris Large Piece",
		catagory: "travel_posters",
		height: 17.0,
		width: 12.75
	},
	{
		imgName: "southern_railway.jpg",
		thumbName: "southern_railway_thumb.jpg",
		name: "Southern Railway",
		catagory: "travel_posters",
		height: 16.6499996185303,
		width: 11.75
	},
	{
		imgName: "foire_de_paris.jpg",
		thumbName: "foire_de_paris_thumb.jpg",
		name: "Foire de Paris",
		catagory: "travel_posters",
		height: 17.0,
		width: 12.75
	},
	{
		imgName: "the_palisades_of_the_hudson.jpg",
		thumbName: "the_palisades_of_the_hudson_thumb.jpg",
		name: "The Palisades of the Hudson",
		catagory: "travel_posters",
		height: 14.5,
		width: 9.75
	},
	{
		imgName: "voyage_round_the_world.jpg",
		thumbName: "voyage_round_the_world_thumb.jpg",
		name: "Voyage Round the World",
		catagory: "travel_posters",
		height: 12.75,
		width: 17.5
	},
	{
		imgName: "centenario.jpg",
		thumbName: "centenario_thumb.jpg",
		name: "Centenario",
		catagory: "travel_posters",
		height: 18.25,
		width: 12.75
	},
	{
		imgName: "new_york_the_wonder_city.jpg",
		thumbName: "new_york_the_wonder_city_thumb.jpg",
		name: "New York the Wonder City",
		catagory: "travel_posters",
		height: 17.5,
		width: 12.75
	},
	{
		imgName: "st._moritz.jpg",
		thumbName: "st._moritz_thumb.jpg",
		name: "St. Moritz",
		catagory: "travel_posters",
		height: 13.5,
		width: 8.5
	},
]

export default puzzle_inventory