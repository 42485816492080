
import { Component, Prop } from "vue-property-decorator";
import { Vue, Watch } from "vue-property-decorator";
import * as mut from "@/store/modules/MutationNames";
import MatImage from "@/components/mat/MatImage.vue"
import InfoButton from "@/components/buttons/InfoButton.vue"

@Component({
    components: {
        MatImage,
        InfoButton
    }
})
export default class MatTypeSelector extends Vue {


  created() {
      require(`./images/crescent.jpeg`)
      require(`./images/doubleMatCream.jpeg`)
      require(`./images/singleMat.jpeg`)
  }


}



