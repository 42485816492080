
/*
   An image object car represent a stain, mat, puzzle, etc.
*/

export default class Image {
    public imgName: string;
    public thumbName: string;
    public name: string;
    public height: number;
    public width: number;
    public catagory: string;

    constructor(image?: any) {
        if (!image) {
            this.imgName = "no img";
            this.name = "no name";
            this.height = 0;
            this.width = 0;
            this.catagory = "no category";
            this.thumbName = "no thumb";
        }
        this.imgName = image.imgName;
        this.name = image.name;

        if(!image.height) {
            this.height = 0
        }
        else {
            this.height = image.height;
        }

        if(!image.width) {
            this.width = 0
        }
        else {
            this.width = image.width;
        }
        if(!image.catagory) {
            this.catagory = "no category";
        }
        else {
            this.catagory = image.catagory;
        }
        if(!image.width) {
            this.thumbName = "no thumb";
        }
        else {
            this.thumbName = image.thumbName   
        }
    }

}