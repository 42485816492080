const puzzle_inventory = [
	{
		imgName: "chesapeake_bay_map.jpg",
		thumbName: "chesapeake_bay_map_thumb.jpg",
		name: "Chesapeake Bay Map",
		catagory: "maps",
		height: 19.75,
		width: 14.75
	},
	{
		imgName: "illustrated_map_of_the_republic_of_mexico.jpg",
		thumbName: "illustrated_map_of_the_republic_of_mexico_thumb.jpg",
		name: "Illustrated Map of the Republic of Mexico",
		catagory: "maps",
		height: 13.25,
		width: 20.75
	},
	{
		imgName: "large_Oregon-Trail-watermark-1710.jpeg",
		thumbName: "the_oregon_trail_thumb.jpg",
		name: "The Oregon Trail",
		catagory: "maps",
		height: 12.5,
		width: 23.75
	},
	{
		imgName: "flathead_lake_xplorer_map.jpg",
		thumbName: "flathead_lake_xplorer_map_thumb.jpg",
		name: "Flathead Lake Xplorer Map",
		catagory: "maps",
		height: 21.0,
		width: 15.75
	},
	{
		imgName: "grand_circuit_des_capitales.jpg",
		thumbName: "grand_circuit_des_capitales_thumb.jpg",
		name: "Grand Circuit Des Capitales",
		catagory: "maps",
		height: 12.75,
		width: 23.75
	},
	{
		imgName: "large_hawaiian-islands-image-1400-watermark.jpeg",
		thumbName: "hawaiian_islands_thumb.jpg",
		name: "Hawaiian Islands",
		catagory: "maps",
		height: 12.5,
		width: 23.75
	},
	{
		imgName: "a_story_map_of_france.jpg",
		thumbName: "a_story_map_of_france_thumb.jpg",
		name: "A Story Map of France",
		catagory: "maps",
		height: 15.75,
		width: 19.75
	},
	{
		imgName: "bel_paese_-_gastronomical_map_of_italy.jpg",
		thumbName: "bel_paese_-_gastronomical_map_of_italy_thumb.jpg",
		name: "Bel Paese - Gastronomical Map of Italy",
		catagory: "maps",
		height: 16.25,
		width: 12.75
	},
	{
		imgName: "colorado_xplorer_map.jpg",
		thumbName: "colorado_xplorer_map_thumb.jpg",
		name: "Colorado Xplorer Map",
		catagory: "maps",
		height: 15.75,
		width: 21.0
	},
	{
		imgName: "london_xplorer_map.jpg",
		thumbName: "london_xplorer_map_thumb.jpg",
		name: "London Xplorer Map",
		catagory: "maps",
		height: 14.75,
		width: 19.75
	},
	{
		imgName: "the_wondrous_isle_of_manhattan.jpg",
		thumbName: "the_wondrous_isle_of_manhattan_thumb.jpg",
		name: "The Wondrous Isle of Manhattan",
		catagory: "maps",
		height: 14.75,
		width: 21.5
	},
	{
		imgName: "map_of_long_island.jpg",
		thumbName: "map_of_long_island_thumb.jpg",
		name: "Map of Long Island",
		catagory: "maps",
		height: 14.5,
		width: 21.75
	},
	{
		imgName: "an_illustrated_map_of_chicago.jpg",
		thumbName: "an_illustrated_map_of_chicago_thumb.jpg",
		name: "An Illustrated Map of Chicago",
		catagory: "maps",
		height: 14.5,
		width: 21.75
	},
	{
		imgName: "a_whimsical_map_of_san_diego.jpg",
		thumbName: "a_whimsical_map_of_san_diego_thumb.jpg",
		name: "A Whimsical Map of San Diego",
		catagory: "maps",
		height: 19.5,
		width: 15.75
	},
	{
		imgName: "the_world_map.jpg",
		thumbName: "the_world_map_thumb.jpg",
		name: "The World Map",
		catagory: "maps",
		height: 18.25,
		width: 23.75
	},
	{
		imgName: "texas_map.jpg",
		thumbName: "texas_map_thumb.jpg",
		name: "Texas Map",
		catagory: "maps",
		height: 15.75,
		width: 21.0
	},
	{
		imgName: "a_map_of_interesting_cape_cod.jpg",
		thumbName: "a_map_of_interesting_cape_cod_thumb.jpg",
		name: "A Map of Interesting Cape Cod",
		catagory: "maps",
		height: 12.75,
		width: 19.25
	},
	{
		imgName: "montana_map.jpg",
		thumbName: "montana_map_thumb.jpg",
		name: "Montana Map",
		catagory: "maps",
		height: 15.75,
		width: 21.0
	},
	{
		imgName: "hysterical_map_of_yellowstone_park.jpg",
		thumbName: "hysterical_map_of_yellowstone_park_thumb.jpg",
		name: "Hysterical Map of Yellowstone Park",
		catagory: "maps",
		height: 16.0,
		width: 16.0
	},
	{
		imgName: "lake_tahoe_map.jpg",
		thumbName: "lake_tahoe_map_thumb.jpg",
		name: "Lake Tahoe Map",
		catagory: "maps",
		height: 21.0,
		width: 15.75
	},
	{
		imgName: "san_francisco_bay_map.jpg",
		thumbName: "san_francisco_bay_map_thumb.jpg",
		name: "San Francisco Bay Map",
		catagory: "maps",
		height: 21.0,
		width: 15.75
	},
	{
		imgName: "dole_map_of_the_hawaiian_islands.jpg",
		thumbName: "dole_map_of_the_hawaiian_islands_thumb.jpg",
		name: "Dole Map of the Hawaiian Islands",
		catagory: "maps",
		height: 12.75,
		width: 19.25
	},
	{
		imgName: "california.jpg",
		thumbName: "california_thumb.jpg",
		name: "California",
		catagory: "maps",
		height: 20.5,
		width: 15.75
	},
	{
		imgName: "map_of_paris.jpg",
		thumbName: "map_of_paris_thumb.jpg",
		name: "Map of Paris",
		catagory: "maps",
		height: 13.75,
		width: 15.75
	},
	{
		imgName: "map_of_the_british_empire_in_1886.jpg",
		thumbName: "map_of_the_british_empire_in_1886_thumb.jpg",
		name: "Map of the British Empire in 1886",
		catagory: "maps",
		height: 15.75,
		width: 21.5
	},
	{
		imgName: "map_of_london.jpg",
		thumbName: "map_of_london_thumb.jpg",
		name: "Map of London",
		catagory: "maps",
		height: 12.75,
		width: 18.25
	},
]

export default puzzle_inventory