
import { Component, Prop } from "vue-property-decorator";
import { Vue } from "vue-property-decorator";

@Component({
    components: {
    }
})
export default class MatItem extends Vue {
    @Prop()
    imageName;

    @Prop()
    isSelected;


    created() {
        //console.log("Created Mat image for: " + this.imageName)
    }

    get imagePath() {
        require(`./images/${this.imageName}`)
        let path = "img/" + this.imageName
        //console.log("imagePath: " + path)
        return path
    }

    get style() {

        //if (this.isSelected) {
        let heightPx =  '40px'
        let widthPx = '80px'
        return "height: " + heightPx + "; width: " + widthPx;
    }
}
