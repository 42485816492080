
import { Component, Vue, Prop } from "vue-property-decorator";


@Component({
    
})
export default class CartButton extends Vue {

    @Prop()
    disabled!: boolean;

    handleClick() {
        //console.log("addToCartClick")
        this.$emit("addToCartClick")
    }

}
