
import { Component, Prop, Watch } from "vue-property-decorator";
import { Vue } from "vue-property-decorator";
import PuzzleImageFramed from  "@/components/puzzles/PuzzleImageFramed.vue"
import MatImage from "@/components/mat/MatImage.vue"
import Spinner from "@/components/views/Spinner.vue"
import {MatTypes, DEFAULT_TOP_MAT, DEFAULT_BOTTOM_MAT} from "@/store/GbsStore"



@Component({
    components: {
        PuzzleImageFramed,
        MatImage,
        Spinner
    }
})
export default class DoubleMatPuzzleFrame extends Vue {
    pixelRatio!: number;
    matTypes = MatTypes
    beveledEdge = "border: 2px solid white;"
    bottomMatStyle = ""
    //hasDoubleMat = ;
    defaultTopMat = DEFAULT_TOP_MAT
    isImageLoaded = false;
    frameHeightPixels = "";
    frameWidthPixels = "";
    puzzleHeight = 0;
    puzzleWidth = 0;

    @Prop()
    puzzle;

    @Prop()
    isFullScreen

    created() {
        this.isImageLoaded = this.$store.getters.isPuzzleLoaded
        this.pixelRatio = this.$store.getters.pixelRatio;
        this.setPuzzleAndFrameSize();
        this.$forceUpdate
        this.setBottomMatStyle()
    }

    setPuzzleAndFrameSize() {
        const pIndexHeight = this.$store.getters.frameHeightPixels.indexOf('p')
        const pIndexWidth = this.$store.getters.frameWidthPixels.indexOf('p')
        let height = this.$store.getters.frameHeightPixels.slice(0, pIndexHeight);
        let width = this.$store.getters.frameWidthPixels.slice(0, pIndexWidth);
        if (this.isFullScreen) {
            const heightIncrease = window.innerHeight - height;
            const proportion = heightIncrease/height;
            const fullHeight = (height * proportion);
            const fullWidth =  (width * proportion);
            this.frameHeightPixels = "" + fullHeight + "px";
            this.frameWidthPixels = "" + fullWidth + "px";
            this.puzzleHeight = this.puzzle.height * proportion;
            this.puzzleWidth = this.puzzle.width * proportion;
        }
        else {
            this.frameHeightPixels = this.$store.getters.frameHeightPixels;
            this.frameWidthPixels = this.$store.getters.frameWidthPixels;
            this.puzzleHeight = this.puzzle.height;
            this.puzzleWidth = this.puzzle.width;
        }
    }


    setBottomMatStyle() {
        let sty = ""
        if(this.$store.getters.matType == MatTypes.DOUBLE) {
            let heightPx = ""
            let widthPx = ""
            const pIndexHeight = this.$store.getters.frameHeightPixels.indexOf('p')
            let frameHeight = this.$store.getters.frameHeightPixels.slice(0, pIndexHeight);
            let height = this.$store.getters.puzzleHeightPixels + this.$store.getters.bottomMatPixels; //This extra number is what determines the width of the bottom mat
            let width = this.$store.getters.puzzleWidthPixels + this.$store.getters.bottomMatPixels; // and for now we aren't going to offer any options to change this
            if (this.isFullScreen) {
                const heightIncrease = window.innerHeight - frameHeight;
                const proportion = heightIncrease/frameHeight;
                const fullHeight = (height * proportion);
                const fullWidth =  (width * proportion);
                heightPx = "" + fullHeight + "px";
                widthPx = "" + fullWidth + "px";
            }
            else {
                heightPx = "" + height + "px";
                widthPx = "" + width + "px";
            }
            
            sty =     this.beveledEdge +
                        "height: " + heightPx + ";" +
                        "width: " + widthPx + ";" +
                        "background-image: url(" + this.$store.getters.bottomMatImagePath + ");"
        }
        this.bottomMatStyle =  sty
    }

    //For now we aren't going to offer any option to alter bevel width
    /*@Watch('$store.getters.matBevelWidth')
    onBevelEdgeChange(value: number) {
        //console.log("onBevelEdgeChange got: ", value)
        this.bevelEdge = "border: " + value + "px solid white;"
         this.$forceUpdate()
    }*/

    @Watch('$store.getters.isMatWithCore')
    onIsMatWithCoreChange(hasCore: boolean) {
        //console.log("onIsMatWithCoreChange got: ", hasCore)
        if (hasCore) {
            this.beveledEdge = "border: 2px solid white;"
        }
        else {
            this.beveledEdge = ""
        }
         this.$forceUpdate()
    }

    
    @Watch('$store.getters.matType')
    onMatTypeChange(value: number) {
        if (value == this.matTypes.DOUBLE) {
            this.setBottomMatStyle();
        }
        else {
            this.bottomMatStyle = "";
        }
        this.$forceUpdate()

    }

    @Watch('$store.getters.selectedPuzzle')
    onPuzzleChange(value: string) {
        //console.log("frame heard puzzle change to: ", value)
        this.setBottomMatStyle();
        this.setPuzzleAndFrameSize()
         this.$forceUpdate()
    }
    
    @Watch('$store.getters.bottomMatImagePath')
    onBottomMatImagePathChange(value: string) {
        this.setBottomMatStyle()
         this.$forceUpdate()
    }

    @Watch('$store.getters.topMatImagePath')
    onTopMatImagePathChange(value: string) {
         this.$forceUpdate()
    }

    @Watch('$store.getters.bottomMatPixels')
    onBottomMatPixChange(value: number) {
        ////console.log("frame heard bottomMatPixels change to: " + value)
        this.setBottomMatStyle()
        this.$forceUpdate()
    }

    @Watch('$store.getters.isPuzzleLoaded')
    onPuzzleLoadEvent(value: boolean) {
        this.isImageLoaded = value
    }

}
