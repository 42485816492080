export default class FrameSample {
    public imgName: string;
    public name: string;
    public topMatColor: string;
    public wood: string;
    public stain: string;
    public tableRows: Object;

    constructor(sample?: any) {
        this.imgName = sample.imgName;
        this.name = sample.name;
        this.topMatColor = sample.topMatColor
        this.wood = sample.wood
        this.stain = sample.stain
        this.tableRows = [{"id": 1,
                             "itemAttribute": "Mat Color:",
                             "selectedValue": this.topMatColor
                             },
                             {"id": 2,
                             "itemAttribute": "Wood:",
                             "selectedValue": this.wood
                             },
                             {"id": 3,
                             "itemAttribute": "Stain:",
                             "selectedValue": this.stain
                             },
        ]
    }

}