const puzzle_inventory = [
	{
		imgName: "playin_hookie.jpg",
		thumbName: "playin_hookie_thumb.jpg",
		name: "Playin' Hookie",
		catagory: "little_liberty",
		height: 9.75,
		width: 11.25
	},
	{
		imgName: "dish_ran_away_with_the_spoon.jpg",
		thumbName: "dish_ran_away_with_the_spoon_thumb.jpg",
		name: "Dish Ran Away with the Spoon",
		catagory: "little_liberty",
		height: 9.75,
		width: 11.4
	},
	{
		imgName: "hook_overboard.jpg",
		thumbName: "hook_overboard_thumb.jpg",
		name: "Hook Overboard",
		catagory: "little_liberty",
		height: 11.0,
		width: 9.75
	},
	{
		imgName: "jack_the_giant_killer.jpg",
		thumbName: "jack_the_giant_killer_thumb.jpg",
		name: "Jack the Giant Killer",
		catagory: "little_liberty",
		height: 11.75,
		width: 9.0
	},
	{
		imgName: "jolly_roger.jpg",
		thumbName: "jolly_roger_thumb.jpg",
		name: "Jolly Roger",
		catagory: "little_liberty",
		height: 11.0,
		width: 9.75
	},
	{
		imgName: "little_dog_laughed.jpg",
		thumbName: "little_dog_laughed_thumb.jpg",
		name: "Little Dog Laughed",
		catagory: "little_liberty",
		height: 9.75,
		width: 11.3400001525879
	},
	{
		imgName: "old_woman_in_shoe.jpg",
		thumbName: "old_woman_in_shoe_thumb.jpg",
		name: "Old Woman in Shoe",
		catagory: "little_liberty",
		height: 8.75,
		width: 12.3
	},
	{
		imgName: "peter_pan_flying.jpg",
		thumbName: "peter_pan_flying_thumb.jpg",
		name: "Peter Pan Flying",
		catagory: "little_liberty",
		height: 11.25,
		width: 9.75
	},
	{
		imgName: "sand_castles.jpg",
		thumbName: "sand_castles_thumb.jpg",
		name: "Sand Castles",
		catagory: "little_liberty",
		height: 8.25,
		width: 13.5
	},
	{
		imgName: "tinkerbell.jpg",
		thumbName: "tinkerbell-thumb.png",
		name: "Tinkerbell",
		catagory: "little_liberty",
		height: 11.0,
		width: 9.75
	},
]

export default puzzle_inventory