
import { Component } from "vue-property-decorator";
import { Vue, Prop, Watch} from "vue-property-decorator";
import InfoButton from "@/components/buttons/InfoButton.vue"
import PencilButton from "@/components/buttons/PencilButton.vue"

@Component({
    components: {
        InfoButton,
        PencilButton
    }
})
export default class TopMatColorLineItem extends Vue {
  matTypePrefix = ""

  created() {
      this.parseMatPrefixName(this.$store.getters.matType)
  } 

  parseMatPrefixName(value: number) {
    if (value == this.$store.getters.matTypes.DOUBLE) {
            this.matTypePrefix = "Primary"
        }
        if (value == this.$store.getters.matTypes.SINGLE) {
          this.matTypePrefix = ""
        }
  }

  infoClick() {
    this.$emit("matColorInfoClick")
  }

  
  editClick() {
      this.$emit("editTopMatColorClick")
  }

  @Watch('$store.getters.matType')
    onMatTypeChange(value: number) {
      this.parseMatPrefixName(value)
      this.$forceUpdate()
    }

  


}



