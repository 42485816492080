
import { Component, Watch } from "vue-property-decorator";
import { Vue } from "vue-property-decorator";

@Component({
    components: {
    }
})
export default class About extends Vue {
    showAbout = false;
  created() {
      //console.log("About page has been created.")
      this.showAbout = this.$store.getters.showAbout
  }


  @Watch('$store.getters.showAbout')
    onPageChange(value: boolean, oldValue: boolean) {
        //console.log("about onPageChange: ", value)
        this.showAbout = value;
        this.$forceUpdate()
    }
}
