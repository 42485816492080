const puzzle_inventory = [
	{
		imgName: "cosy_cat.jpg",
		thumbName: "cosy_cat_thumb.jpg",
		name: "Cosy Cat",
		catagory: "winter",
		height: 16.5,
		width: 13.75
	},
	{
		imgName: "star_gazing.jpg",
		thumbName: "star_gazing_thumb.jpg",
		name: "Star Gazing",
		catagory: "winter",
		height: 15.25,
		width: 11.25
	},
	{
		imgName: "large_Nutcracker-Stage-image-1200-watermark.jpeg",
		thumbName: "tchaikovskys_nutcracker_stage_thumb.jpg",
		name: "Tchaikovsky's Nutcracker Stage",
		catagory: "winter",
		height: 11.75,
		width: 17.25
	},
	{
		imgName: "large_Stacks-of-Wheat-image-1300-watermark.jpeg",
		thumbName: "stacks_of_wheat_sunset_snow_effect_large_piece_thumb.jpg",
		name: "Stacks of Wheat (Sunset, Snow Effect) Large Piece",
		catagory: "winter",
		height: 11.75,
		width: 18.25
	},
	{
		imgName: "large_Shrovetide-image-1500-watermark.jpeg",
		thumbName: "shrovetide_thumb.jpg",
		name: "Shrovetide",
		catagory: "winter",
		height: 10.0,
		width: 21.75
	},
	{
		imgName: "peace_on_earth.jpg",
		thumbName: "peace_on_earth_thumb.jpg",
		name: "Peace on Earth",
		catagory: "winter",
		height: 7.0,
		width: 7.0
	},
	{
		imgName: "a_winters_day.jpg",
		thumbName: "a_winters_day_thumb.jpg",
		name: "A Winter's Day",
		catagory: "winter",
		height: 13.75,
		width: 16.5
	},
	{
		imgName: "that_winter_spring_came_late.jpg",
		thumbName: "that_winter_spring_came_late_thumb.jpg",
		name: "That Winter Spring Came Late",
		catagory: "winter",
		height: 17.75,
		width: 11.75
	},
	{
		imgName: "foxes_in_the_moonlight.jpg",
		thumbName: "foxes_in_the_moonlight_thumb.jpg",
		name: "Foxes in the Moonlight",
		catagory: "winter",
		height: 16.5,
		width: 13.75
	},
	{
		imgName: "a_tasty_treat.jpg",
		thumbName: "a_tasty_treat_thumb.jpg",
		name: "A Tasty Treat",
		catagory: "winter",
		height: 17.5,
		width: 13.75
	},
	{
		imgName: "starry_river.jpg",
		thumbName: "starry_river_thumb.jpg",
		name: "Starry River",
		catagory: "winter",
		height: 11.75,
		width: 18.0
	},
	{
		imgName: "snowbirds.jpg",
		thumbName: "snowbirds_thumb.jpg",
		name: "Snowbirds",
		catagory: "winter",
		height: 16.5,
		width: 13.75
	},
	{
		imgName: "first_sprinkling.jpg",
		thumbName: "first_sprinkling_thumb.jpg",
		name: "First Sprinkling",
		catagory: "winter",
		height: 15.25,
		width: 14.75
	},
	{
		imgName: "davos_in_winter.jpg",
		thumbName: "davos_in_winter_thumb.jpg",
		name: "Davos in Winter",
		catagory: "winter",
		height: 9.75,
		width: 12.25
	},
	{
		imgName: "this_is_life.jpg",
		thumbName: "this_is_life_thumb.jpg",
		name: "This is Life",
		catagory: "winter",
		height: 12.75,
		width: 17.25
	},
	{
		imgName: "winterlands.jpg",
		thumbName: "winterlands_thumb.jpg",
		name: "Winterlands",
		catagory: "winter",
		height: 14.75,
		width: 15.0
	},
	{
		imgName: "central_european_songbirds.jpg",
		thumbName: "central_european_songbirds_thumb.jpg",
		name: "Central European Songbirds",
		catagory: "winter",
		height: 15.25,
		width: 10.25
	},
	{
		imgName: "champery_valais-suisse.jpg",
		thumbName: "champery_valais-suisse_thumb.jpg",
		name: "Champery, Valais-Suisse",
		catagory: "winter",
		height: 17.0,
		width: 12.75
	},
	{
		imgName: "light_snowfall_in_new_york.jpg",
		thumbName: "light_snowfall_in_new_york_thumb.jpg",
		name: "Light Snowfall in New York",
		catagory: "winter",
		height: 21.5,
		width: 14.75
	},
	{
		imgName: "dolomiti.jpg",
		thumbName: "dolomiti_thumb.jpg",
		name: "Dolomiti",
		catagory: "winter",
		height: 12.5,
		width: 8.25
	},
	{
		imgName: "grand_hotel_belvedere.jpg",
		thumbName: "grand_hotel_belvedere_thumb.jpg",
		name: "Grand Hotel Belvedere",
		catagory: "winter",
		height: 18.0,
		width: 12.75
	},
	{
		imgName: "mountain_majesty.jpg",
		thumbName: "mountain_majesty_thumb.jpg",
		name: "Mountain Majesty",
		catagory: "winter",
		height: 12.75,
		width: 9.0
	},
	{
		imgName: "skating_in_holland.jpg",
		thumbName: "skating_in_holland_thumb.jpg",
		name: "Skating in Holland",
		catagory: "winter",
		height: 12.75,
		width: 17.0
	},
	{
		imgName: "vintage_winter_days.jpg",
		thumbName: "vintage_winter_days_thumb.jpg",
		name: "Vintage Winter Days",
		catagory: "winter",
		height: 18.5,
		width: 14.75
	},
	{
		imgName: "mount_haruna_under_snow.jpg",
		thumbName: "mount_haruna_under_snow_thumb.jpg",
		name: "Mount Haruna Under Snow",
		catagory: "winter",
		height: 18.0,
		width: 11.75
	},
	{
		imgName: "winter_sports_by_south_shore_line.jpg",
		thumbName: "winter_sports_by_south_shore_line_thumb.jpg",
		name: "Winter Sports by South Shore Line",
		catagory: "winter",
		height: 18.0,
		width: 12.75
	},
	{
		imgName: "meguro_drum_bridge.jpg",
		thumbName: "meguro_drum_bridge_thumb.jpg",
		name: "Meguro Drum Bridge",
		catagory: "winter",
		height: 7.0,
		width: 7.0
	},
	{
		imgName: "winter_landscape_in_moonlight.jpg",
		thumbName: "winter_landscape_in_moonlight_thumb.jpg",
		name: "Winter Landscape in Moonlight",
		catagory: "winter",
		height: 14.75,
		width: 14.75
	},
	{
		imgName: "winter_carnival_fair_1919.jpg",
		thumbName: "winter_carnival_fair_1919_thumb.jpg",
		name: "Winter, Carnival Fair, 1919",
		catagory: "winter",
		height: 13.75,
		width: 16.5
	},
	{
		imgName: "central_park_winter.jpg",
		thumbName: "central_park_winter_thumb.jpg",
		name: "Central Park, Winter",
		catagory: "winter",
		height: 13.75,
		width: 21.75
	},
	{
		imgName: "atagoshita_and_yabu_lane.jpg",
		thumbName: "atagoshita_and_yabu_lane_thumb.jpg",
		name: "Atagoshita and Yabu Lane",
		catagory: "winter",
		height: 19.25,
		width: 12.75
	},
	{
		imgName: "winter_carnival_iii.jpg",
		thumbName: "winter_carnival_iii_thumb.jpg",
		name: "Winter Carnival III",
		catagory: "winter",
		height: 16.5,
		width: 12.75
	},
	{
		imgName: "snowflake.jpg",
		thumbName: "snowflake_thumb.jpg",
		name: "Snowflake",
		catagory: "winter",
		height: 9.5,
		width: 9.5
	},
	{
		imgName: "winter_in_the_country_gathering_ice.jpg",
		thumbName: "winter_in_the_country_gathering_ice_thumb.jpg",
		name: "Winter in the Country: Gathering Ice",
		catagory: "winter",
		height: 12.75,
		width: 18.5
	},
	{
		imgName: "cachats_majestic_chamonix.jpg",
		thumbName: "cachats_majestic_chamonix_thumb.jpg",
		name: "Cachats Majestic Chamonix",
		catagory: "winter",
		height: 12.75,
		width: 17.0
	},
	{
		imgName: "flags_on_57th_street_winter_1918.jpg",
		thumbName: "flags_on_57th_street_winter_1918_thumb.jpg",
		name: "Flags on 57th Street, Winter 1918",
		catagory: "winter",
		height: 13.0,
		width: 8.5
	},
	{
		imgName: "grindelwald_winter_sport.jpg",
		thumbName: "grindelwald_winter_sport_thumb.jpg",
		name: "Grindelwald Winter Sport",
		catagory: "winter",
		height: 18.5,
		width: 12.75
	},
	{
		imgName: "tobogganing_in_the_snow.jpg",
		thumbName: "tobogganing_in_the_snow_thumb.jpg",
		name: "Tobogganing in the Snow",
		catagory: "winter",
		height: 10.25,
		width: 16.0
	},
	{
		imgName: "christmas_angel_round.jpg",
		thumbName: "christmas_angel_round_thumb.jpg",
		name: "Christmas Angel round",
		catagory: "winter",
		height: 7.0,
		width: 7.0
	},
]

export default puzzle_inventory