
import { Vue, Component } from "vue-property-decorator";
import * as act from "@/store/modules/ActionNames";
import FrameImage from "@/components/frame/FrameImage.vue"
import CustomSelect from  "@/components/controls/CustomSelect.vue"



@Component({
    components: {
        FrameImage,
        CustomSelect
    }
})
export default class FrameWoodSelector extends Vue {
    isInitialPuzzleSetup = this.$store.getters.isInitialPuzzleSetup
    showInfo = false;
    placeholder="Select wood..."
    selectedWood = null;

    created() {
        if(this.$store.getters.buildStep > this.$store.getters.buildSteps.SELECT_WOOD_TYPE) {
            this.selectedWood = this.$store.getters.selectedWood
        }
    }
    
    handleWoodChange(e: string) {
        //console.log("selected wood handler event: ", e)
        this.$store.dispatch(act.SET_SELECTED_WOOD, e)
        this.selectedWood = e;
    }
}

