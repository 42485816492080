
/* eslint-disable  no-unused-vars */ 

import Vue from 'vue'
import Vuelidate from 'vuelidate'
import {required, requiredIf, email} from 'vuelidate/lib/validators'
Vue.use(Vuelidate as any);

export default Vue.extend({
  name: 'EmailAddressInput',
  props: {settingTitle: String,
          propName: String,
          settingValue: String,
          isRequired: Boolean,
          disable: Boolean
  },
  data() {
    return {
      localnextValue: this.settingValue,
      //this is a hack to get around requred validator not being used when using requiredIf
      hack: true,
      isFirstClick: true
    }
  },
  validations () {
    return {
      hack: {
        required
      },
      localnextValue: {
         required: requiredIf(function(this: any) {
                    //console.log("Avoid eslint no-unused-var error: ", this)
                    return this.isRequired;
                }),
          email
      }
    }
  },
  methods: {
    setValue(newValue: string) {
      this.$v.localnextValue.$touch()
      const eventName = "update" + this.propName
      let addr = newValue.toLowerCase()
      this.$emit(eventName, addr)
    }
  },
  computed: {
    isValid: function() {
      return !this.$v.localnextValue.$invalid
    },
    fieldWidth: function () {
      if (String(this.settingValue).length > 21) {
        return `${String(this.settingValue).length + 2}ch`
      }
      return "21ch"
    }
  },
  watch: {
    isValid(val) {
      const eventName = "valid" + this.propName
      this.$emit(eventName, val)
    }
  }
})
/* eslint-disable  no-unused-vars */ 
