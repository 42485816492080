
import { Prop, Component, Vue } from "vue-property-decorator";

@Component({
    
})
export default class CancelButton extends Vue {
     @Prop()
    disabled!: boolean;

    handleClick() {
        this.$emit('cancelClick')
    }
}
