
import { Component } from "vue-property-decorator";
import { Vue, Watch } from "vue-property-decorator";
import * as mut from "@/store/modules/MutationNames";
import 'tachyons';

//Implemented with https://codepen.io/tomascherry/pen/GrgbzQ
// https://levelup.gitconnected.com/display-images-like-google-and-flickr-with-vue-js-e4cb8159ecfa
@Component({
    components: {
    }
})
export default class PuzzleSearchTextBox extends Vue {
    
    isInitialPuzzleSetup = this.$store.getters.isInitialPuzzleSetup
    search = '';
    isChosen = false;

  

  @Watch('search')
    onSearchChange(value: string, oldValue: string) {
        this.$store.commit(mut.SET_PUZZLE_SEARCH_TEXT, value);
    }


}


