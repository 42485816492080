
import { Component, Vue, Prop } from "vue-property-decorator";


@Component({
    
})
export default class CartButton extends Vue {

    @Prop()
    disabled!: boolean;

    @Prop()
    buttonText!: boolean;

    @Prop()
    buttonId!: string;

    @Prop()
    buttonWidth!: string;

    handleClick() {
        this.$emit(this.buttonId + 'Click')
    }

}
