
import { Component, Prop, Watch } from "vue-property-decorator";
import { Vue } from "vue-property-decorator";
import * as act from '@/store/modules/ActionNames';
import InfoButton from "@/components/buttons/InfoButton.vue"
import InfoPopup from "@/components/views/info/InfoPopup.vue"

@Component({
  components: {
        InfoButton,
        InfoPopup
    }
})
export default class RadioButtons extends Vue {
  matType = this.$store.getters.matType
  matTypes = this.$store.getters.matTypes
  matTypeName = "Unselected";
  showInfo = false;

  created() {
    if(this.matType == this.matTypes.SINGLE) {
        this.matTypeName = "single"
    }
    if(this.matType == this.matTypes.DOUBLE) {
      this.matTypeName = "double"
    }
  }

  closeInfo() {
        this.showInfo = false;
  }

  @Watch('matTypeName')
  onNameChange() {
    let mtype = 0
    if(this.matTypeName == "single") {
        mtype = this.matTypes.SINGLE;
    }
    if(this.matTypeName == "double") {
      mtype = this.matTypes.DOUBLE
    }
    this.$store.dispatch(act.SET_MAT_TYPE, mtype )
  }

  @Watch('$store.getters.matType')
  onMatTypeChange(value: number) {
    if(value == this.matTypes.SINGLE) {
        this.matTypeName = "single"
    }
    if(value == this.matTypes.DOUBLE) {
      this.matTypeName = "double"
    }
  }
}

