
import Vue from 'vue'
import Component from 'vue-class-component'
import {Prop} from 'vue-property-decorator'

@Component({
})
export default class PencilButton extends Vue {

    @Prop()
    propertyId!: string;

    @Prop()
    propertyType!: string;

    handleClick() {
        let eventName = this.propertyType + "Click"
        this.$emit(eventName, this.propertyId)
    };
}
