
import { Component, Prop } from "vue-property-decorator";
import { Vue } from "vue-property-decorator";
import DoubleMatPuzzleFrame from  "@/components/puzzles/DoubleMatPuzzleFrame.vue"

@Component({
    components: {
        DoubleMatPuzzleFrame
    }
})
export default class FullScreenPreview extends Vue {
    @Prop()
    matImagePath;

    @Prop()
    frameHeightPixels;

    @Prop()
    frameWidthPixels;

    @Prop()
    selectedPuzzle;

    @Prop()
    stainImage;
    
    created() {
        console.log("Created FullScreenPreview")
        console.log("matImagePath:" + this.matImagePath)
        console.log("frameHeightPixels: " + this.frameHeightPixels)
        console.log("frameWidthPixels: " + this.frameWidthPixels)
        console.log("selectedPuzzle: " + this.selectedPuzzle.name)

    }

    closeFullScreen() {
        this.$emit("closeFullScreen")
    }

    
}
