import Vue from 'vue';
import App from './App.vue';
import router from './router/index.js';
import store from './store';
//import system from './modules/system/module'


import 'buefy/dist/buefy.css';
import "./assets/sass/main.scss";


// Full Buefy import
import Buefy from 'buefy';
Vue.use(Buefy);

//Individual Buefy imports
import Navbar from "buefy/dist/esm/navbar.js";
import Switch from "buefy/dist/esm/switch.js";
import Dialog from "buefy/dist/esm/dialog.js";
import Toast from "buefy/dist/esm/toast.js";
import Field from "buefy/dist/esm/field.js";
import Input from "buefy/dist/esm/input.js";
import Menu from "buefy/dist/esm/menu.js";
import MenuItem from "buefy/dist/esm/menu.js";
import MenuList from "buefy/dist/esm/menu.js";
import Sidebar from "buefy/dist/esm/sidebar.js";
import Tag from "buefy/dist/esm/tag.js";
import Checkbox from "buefy/dist/esm/checkbox.js";
import Progress from "buefy/dist/esm/progress.js";
import Button from "buefy/dist/esm/button.js";
import Radio from "buefy/dist/esm/radio.js";
import Tooltip from "buefy/dist/esm/tooltip.js";
import Carousel from "buefy/dist/esm/carousel.js";
import Select from "buefy/dist/esm/select.js";
import Table from "buefy/dist/esm/table.js";

Vue.use(Table);
Vue.use(Field);
Vue.use(Input);
Vue.use(Toast);
Vue.use(Dialog);
Vue.use(Switch);
Vue.use(Navbar);
Vue.use(Menu);
Vue.use(MenuItem);
Vue.use(MenuList);
Vue.use(Sidebar);
Vue.use(Tag);
Vue.use(Checkbox);
Vue.use(Progress);
Vue.use(Button);
Vue.use(Radio);
Vue.use(Tooltip);
Vue.use(Carousel);
Vue.use(Select);


Vue.config.productionTip = false;
//Vue.prototype.$store = store;

//TODO add store in Vue argument object
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
