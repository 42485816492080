
import { Component } from "vue-property-decorator";
import { Vue } from "vue-property-decorator";

@Component({
    components: {
       
    }
})
export default class Mantra extends Vue {


  


}



