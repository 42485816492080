
import Vue from 'vue'
import Vuelidate from 'vuelidate'
import {required, requiredIf} from 'vuelidate/lib/validators'
Vue.use(Vuelidate as any);

export default Vue.extend({
  name: 'TextSetting',
  props: {settingTitle: String,
          propName: String,
          settingValue: String,
          isRequired: Boolean,
          readonly: String,
          disable: Boolean
  },
  data() {
    return {
      localnextValue: this.settingValue,
      //this is a hack to get around requred validator not being used when using requiredIf
      hack: true,
      isMaxLength: false
    }
  },
  validations () {
    return {
      hack: {
        required
      },
      localnextValue: {
         required: requiredIf(function(this: any) {
                    return this.isRequired;
                }) 
      }
    }
  },
  methods: {
    setValue(newValue: string) {
      this.$v.localnextValue.$touch()
      const eventName = "update" + this.propName
      //console.log("emitting " + eventName + " to value: " + newValue)
      this.$emit(eventName, newValue)
      if (newValue.length > 499 ) {
        this.isMaxLength = true
      }
      else {
        this.isMaxLength = false
      }
    }
  },
  computed: {
    isValid: function() {
      return !this.$v.localnextValue.$invalid
    },
    isDirty: function() {
      return this.$v.localnextValue.$dirty
    },
    fieldWidth: function () {
      if (String(this.settingValue).length > 21) {
        return `${String(this.settingValue).length + 2}ch`
      }
      return "21ch"
    }
  },
  watch: {
    isValid(val) {
      const eventName = "setValidStatus" + this.propName
      //console.log("emitting " + eventName + " to value: " + val)
      this.$emit(eventName, val)
    },
    isDirty(val) {
      const eventName = "setDirtyStatus" + this.propName
      //console.log("emitting " + eventName + " to value: " + val)
      this.$emit(eventName, val)
    },
    settingValue(val) {
        this.localnextValue = val
    }

  }
})
