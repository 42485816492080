const puzzle_inventory = [
	{
		imgName: "princess_hyacinth.jpg",
		thumbName: "princess_hyacinth_thumb.jpg",
		name: "Princess Hyacinth",
		catagory: "art_nouveau",
		height: 18.75,
		width: 12.75
	},
	{
		imgName: "rose_mucha.jpg",
		thumbName: "rose_mucha_thumb.jpg",
		name: "Rose Mucha",
		catagory: "art_nouveau",
		height: 17.75,
		width: 7.0
	},
]

export default puzzle_inventory