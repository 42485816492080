
import { Component } from "vue-property-decorator";
import { Vue, Watch } from "vue-property-decorator";
import * as act from "@/store/modules/ActionNames";
import * as mut from '@/store/modules/MutationNames';
import CustomSelect from  "@/components/controls/CustomSelect.vue"
import InfoButton from "@/components/buttons/InfoButton.vue"
import InfoPopup from "@/components/views/info/InfoPopup.vue"

@Component({
    components: {
        CustomSelect,
        InfoButton,
        InfoPopup
    }
})
export default class MatWidthSelector extends Vue {
    
    //width
    placeholder="Select Mat Width..."
    sizeOptions = new Array<any>();
    selectedKey = 10;
    selectedPuzzle = this.$store.getters.selectedPuzzle
    isInitialPuzzleSetup!: boolean;
    showInfo = false;
    topMatWidth = null;

    created() {
        let options = this.getSizeOptions()
        options.forEach(opt => {
            this.sizeOptions.push({key: opt.size, value:opt.name })
        });
        this.isInitialPuzzleSetup = this.$store.getters.isInitialPuzzleSetup
        if (this.$store.getters.buildStep > this.$store.getters.buildSteps.SELECT_TOP_MAT_WIDTH) {
            this.topMatWidth = this.$store.getters.topMatWidth
        }
    }

    closeInfo() {
        this.showInfo = false;
    }

   
    getSizeOptions() {
        let options = new Array<any>();
        // Determine what the largest mat size is based on puzzle size and 
        //matboardandmore.com max size 40" x 32"
        let puzzle = this.selectedPuzzle
        //Key is the actual size, value is the text representation of key as displayed in drop down box
        let largest = {value: "", key: 0}
        let widthMax;
        let lengthMax;
        if (puzzle.width > puzzle.height) {
            //This is a landscape format
            widthMax = (40 - puzzle.width) / 2;
            lengthMax = (32 - puzzle.height) / 2;
        }
        else {
            widthMax = (32 - puzzle.width) / 2;
            lengthMax = (40 - puzzle.height) / 2;
        }
        widthMax < lengthMax ? largest.key = widthMax : largest.key = lengthMax;

        //Round down the size to nearest 1/2"
        let increment = .5;
        var decimal = largest.key - Math.floor(largest.key);
        if (decimal > .5) {
            //Round down to .5
            largest.key  = Math.floor(largest.key) + .5;
        } else {
            //Round down to integer
            largest.key  = Math.floor(largest.key);
        }
        largest.value = largest.key + '"'

        //Overide the determination of 'largest' and limit that to be 6"
        largest.key = 5.5
        largest.value = '5.5"'

        options.push(largest);
        

        let smallestIncrement = 1;
        let nextSizeIncrement = largest.key - increment;
        while (nextSizeIncrement >= smallestIncrement) {
            ////console.log("matSizeOptions.push: " + nextSizeIncrement + " / " + nextSizeIncrement + '"')
            options.push({name: nextSizeIncrement + '"', size: nextSizeIncrement});
            nextSizeIncrement = nextSizeIncrement - increment;
        }
        options = options.reverse()
        
        //Override options and just give the 1" option
        options = [{name: '1"', size: 1}]
        return options
    }



    handleSizeChange(e) {
        //console.log("Selected size: " + e)
        this.topMatWidth = e
        this.$store.dispatch(act.SET_TOP_MAT_WIDTH, e)
        this.$forceUpdate();
    }

}



