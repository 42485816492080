
import { Component, Vue, Prop } from "vue-property-decorator";


@Component({
    
})
export default class CartCheckoutButton extends Vue {

    @Prop()
    disabled!: boolean;


    handleClick() {
        this.$emit('checkoutClick')
    }

}
