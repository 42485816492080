
import { Component, Watch } from "vue-property-decorator";
import { Vue } from "vue-property-decorator";
import SendEmailButton from "@/components/buttons/SendEmailButton.vue"
import SecurityButton from "@/components/buttons/SecurityButton.vue"
import CancelButton from "@/components/buttons/CancelButton.vue"
import EmailAddressSetting from  '@/components/controls/EmailAddressSetting.vue'
import TextSetting from  '@/components/controls/TextSetting.vue'
import * as act from '@/store/modules/ActionNames';
import { DialogProgrammatic as Dialog } from 'buefy';
import { ToastProgrammatic as Toast } from 'buefy';
import { getInchesAsString } from "@/store/GbsStore"

function sleep(seconds) {
   let milliseconds = seconds * 1000
   const promise = new Promise(resolve => {
       setTimeout(() => {
         resolve('done sleeping');
       }, milliseconds);
     });
   return promise
}

@Component({
    components: {
      SendEmailButton,
      CancelButton,
      EmailAddressSetting,
      TextSetting,
      SecurityButton
    }
})
export default class Contact extends Vue {
    emailAddress = "";
    cart = this.$store.getters.cart
    cartLineItems = new Array<string>()
    isEmailAddrValid = false
    comments=""
    orderedFrames = ""
    requestType = ""
    pageTitle = ""
    subject = ""
    textBoxTitle = ""
    showPrivacyPolicy = false;
    showPrivicyToolTip = true;
    showEmailToolTip = true;

  created() {
        this.requestType = this.$store.getters.emailRequestType
        this.setProperties()
        this.cart.forEach(item => { let topMatColorTitle = "Mat Color";
                                    let topMatWidthTitle = "Mat Size";
                                    if (item.bottomMatReveal > 0) {
                                        topMatColorTitle = "Primary Mat Color";
                                        topMatWidthTitle = "Primary Mat Size";
                                    }
                                    this.cartLineItems.push(`-------`);
                                    this.cartLineItems.push(`     Puzzle:            ${item.puzzle}`);
                                    this.cartLineItems.push(`     ${topMatColorTitle}:   ${item.topMatColor}`);
                                    this.cartLineItems.push(`     ${topMatWidthTitle}:   ${getInchesAsString(item.topMatWidth)}`);
                                    if (item.bottomMatReveal > 0) { 
                                        this.cartLineItems.push(`     Accent Mat Color :   ${item.bottomMatColor}`);
                                        this.cartLineItems.push(`     Accent Mat Reveal:   ${getInchesAsString(item.bottomMatReveal)}`);
                                    }
                                    this.cartLineItems.push(`     Wood:              ${item.wood}`);
                                    this.cartLineItems.push(`     Stain:             ${item.stain}`);
                              })
  }

  handlePolicyClick() {
    this.$router.push( "/policy");
  }

  closePrivacyPolicy() {
    this.showPrivicyToolTip  = false;
    this.showPrivacyPolicy = false;
  }

  validateEmailAddress(b: boolean) {
    this.isEmailAddrValid = b
  }

  setEmailAddress(addr: string) {
    this.showPrivicyToolTip  = false;
    this.showEmailToolTip = false;
    this.emailAddress = addr
  }
  
  handleSendClick() {
      Dialog.confirm({
            title: "Confirm your email address:",
            message:
                this.emailAddress,
            type: "is-danger",
            cancelText: 'Edit Email Address',
            confirmText: "Send",
            onConfirm: () => this.sendEmail()
        });
      
  }

  handleCancelClick() {
    switch(this.requestType) {
      case("addPuzzle"):
        this.$router.push( "/builder");
        break;
      case("purchase"):
        this.$router.push( "/purchase");
        break;
    }
  }

  sendEmail() {
    let body = ""
    if (this.requestType == "purchase") {
      this.cartLineItems.forEach( i => {body.concat("\n", i);})
    }
    body.concat("\n", this.comments);
    const payload = {requestType: this.requestType, emailAddress: this.emailAddress, subject: this.subject, comments: this.comments}
    // payload: {requestType: string, emailAddress: string, subject: string, comments: string})
    this.$store.dispatch(act.SEND_EMAIL, payload)
    //console.log("TODO: create EMAIL_ORDER to: ", payload)
    this.$router.push( "/builder");
    Toast.open('Email has been sent')
  }

  updateComments(s: string) {
    this.comments = s
  }

  setProperties() {
    switch(this.requestType) {
      case("addPuzzle"):
        this.subject = "Add Puzzle Request"
        this.textBoxTitle = "Enter the name of the puzzle(s) to add:"
        this.pageTitle = "Enter the name of the puzzle you would like added to the list in the comments section below and I will add the puzzle ASAP and notify you when I do.";
        break;
      case("purchase"):
        this.subject = "Purchase"
        this.textBoxTitle = "Other comments or questions:"
        this.pageTitle = "I will get back to you ASAP so we can discuss payment options.";
        break;
      case("contact"):
        this.subject = "Comment/Question"
        this.textBoxTitle = "Comments or questions:"
        this.pageTitle = "Type your questions or comments below and I will get back to you ASAP!";
        break;
    }
  }

  @Watch('$store.getters.emailRequestType')
  onRequestChange(value: string, oldValue: string) {
      this.requestType = value;
      this.setProperties()
  }

}
