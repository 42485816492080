
import { Component, Watch } from "vue-property-decorator";
import { Vue } from "vue-property-decorator";
import CloseButton from "@/components/buttons/CloseButton.vue"

@Component({
    components: {
        CloseButton
    }
})
export default class PrivacyPolicy extends Vue {
    

  closePrivacy() {
    this.$router.push( "/contact");
  }


}
