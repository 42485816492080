// Store design inspired by https://github.com/garyo/vuex-module-decorators-example

// This is the "store accessor":
// It initializes all the modules using a Vuex plugin (see store/index.ts)
// In here you import all your modules, call getModule on them to turn them
// into the actual stores, and then re-export them.
import { Store } from 'vuex'
import { getModule } from 'vuex-module-decorators'
import GbsStore from './GbsStore'
//import RestStore from './modules/RestStore'


export let gbsStore: GbsStore
//export let restStore: RestStore


// initializer plugin: sets up state/getters/mutations/actions for each store
export function initializeStores (store: Store<any>): void {
    
    gbsStore = getModule(GbsStore, store)
    //restStore = getModule(RestStore, store)
}

// for use in 'modules' store init (see store/index.ts), so each module
// appears as an element of the root store's state.
// (This is required!)
export const modules = {
    //'restStore': RestStore,
    'gbsStore': GbsStore
}