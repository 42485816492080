
import { Component, Vue, Prop } from "vue-property-decorator";


@Component({
    
})
export default class NextButton extends Vue {

    @Prop()
    disabled!: boolean;

    handleClick() {
        this.$emit("nextButtonClick")
    }

}
