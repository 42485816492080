const puzzle_inventory = [
	{
		imgName: "large_Some-Things-are-Scary-image-1200-watermark.jepg",
		thumbName: "some_things_are_scary_thumb.jpg",
		name: "Some Things Are Scary",
		catagory: "fall",
		height: 16.75,
		width: 12.75
	},
	{
		imgName: "september_garden.jpg",
		thumbName: "september_garden_thumb.jpg",
		name: "September Garden",
		catagory: "fall",
		height: 16.0,
		width: 11.75
	},
	{
		imgName: "how_the_west_was_one.jpg",
		thumbName: "how_the_west_was_one_thumb.jpg",
		name: "How the West Was One",
		catagory: "fall",
		height: 14.75,
		width: 14.75
	},
	{
		imgName: "ingram_falls_telluride.jpg",
		thumbName: "ingram_falls_telluride_thumb.jpg",
		name: "Ingram Falls, Telluride",
		catagory: "fall",
		height: 12.75,
		width: 16.75
	},
	{
		imgName: "sunrise_circle.jpg",
		thumbName: "sunrise_circle_thumb.jpg",
		name: "Sunrise Circle",
		catagory: "fall",
		height: 11.75,
		width: 17.75
	},
	{
		imgName: "the_weald_of_kent.jpg",
		thumbName: "the_weald_of_kent_thumb.jpg",
		name: "The Weald of Kent",
		catagory: "fall",
		height: 11.75,
		width: 17.0
	},
	{
		imgName: "the_hunt_diana_and_her_nymphs.jpg",
		thumbName: "the_hunt_diana_and_her_nymphs_thumb.jpg",
		name: "The Hunt (Diana and Her Nymphs)",
		catagory: "fall",
		height: 14.75,
		width: 14.75
	},
	{
		imgName: "the_dogs_dinner_party.jpg",
		thumbName: "the_dogs_dinner_party_thumb.jpg",
		name: "The Dogs Dinner Party",
		catagory: "fall",
		height: 10.75,
		width: 20.75
	},
	{
		imgName: "a_plethora_of_cats.jpg",
		thumbName: "a_plethora_of_cats_thumb.jpg",
		name: "A Plethora of Cats",
		catagory: "fall",
		height: 20.75,
		width: 10.25
	},
	{
		imgName: "on_the_river_greta_lake_district_england.jpg",
		thumbName: "on_the_river_greta_lake_district_england_thumb.jpg",
		name: "On the River Greta, Lake District, England",
		catagory: "fall",
		height: 12.75,
		width: 18.0
	},
	{
		imgName: "fall_garden.jpg",
		thumbName: "fall_garden_thumb.jpg",
		name: "Fall Garden",
		catagory: "fall",
		height: 13.75,
		width: 13.75
	},
	{
		imgName: "a_river_in_autumn.jpg",
		thumbName: "a_river_in_autumn_thumb.jpg",
		name: "A River in Autumn",
		catagory: "fall",
		height: 13.75,
		width: 17.25
	},
	{
		imgName: "tannenwald.jpg",
		thumbName: "tannenwald_thumb.jpg",
		name: "Tannenwald",
		catagory: "fall",
		height: 7.0,
		width: 7.0
	},
	{
		imgName: "the_battle_of_carnival_and_lent.jpg",
		thumbName: "the_battle_of_carnival_and_lent_thumb.jpg",
		name: "The Battle of Carnival and Lent",
		catagory: "fall",
		height: 14.75,
		width: 21.25
	},
	{
		imgName: "childrens_games.jpg",
		thumbName: "childrens_games_thumb.jpg",
		name: "Children's Games",
		catagory: "fall",
		height: 15.75,
		width: 22.0
	},
	{
		imgName: "large_dia-de-los-muertos-image-1100-watermark.jpeg",
		thumbName: "dia_de_los_muertos_thumb.jpg",
		name: "Dia de Los Muertos",
		catagory: "fall",
		height: 15.75,
		width: 7.75
	},
	{
		imgName: "fates_and_fortunes_halloween.jpg",
		thumbName: "fates_and_fortunes_halloween_thumb.jpg",
		name: "Fates and Fortunes Halloween",
		catagory: "fall",
		height: 13.0,
		width: 8.25
	},
	{
		imgName: "halloween_mischief.jpg",
		thumbName: "halloween_mischief_thumb.jpg",
		name: "Halloween Mischief",
		catagory: "fall",
		height: 13.25,
		width: 8.0
	},
	{
		imgName: "halloween_spirit.jpg",
		thumbName: "halloween_spirit_thumb.jpg",
		name: "Halloween Spirit",
		catagory: "fall",
		height: 9.0,
		width: 13.75
	},
	{
		imgName: "happy_halloween.jpg",
		thumbName: "happy_halloween_thumb.jpg",
		name: "Happy Halloween",
		catagory: "fall",
		height: 9.0,
		width: 13.75
	},
	{
		imgName: "the_night_of_halloween.jpg",
		thumbName: "the_night_of_halloween_thumb.jpg",
		name: "The Night of Halloween",
		catagory: "fall",
		height: 13.0,
		width: 8.0
	},
	{
		imgName: "witch_way_halloween.jpg",
		thumbName: "witch_way_halloween_thumb.jpg",
		name: "Witch Way Halloween",
		catagory: "fall",
		height: 12.75,
		width: 8.0
	},
	{
		imgName: "butterfly_golden_pumpkin.jpg",
		thumbName: "butterfly_golden_pumpkin_thumb.jpg",
		name: "Butterfly Golden Pumpkin",
		catagory: "fall",
		height: 6.5,
		width: 17.25
	},
	{
		imgName: "golden_turkey.jpg",
		thumbName: "golden_turkey_thumb.jpg",
		name: "Golden Turkey",
		catagory: "fall",
		height: 8.0,
		width: 12.75
	},
	{
		imgName: "patriotic_turkey.jpg",
		thumbName: "patriotic_turkey_thumb.jpg",
		name: "Patriotic Turkey",
		catagory: "fall",
		height: 8.0,
		width: 12.75
	},
	{
		imgName: "peace_and_plenty_thanksgiving.jpg",
		thumbName: "peace_and_plenty_thanksgiving_thumb.jpg",
		name: "Peace and Plenty Thanksgiving",
		catagory: "fall",
		height: 8.25,
		width: 12.75
	},
	{
		imgName: "thanksgiving_greeting.jpg",
		thumbName: "thanksgiving_greeting_thumb.jpg",
		name: "Thanksgiving Greeting",
		catagory: "fall",
		height: 13.0,
		width: 8.5
	},
	{
		imgName: "thanksgiving_postcard.jpg",
		thumbName: "thanksgiving_postcard_thumb.jpg",
		name: "Thanksgiving Postcard",
		catagory: "fall",
		height: 8.25,
		width: 12.75
	},
	{
		imgName: "thanksgiving_twilight.jpg",
		thumbName: "thanksgiving_twilight_thumb.jpg",
		name: "Thanksgiving Twilight",
		catagory: "fall",
		height: 8.25,
		width: 12.75
	},
	{
		imgName: "the_first_thanksgiving_1621.jpg",
		thumbName: "the_first_thanksgiving_1621_thumb.jpg",
		name: "The First Thanksgiving, 1621",
		catagory: "fall",
		height: 12.75,
		width: 18.0
	},
]

export default puzzle_inventory