const puzzle_inventory = [
	{
		imgName: "barrier_reef_anemone.jpg",
		thumbName: "barrier_reef_anemone_thumb.jpg",
		name: "Barrier Reef Anemone",
		catagory: "summer",
		height: 6.75,
		width: 7.25
	},
	{
		imgName: "étretat_the_beach_and_the_falaise_damont.jpg",
		thumbName: "étretat_the_beach_and_the_falaise_damont_thumb.jpg",
		name: "Étretat: The Beach and the Falaise d'Amont",
		catagory: "summer",
		height: 9.75,
		width: 10.0
	},
	{
		imgName: "sunset_pool_of_london.jpg",
		thumbName: "sunset_pool_of_london_thumb.jpg",
		name: "Sunset: Pool of London",
		catagory: "summer",
		height: 12.75,
		width: 18.0
	},
	{
		imgName: "vogue_the_brides_many_suggestions.jpg",
		thumbName: "vogue_the_brides_many_suggestions_thumb.jpg",
		name: "Vogue: The Bride's Many Suggestions",
		catagory: "summer",
		height: 16.25,
		width: 12.75
	},
	{
		imgName: "vogue_hot_weather_fashions_number.jpg",
		thumbName: "vogue_hot_weather_fashions_number_thumb.jpg",
		name: "Vogue: Hot Weather Fashions Number",
		catagory: "summer",
		height: 16.25,
		width: 12.75
	},
	{
		imgName: "seascape_near_les_saintes_maries_de_la_mer.jpg",
		thumbName: "seascape_near_les_saintes_maries_de_la_mer_thumb.jpg",
		name: "Seascape near Les Saintes Maries de la Mer",
		catagory: "summer",
		height: 12.75,
		width: 16.75
	},
	{
		imgName: "summer_geraniums.jpg",
		thumbName: "summer_geraniums_thumb.jpg",
		name: "Summer Geraniums",
		catagory: "summer",
		height: 14.75,
		width: 14.75
	},
	{
		imgName: "august_in_weymouth.jpg",
		thumbName: "august_in_weymouth_thumb.jpg",
		name: "August in Weymouth",
		catagory: "summer",
		height: 14.75,
		width: 14.75
	},
	{
		imgName: "return_to_the_welcome_hills.jpg",
		thumbName: "return_to_the_welcome_hills_thumb.jpg",
		name: "Return to the Welcome Hills",
		catagory: "summer",
		height: 15.0,
		width: 14.75
	},
	{
		imgName: "the_little_pier.jpg",
		thumbName: "the_little_pier_thumb.jpg",
		name: "The Little Pier",
		catagory: "summer",
		height: 13.75,
		width: 16.5
	},
	{
		imgName: "boats_by_lindsey.jpg",
		thumbName: "boats_by_lindsey_thumb.jpg",
		name: "Boats by Lindsey",
		catagory: "summer",
		height: 16.75,
		width: 12.75
	},
	{
		imgName: "la_paz.jpg",
		thumbName: "la_paz_thumb.jpg",
		name: "La Paz",
		catagory: "summer",
		height: 16.5,
		width: 13.75
	},
	{
		imgName: "amalfi.jpg",
		thumbName: "amalfi_thumb.jpg",
		name: "Amalfi",
		catagory: "summer",
		height: 18.0,
		width: 11.75
	},
	{
		imgName: "white_ships.jpg",
		thumbName: "white_ships_thumb.jpg",
		name: "White Ships",
		catagory: "summer",
		height: 9.0,
		width: 12.75
	},
	{
		imgName: "figures_on_the_beach.jpg",
		thumbName: "figures_on_the_beach_thumb.jpg",
		name: "Figures on the Beach",
		catagory: "summer",
		height: 13.75,
		width: 16.75
	},
	{
		imgName: "summer_fashion_number.jpg",
		thumbName: "summer_fashion_number_thumb.jpg",
		name: "Summer Fashion Number",
		catagory: "summer",
		height: 16.5,
		width: 12.75
	},
	{
		imgName: "cote_dazur.jpg",
		thumbName: "cote_dazur_thumb.jpg",
		name: "Cote D'Azur",
		catagory: "summer",
		height: 18.25,
		width: 12.75
	},
	{
		imgName: "bandol.jpg",
		thumbName: "bandol_thumb.jpg",
		name: "Bandol",
		catagory: "summer",
		height: 19.5,
		width: 12.75
	},
	{
		imgName: "children_playing_on_the_beach.jpg",
		thumbName: "children_playing_on_the_beach_thumb.jpg",
		name: "Children Playing on the Beach",
		catagory: "summer",
		height: 16.5,
		width: 12.75
	},
	{
		imgName: "summer_1918.jpg",
		thumbName: "summer_1918_thumb.jpg",
		name: "Summer 1918",
		catagory: "summer",
		height: 9.75,
		width: 11.75
	},
]

export default puzzle_inventory