
import { Component } from "vue-property-decorator";
import { Vue } from "vue-property-decorator";
import Spinner from "@/components/views/Spinner.vue"
import BuildFrameButton from "@/components/buttons/BuildFrameButton.vue"

@Component({
    components: {
      Spinner,
      BuildFrameButton
    }
})
export default class Home extends Vue {
  isImageLoaded = false;

  created() {
      //console.log("Home page has been created.")
  }

  onImgLoad() {
        this.isImageLoaded = true;
    }


  handleBuildNewFrameClick() {
    this.$router.push( "/builder");
  }

  onPuzzleClick() {
      this.$router.push( "/demo");
  }
}
